import { Col, Row, Table, Pagination, FormInstance, Button, notification, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { exportFileUsingPOST } from "../../../common/assets/CommonFunctions";
import { useExportDataMutation, useLazyGetTienDoKiemTraReportQuery, useLazyGetTienDoKiemTraTotalQuery } from "../redux/uploadDataReportApi";
import {useLazyGetSyncReportQuery} from "../../../../redux/api/commonApi";
import ConfirmModal from "../../../common/components/ConfirmModal";
import { DownloadOutlined } from "@ant-design/icons";

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetTienDoKiemTraReportQuery();
    const [triggerReportTotal, responseReportTotal] = useLazyGetTienDoKiemTraTotalQuery();
    const [triggerSyncReport, responseSyncReport] = useLazyGetSyncReportQuery();
    const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<any[]>();
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        // console.log(searchParam);
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.content) {
            let newTableData: any[] = [];
            responseReport.data.content.forEach((item: any, index: any) => {
                newTableData.push({
                    key: index + 1,
                    ...item
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    const columns: ColumnsType<any> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        // {
        //     title: "Tỉnh/Thành",
        //     dataIndex: "tinh",
        //     key: "tinh",
        // },
        {
            title: "Quận/huyện",
            dataIndex: "huyen",
            key: "huyen",
        },
        {
            title: "Phường/xã",
            dataIndex: "xa",
            key: "xa",
        },
        {
            title: "Loại hộ tịch",
            dataIndex: "type_id",
            key: "type_id",
            align: "center",
        },
        {
            title: "Năm mở sổ",
            dataIndex: "nam_mo_so",
            key: "nam_mo_so",
            align: "center",
        },
        {
            title: "Quyển số",
            dataIndex: "quyen_so",
            key: "quyen_so",
            align: "center",
        },
        {
            title: "Số THHT",
            dataIndex: "total_thht",
            key: "total_thht",
            align: "center",
        },
        {
            title: "Số cột thông tin",
            dataIndex: "key_map_size",
            key: "key_map_size",
            align: "center",
        },
        {
            title: "Số trường thông tin có dữ liệu",
            dataIndex: "total_field_not_null",
            key: "total_field_not_null",
            align: "center",
        },
        {
            title: "Số trường thông tin không dữ liệu",
            dataIndex: "total_field_null",
            key: "total_field_null",
            align: "center",
        }
        
    ];

    const getData = () => {
        // console.log(searchParam);
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportTotal({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    };

    const onSyncClick = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        // Thực hiện đồng bộ ở đây
        notification.success({ message: "Đồng bộ bắt đầu, Vui lòng chờ!" });
        setLoadings(true);
        triggerSyncReport({ rpName: "upload-du-lieu" })
            .unwrap()
            .then((res) => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                setTimeout(() => {
                    notification.success({ message: "Đồng bộ thành công!" });
                }, 500);
                getData();
                setLoadings(false);
            })
            .catch((err) => {
                setLoadings(false);
            });
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false); // Ẩn modal nếu người dùng hủy bỏ
    };

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                {/* <Col span={6}></Col> */}
                <div>Tổng số: <strong>{responseReportTotal?.data ??0}</strong> bản ghi</div>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        // type="primary"
                        icon={<DownloadOutlined/>}
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                    <Button htmlType="button" type="primary" onClick={onSyncClick}>Đồng bộ</Button>
                </div>
            </Row>
            <ConfirmModal
                cancelText="Huỷ bỏ"
                okText="Xác nhận"
                classBtnOk="ant-button-dangerous"
                contents={[
                    "Đồng bộ sẽ mất thời gian khoảng 5 phút.",
                    "Bạn có chắc chắn muốn đồng bộ không?"
                ]}
                handleCancel={handleCancel}
                handleOk={handleOk}
                type={1}
                visible={isModalVisible}
                widthConfig={500}
                okBtnDanger={true}
            />
            <Table<any>
                loading={loadings || responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;
