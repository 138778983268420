import { Button, Pagination, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMemo, useState } from "react";
import { useAppSelector } from "../../../redux/Hooks";
import { IFilter } from "../redux/apiTypes";
import CreateCampaign from "./CreateCampaign";
import { Link, useNavigate } from "react-router-dom";
// import CreateCampaign from "./CreateCampaign";

interface DataListTable {
  id: string;
  fileName: string;
  fileName158: string;
  userChecks: string[];
  status: number;
  dataSource: number;
  numberCheck: number;
  createdDateStr: string;
  groupUserIds: string[];
}

type Props = {
  total: number;
  isLoading: boolean;
  filter: IFilter;
  onChangeTable: (pageNum?: number, pageSize?: number) => void;
  dataSource: DataListTable[];
};

const RegisterInfoList = ({
  total,
  isLoading,
  filter,
  onChangeTable,
  dataSource,
}: Props) => {
  const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const currentPageNum = useMemo(() => {
    return filter?.page + 1;
  }, [filter?.page]);

  const currentPageSize = useMemo(() => {
    return filter?.size;
  }, [filter?.size]);

  const currentDataSource = useMemo(() => {
    return dataSource;
  }, [dataSource]);

  const totals = useMemo(() => {
    return total;
  }, [total]);

  const STATUS = {
    UN_CHECK: 0,
    WAIT_CHECK: 2,
    CHECKED: 1,
  };

  const StatusElement = ({ value }: { value: number }) => {
    const statusStyle = {
      padding: "4px",
      borderRadius: "4px",
    };

    const statusMapping = {
      [STATUS.UN_CHECK]: {
        color: "#E11D48",
        text: "Chưa kiểm tra",
        bg: "#FFE4E6",
      },
      [STATUS.WAIT_CHECK]: {
        color: "#E1871D",
        text: "Chờ kiểm tra",
        bg: "#FFF7E4",
      },
      [STATUS.CHECKED]: {
        color: "green",
        text: "Đã kiểm tra",
        bg: "#CCFBF1",
      },
    };
    const status = statusMapping[value] || { color: "gray", text: "Unknown" };

    return (
      <span
        style={{
          ...statusStyle,
          color: status.color,
          backgroundColor: status.bg,
        }}
      >
        {status.text}
      </span>
    );
  };

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, record, index) =>
        (currentPageNum - 1) * currentPageSize + index + 1,
      width: 70,
    },
    {
      title: "File pdf",
      dataIndex: "fileName",
      key: "fileName",
      ellipsis: true,
      width: 200,
      render: (value, item, index) => (
        <div>
          {/* <Link to={`/data/recheck/detail/${item.id}`}>{value || "-"}</Link> */}
          <Link to={`/data/info_init_history_detail/${item.id}`}>{value || "-"}</Link>
        </div>
      ),
    },
    {
      title: "File pdf 158",
      dataIndex: "fileName158",
      key: "fileName158",
      ellipsis: true,
      width: 200,
      render: (value, item, index) => <div>{value || "-"}</div>,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => <StatusElement value={value} />,
    },
    {
      title: "Nguồn dữ liệu",
      dataIndex: "dataSource",
      key: "dataSource",
      render: (value) => (
        <div>
          {value === 1 ? "Kho dữ liệu nhập liệu" : "Kho dữ liệu đối chiếu"}
        </div>
      ),
    },
    {
      title: "Số lần kiểm tra",
      dataIndex: "numberCheck",
      key: "numberCheck",
      render: (value, record) => (
        <span>{record.status !== STATUS.CHECKED ? 0 : value}</span>
      ),
    },
    {
      title: "Nhóm người dùng",
      key: "groupUserIds",
      dataIndex: "groupUserIds",
      render: (value) => (
        <div>
          {(value?.length > 0 && value?.map((el: any) => el.name).join(", ")) ||
            "-"}
        </div>
      ),
      align: "left",
    },
    {
      title: "Người kiểm tra",
      dataIndex: "userChecks",
      key: "userChecks",
      render: (value) => (
        <div>
          {(value?.length > 0 && value?.map((el: any) => el.name).join(", ")) ||
            "-"}
        </div>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDateStr",
      key: "createdDateStr",
    },
  ];

  const changePage = (currentPage: number, pageSize: number) => {
    onChangeTable(pageSize === currentPageSize ? currentPage : 1, pageSize);
  };

  const toggleModal = () => {
    setCreateCampaignVisible(!createCampaignVisible);
  };
  const navigate = useNavigate();

  const openDetailModal = (e: any, item: DataListTable) => {
    navigate(`/data/info_init_history_detail/${item.id}`);
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <div>
          Tổng số: <strong>{totals}</strong> bản ghi
        </div>

        <div className="search-box-button">
          <Button
            // disabled={currentDataSource.length === 0}
            htmlType="button"
            type="primary"
            onClick={toggleModal}
          >
            Tạo chiến dịch
          </Button>
        </div>
      </Row>

      <Table<DataListTable>
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => openDetailModal(event, record), // click row
        //     onDoubleClick: (event) => openDetailModal(event, record), // double click row
        //   };
        // }}
        loading={isLoading}
        dataSource={currentDataSource}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        rowKey="id"
      />

      <Spin spinning={isLoading} tip="Đang tải...">
        <Pagination
          total={totals}
          defaultPageSize={10}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={currentPageNum}
        />
      </Spin>

      <CreateCampaign
        filter={filter}
        visible={createCampaignVisible}
        onClose={toggleModal}
      />
    </div>
  );
};

export default RegisterInfoList;
