export const CustomToolTipUserPieChart = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="customs-tooltip" style={{ padding: 6 }}>
                <p
                    className="label content"
                    style={{
                        fontSize: 15,
                        borderBottom: "none",
                        color: payload[0].payload?.color,
                    }}
                >{`${payload[0].name} : ${new Intl.NumberFormat().format(
                    payload[0].value
                )}`}</p>
            </div>
        );
    }

    return null;
};
