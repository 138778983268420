import { Button, Col, Drawer, Form, Input, notification, Row, Space, Tree, TreeProps } from "antd";
import React, { useEffect, useState } from "react";

import { useAddRoleMutation, useEditRoleMutation, useLazyGetDetailRoleQuery, useLazyGetFullRoleQuery, } from "../redux/permissionsApi";
import { DataListTable } from "./RoleList";
import '../assets/scss/role.scss';


import { ReactComponent as Close } from "../../../assets/logos/close-contained.svg";
import TextArea from "antd/lib/input/TextArea";
import { DataNode } from "antd/lib/tree";
import { FullRoleResponse, Role, TreeRole } from "../redux/apiTypes";
import { arrayToTree } from "performant-array-to-tree";

interface AddroleProps {
    visible: boolean;
    onCancel: () => void;
    currentRole: DataListTable | undefined;
    onAddSuccessful: () => void;
    canEdit: boolean;
}

const AddRoleModal = ({ visible, onCancel, currentRole, onAddSuccessful, canEdit }: AddroleProps) => {
    const [form] = Form.useForm();
    const [addTrigger] = useAddRoleMutation();
    const [editTrigger] = useEditRoleMutation();
    const [detailRoleTrigger, detailRoleResponse] = useLazyGetDetailRoleQuery();

    const [fullRoleTrigger, fullRoleResponse] = useLazyGetFullRoleQuery();

    const [editing, setEditing] = useState<boolean>(false);

    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [treeList, setTreeList] = useState<DataNode[]>();
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const [treeData, setTreeData] = useState<any>([]);

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck: TreeProps["onCheck"] = (checked: any, info) => {
        const list = info.checkedNodes.filter((node: any) => node.id);
        setTreeList(list);
        setCheckedKeys(checked);
    };

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        // console.log('ddddd', selectedKeysValue);
        setSelectedKeys(selectedKeysValue);
    };

    // // khi reload
    // const [isFormChange, setFormChange] = useState<boolean>(false);
    // const [reloadModal, setReloadModal] = useState<boolean>(false);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    // usePrompt(isFormChange);

    useEffect(() => {
        fullRoleTrigger({}).unwrap()
            .then(res => {
                let tree: TreeRole[] = [
                    {
                        title: "Quản trị hệ thống",
                        key: "quanTriHeThong",
                        id: "quanTriHeThong",
                        authKey: "quanTriHeThong",
                        authoritieName: "Quản trị hệ thống",
                        description: "Quản trị hệ thống",
                        children: []
                    },
                    {
                        title: "Quản lý danh mục",
                        key: "danhMuc",
                        id: "danhMuc",
                        authKey: "danhMuc",
                        authoritieName: "Quản lý danh mục",
                        description: "Quản lý danh mục",
                        children: []
                    }
                ];

                res.forEach((item: FullRoleResponse) => {
                    let treeNode: TreeRole = {
                        title: item.authoritieName,
                        key: item.id,
                        id: item.id,
                        authKey: item.authKey,
                        authoritieName: item.authoritieName,
                        description: item.description,
                        parentId: item.parentId,
                        children: []
                    };
                    if (['Tỉnh thành', 'Quận huyện', 'Phường xã'].includes(item.authoritieName)) {
                        treeNode.parentId = 'danhMuc';
                    }
                    if (['Người dùng', 'Nhóm người dùng', 'Nhóm quyền'].includes(item.authoritieName)) {
                        treeNode.parentId = 'quanTriHeThong';
                    }
                    if (item.authoritieName === 'Tham số hệ thống') {
                        treeNode.title = 'Cấu hình trường thông tin';
                        treeNode.authoritieName = 'Cấu hình trường thông tin';
                    }
                    if (item.authoritieName === 'Nhóm người dùng') {
                        treeNode.title = 'Nhóm người dùng';
                        treeNode.authoritieName = 'Nhóm người dùng';
                    }
                    if (item.authoritieName === 'Nhóm quyền') {
                        treeNode.title = 'Nhóm quyền';
                        treeNode.authoritieName = 'Nhóm quyền';
                    }
                    if (item.authoritieName === 'Người dùng') {
                        treeNode.title = 'Quản lý người dùng';
                        treeNode.authoritieName = 'Quản lý người dùng';
                    }
                    tree.push(treeNode);
                });
                
                const treeRes = arrayToTree(
                    tree,
                    { dataField: null }
                );

                let sortIn = [
                    "Danh sách file pdf",
                    "Nhập liệu dữ liệu",
                    "Kiểm tra dữ liệu",
                    "Kho dữ liệu nhập liệu",
                    "Kho dữ liệu kiểm tra",
                    "Đồng bộ dữ liệu",
                    "Kho dữ liệu",
                    "Dữ liệu số hóa",
                    "Chiến dịch nhập liệu",
                    "Chiến dịch kiểm tra",
                    "Báo cáo",
                    "Cấu hình trường thông tin",
                    "Quản lý danh mục",
                    "Quản trị hệ thống",
                    "Đối chiếu dữ liệu",
                    "Chiến dịch đối chiếu",
                    "Kho đối chiếu nhập liệu"
                ];

                // eslint-disable-next-line array-callback-return
                let sortRes = sortIn.map(item => {
                    let idx = treeRes.findIndex(x => x.authoritieName === item);
                    if (idx !== -1) {
                        return treeRes[idx];
                    }
                }
                ).filter(item => item !== undefined);

                sortRes.forEach((item) => {
                    const idx = item?.children.findIndex((x: any) => x.title.substring(0, 3) === 'Xem')
                    item?.children.unshift(item?.children.splice(idx, 1)[0]);

                    if (item?.title === 'Quản trị hệ thống' || item?.title === 'Quản lý danh mục') {
                        item.children.sort((a: TreeRole, b: TreeRole) => b.title.localeCompare(a.title));

                        item?.children.forEach((a: TreeRole) => {
                            const idx1 = a?.children.findIndex((y: TreeRole) => y.title.substring(0, 3) === 'Xem')
                            a?.children.unshift(a?.children.splice(idx1, 1)[0]);
                        })
                    }
                })

                setTreeData(sortRes);

            })
    }, [fullRoleTrigger])



    useEffect(() => {
        if (currentRole) {
            form.setFieldsValue({
                roleName: currentRole.roleName || "",
                description: currentRole.description || "",
                authoritieLst: currentRole.authoritieLst,
            })
            detailRoleTrigger(currentRole.key);
        }
        else {
            form.setFieldsValue({
                status: 0,
            })
        }
    }, [currentRole, detailRoleTrigger, form]);

    useEffect(() => {
        if (detailRoleResponse?.data) {
            const authList = detailRoleResponse?.data?.authoritieLst?.map((role: Role) => role.id);
            setCheckedKeys(authList);
            setTreeList(detailRoleResponse?.data?.authoritieLst);
        }
    }, [detailRoleResponse])

    const onFinish = (values: any) => {
        let map = new Map();
        fullRoleResponse.data.forEach((item: any) => {
            map.set(item.id, item);
        })
        let authoritieList = treeList?.map((item: any) => {
            return map.get(item.id);
        }).filter(item => item !== undefined);

        if (!authoritieList?.length) {
            return notification.error({
                message: 'Vui lòng chọn thông tin nhóm quyền.'
            });
        }

        if (!loadingBtnSubmit) {
            setLoadingBtnSubmit(true);
            if (currentRole) {
                editTrigger({
                    id: currentRole.key,
                    roleName: values.roleName?.trim() || "",
                    description: values.description?.trim() || "",
                    authoritieLst: authoritieList || [],
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Cập nhật Nhóm quyền thành công.'
                        });
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
            else {
                addTrigger({
                    roleName: values.roleName?.trim() || "",
                    description: values.description?.trim() || "",
                    authoritieLst: authoritieList || [],
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Thêm mới Nhóm quyền thành công.'
                        })
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
        }
    };

    return (
        <div>
            <Drawer
                closable={false}
                className="custom-drawer"
                width={'60%'}
                title={currentRole ?
                    <div>
                        <div className="drawer-title">
                            <span>{`${editing ? 'Chỉnh sửa' : 'Thông tin'}`} nhóm quyền</span>
                            <Close className="title-edit-icon" style={{ cursor: 'pointer', width: 40, height: 40 }} onClick={() => onCancel()} />
                        </div>
                    </div>
                    : <div className="drawer-title">
                        <span>Thêm nhóm quyền mới</span>
                        <Close style={{ cursor: 'pointer', width: 40, height: 40 }} onClick={() => onCancel()} />
                    </div>}
                placement="right"
                visible={visible}
                onClose={() => onCancel()}
                // mask={false}
                footer={[
                    <Row align="middle" justify="end" className='row-drawer' style={{ zIndex: 10 }}>
                        <Space>
                            <Button
                                htmlType='button'
                                // onClick={() => ischeckUpdate ? setQuitVisible(true) : resetState()}
                                className="drawer-button"
                                onClick={() => onCancel()}
                            >
                                Đóng
                            </Button>
                            {(editing) ? <Button
                                htmlType='button'
                                type='primary'
                                className='primary-blue-button drawer-button'
                                onClick={() => form.submit()}
                                // disabled={canEdit ? false : true}
                                loading={loadingBtnSubmit}
                            >
                                Lưu
                            </Button> : <></>}
                            {!currentRole ? <Button
                                htmlType='button'
                                type='primary'
                                className='primary-blue-button drawer-button'
                                onClick={() => form.submit()}
                                // disabled={canEdit ? false : true}
                                loading={loadingBtnSubmit}
                            >
                                Thêm mới
                            </Button> : <></>}
                            {(currentRole && canEdit && !editing) && <Button className="'primary-blue-button drawer-button" type="primary" onClick={() => setEditing(!editing)}>Chỉnh sửa</Button>}
                        </Space>
                    </Row>
                ]}
                maskStyle={{ backgroundColor: 'transparent' }}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    labelAlign="left"
                    form={form}
                    scrollToFirstError
                    onFinish={onFinish}
                >
                    <Row gutter={20}>
                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Tên nhóm quyền"
                                name="roleName"
                                rules={[
                                    { required: true, whitespace: true, message: "Vui lòng nhập tên nhóm quyền" }
                                ]}
                            >
                                <Input maxLength={100} disabled={currentRole && !editing ? true : false} className="propNameInput" placeholder="Nhập tên nhóm quyền" allowClear autoComplete="on"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Mô tả"
                                name="description"
                            >
                                <TextArea
                                    disabled={currentRole && !editing ? true : false}
                                    allowClear
                                    maxLength={500}
                                    placeholder="Nhập nội dung mô tả"
                                    // rows={3} 
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <span style={{ fontSize: 20, fontWeight: 500, paddingBottom: 20 }}>Thông tin nhóm quyền</span>

                    <Tree
                        checkable
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        style={{ marginTop: 18 }}
                        disabled={currentRole && !editing ? true : false}
                    />
                </Form>
            </Drawer>
        </div>
    );
};

export default AddRoleModal;
