export const CustomToolTipUserBarChart = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        const total = payload.reduce(
            (total: number, curr: any, idx: number) => {
                return total + curr.value || 0;
            },
            0
        );
        return (
            <div className="customs-tooltip">
                <p className="label">{`Tống: ${new Intl.NumberFormat().format(
                    total
                )}`}</p>
                <ul className="list">
                    {payload.map((elem: any, i: number) => {
                        return (
                            <li
                                key={i}
                                className="item"
                                style={{ color: elem.color }}
                            >
                                <span className="item-name">{elem.name}</span>
                                <span className="item-separator"> : </span>
                                <span className="item-value">{new Intl.NumberFormat().format(elem.value)}</span>
                                <span className="item-unit"></span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    return null;
};
