import { Button, Col, Form, Input, Row, Select } from "antd";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { DatePicker } from "../../custom-components";
import { Commune, District } from "../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
  useLazyGetGroupUserCheckDataQuery,
  // useLazyGetUserCheckDataQuery,
  useLazyGetUserRecheckDataQuery,
} from "../../redux/api/commonApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../category/province/redux/provinceApi";
import DataCompareList from "./components/DataCompareList";
import { DataListTable, IFilter } from "./redux/apiTypes";
import { useLazyGetListDataQuery } from "./redux/compareApi";
import { helpers } from "../../assets/js/helper";
import { useAppSelector } from "../../redux/Hooks";

const CompareData = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const [filter, setFilter] = useState<IFilter>({
    provinceCode: undefined,
    districtCode: undefined,
    communeCode: undefined,

    fileName: undefined,
    userInputs: undefined,
    userRechecks: undefined,

    fromCreateDate: undefined,
    toCreateDate: undefined,

    page: 0,
    size: 10,
  });

  const [dataSource, setDataSource] = useState<DataListTable[]>([]);

  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();

  const [triggerGroupUserCheck, responseGroupUserCheck] =
    useLazyGetGroupUserCheckDataQuery();

  const [triggerListData, { data, isFetching, error }] =
    useLazyGetListDataQuery();

  const [districtTrigger] = useLazyGetDistrictQuery();
  const [communeTrigger] = useLazyGetCommuneQuery();
  // const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
  const [triggerUserRecheck, responseUserRecheck] = useLazyGetUserRecheckDataQuery();

  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    triggerProvince({});
    // triggerUserCheck({});
    triggerUserRecheck({})
    triggerListData({
      page: 0,
      size: 10,
    });
    triggerGroupUserCheck({});
  }, [
    triggerProvince,
    // triggerUserCheck,
    triggerUserRecheck,
    triggerListData,
    triggerGroupUserCheck,
  ]);

  useEffect(() => {
    if (data?.content) {
      setDataSource(data?.content);
      setTotal(data?.totalElements || 0);
    }
    if (error) {
      setDataSource([]);
      setTotal(0);
    }
  }, [data, error]);

  const onFinish = (values: any) => {
    const { createDates, districtCode, provinceCode, communeCode, ...rest } =
      values;
    const newCreateDates = createDates
      ? createDates.map((item: Moment) => {
          return formatMomentToDateStartDay(item);
        })
      : undefined;
    const newDistrictCode = districtCode && districtCode?.split("-")[1];
    const newProvinceCode = provinceCode && provinceCode?.split("-")[1];
    const newCommuneCode = communeCode && communeCode?.split("-")[1];

    const newFilter = {
      ...filter,
      ...rest,
      page: 0,
      fromCreateDate: newCreateDates?.length ? newCreateDates[0] : undefined,
      toCreateDate: newCreateDates?.length ? newCreateDates[1] : undefined,
      districtCode: newDistrictCode,
      communeCode: newCommuneCode,
      provinceCode: newProvinceCode,
    };
    // console.log("newFilter", newFilter)
    triggerListData(newFilter);
    setFilter(newFilter);
  };

  const onValuesChanged = (changedValues: any) => {
    if (Object.keys(changedValues)[0] === "provinceCode") {
      setCommunes([]);
      setDistricts([]);
      if (changedValues.provinceCode) {
        districtTrigger(changedValues.provinceCode.split("-")[0])
          .unwrap()
          .then((res) => setDistricts(res));
      }
      form.setFieldsValue({
        districtCode: undefined,
        communeCode: undefined,
      });
    } else if (Object.keys(changedValues)[0] === "districtCode") {
      setCommunes([]);
      if (changedValues.districtCode) {
        communeTrigger(changedValues.districtCode.split("-")[0])
          .unwrap()
          .then((res) => setCommunes(res));
      }
      form.setFieldsValue({
        communeCode: undefined,
      });
    }
  };

  const onChangeTable = (pageNum?: number, pageSize?: number) => {
    // console.log("pageNum", pageNum, "pageSize", pageSize);
    const newFilter = {
      ...filter,
      page: pageNum !== undefined ? pageNum - 1 : filter.page,
      size: pageSize !== undefined ? pageSize : filter.size,
    };
    // console.log("newFilter", newFilter);
    triggerListData(newFilter);
    setFilter(newFilter);
  };

  const formatMomentToDateStartDay = (data?: Moment) => {
    return data ? moment(data).startOf("day").format("yyyy-MM-DD") : undefined;
  };

  const resetFormData = () => {
    form.resetFields();
    const newFilter = {
      provinceCode: undefined,
      districtCode: undefined,
      communeCode: undefined,

      fileName: undefined,
      userInputs: undefined,
      userRechecks: undefined,

      fromCreateDate: undefined,
      toCreateDate: undefined,

      page: 0,
      size: 10,
    };
    setFilter(newFilter);
  };
  return (
    <>
      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
          onValuesChange={onValuesChanged}
        >
          <>
            <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Tỉnh/Thành phố"
                  name="provinceCode"
                >
                  <Select
                    allowClear
                    placeholder="Chọn tỉnh thành"
                    optionFilterProp="label"
                    options={(responseProvince?.data || []).map((item) => ({
                      label: item.provinceName,
                      value: `${item.id}-${item.provinceCode}`,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Quận/Huyện"
                  name="districtCode"
                >
                  <Select
                    allowClear
                    placeholder="Chọn quận huyện"
                    optionFilterProp="label"
                    options={(districts || []).map((item) => ({
                      label: item.districtName,
                      value: `${item.id}-${item.districtCode}`,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Phường/Xã"
                  name="communeCode"
                >
                  <Select
                    allowClear
                    optionFilterProp="label"
                    placeholder="Chọn phường xã"
                    options={(communes || []).map((item) => ({
                      label: item.communeName,
                      value: `${item.id}-${item.communeCode}`,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Tên file pdf 158"
                  name="fileName"
                >
                  <Input
                    placeholder="Nhập tên file"
                    style={{ minWidth: "100%" }}
                    autoComplete="on"
                  />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Tài khoản nhập liệu"
                  name="userInputs"
                >
                  <Select
                    allowClear
                    placeholder="Chọn tài khoản nhập liệu"
                    options={(responseUserRecheck?.data || []).map((item) => ({
                      label: `${item.fullName} (${item.username})`,
                      value: item.username,
                    }))}
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Ngày tạo"
                  name="createDates"
                >
                  <RangePicker
                    format="DD/MM/Y"
                    placeholder={["Bắt đầu", "Kết thúc"]}
                    ranges={{
                      "Hôm nay": [new Date(), new Date()],
                      "Tháng này": [startOfMonth(new Date()), new Date()],
                      "Tháng trước": [
                        startOfMonth(subMonths(new Date(), 1)),
                        endOfMonth(subMonths(new Date(), 1)),
                      ],
                      "3 tháng gần nhất": [
                        startOfMonth(subMonths(new Date(), 2)),
                        new Date(),
                      ],
                      "6 tháng gần nhất": [
                        startOfMonth(subMonths(new Date(), 5)),
                        new Date(),
                      ],
                      "Năm nay": [
                        startOfYear(subMonths(new Date(), 5)),
                        new Date(),
                      ],
                      "Năm trước": [
                        startOfYear(subYears(new Date(), 1)),
                        endOfYear(subYears(new Date(), 1)),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              {isAdmin && (
                <Col span={6} xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Trạng thái"
                    name="status"
                    style={{ marginBottom: 10 }}
                  >
                    <Select allowClear placeholder="Chọn trạng thái" showArrow>
                      <Select.Option value={2}>Chưa đối chiếu</Select.Option>
                      <Select.Option value={3}>Chờ đối chiếu</Select.Option>
                      <Select.Option value={4}>Đã đối chiếu</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Người thực hiện"
                  name="userRechecks"
                >
                  <Select
                    allowClear
                    placeholder="Chọn người thực hiện"
                    options={(responseUserRecheck?.data || []).map((item) => ({
                      label: `${item.fullName} (${item.username})`,
                      value: item.username,
                    }))}
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                  ></Select>
                </Form.Item>
              </Col>
              {isAdmin && (
                <Col span={6} xs={24} md={12} lg={6}>
                  <Form.Item label="Nhóm người dùng" name="groupUserIds">
                    <Select
                      style={{ marginBottom: 10 }}
                      allowClear
                      placeholder="Chọn nhóm người dùng"
                      options={(responseGroupUserCheck?.data || [])
                        .map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))
                        .sort((item1, item2) =>
                          helpers.compareListByName(item1, item2, "label")
                        )}
                      showSearch
                      optionFilterProp="label"
                      mode="multiple"
                      maxTagCount="responsive"
                      loading={responseGroupUserCheck.isFetching || responseGroupUserCheck.isLoading}
                    ></Select>
                  </Form.Item>
                </Col>
              )}
            </Row>

            <div className="search-box-button">
              <Button htmlType="button" onClick={resetFormData}>
                Xóa điều kiện
              </Button>
              <Button htmlType="submit" type="primary">
                Tìm kiếm
              </Button>
            </div>
          </>
        </Form>
      </div>

      <DataCompareList
        total={total}
        isLoading={isFetching}
        filter={filter}
        dataSource={dataSource}
        onChangeTable={onChangeTable}
      />
    </>
  );
};

export default CompareData;
