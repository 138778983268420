import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { arrayMoveImmutable } from 'array-move';
import React, { useState } from 'react';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DataListTable } from '../redux/apiTypes';


const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

type Props = {
    dataSource: any,
    columns: any,
    setDataSource: (newtableData: any[]) => void,
}

const SortTable = ({ dataSource, columns, setDataSource }: Props) => {

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el: any) => !!el,
            );
            // console.log('Sorted items: ', newData);
            setDataSource(newData);
        }
    };

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <Table
            tableLayout="auto"
            scroll={{ x: "max-content", y: 'calc(100vh - 500px)' }}
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="index"
            size="small"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />
    );
};

export default SortTable;