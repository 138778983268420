import {
  Button,
  Col,
  Row,
  Table,
  Pagination,
  FormInstance,
  Spin,
  Tag,
  Dropdown,
  Menu,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as Manipulation } from "../../../../assets/logos/action.svg";
import { ReactComponent as RemoveDetail } from "../../../../assets/logos/delete.svg";
import { ReactComponent as Lock } from "../../../../assets/logos/lock-dots.svg";
import { ReactComponent as Unlock } from "../../../../assets/logos/unlock.svg";
import {
  checkPermission,
  exportFile,
} from "../../../common/assets/CommonFunctions";
import ConfirmModalCustom from "../../../common/components/ConfirmModalCustom";
import { PERMISSION_KEYS } from "../../../permissions/ts/Contants";
import { DataCommuneResponse } from "../redux/apiTypes";
import {
  useDeleteCommuneMutation,
  useLazyGetCommuneQuery,
  useLockCommuneMutation,
  useUnlockCommuneMutation,
} from "../redux/communeApi";
import AddCommuneModal from "./AddCommuneModal";
import { DownloadOutlined } from "@ant-design/icons";
// import { useDeletecommuneMutation, useLazyGetPagecommuneQuery, useUnlockcommuneMutation, useLockcommuneMutation } from "../redux/communeApi";
// import { communeResponse } from "../redux/apiTypes";
// import ConfirmModalCustom from "../../../common/components/ConfirmModalCustom";

export interface DataListTable {
  key: string;
  communeCode: string;
  communeName: string;
  status: number;
  createdDate: string;
  modifiedDate: string;
  user: string;
  provinceName?: string;
  districtName?: string;
  districtId?: string;
  // isUsed: number,
}

type Props = {
  form: FormInstance<any>;
  searchParam: any;
};

const CommuneList = ({ form, searchParam }: Props) => {
  const [tableData, setTableData] = useState<DataListTable[]>([]);
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
  const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const pageSizeRef = useRef(10);
  const [currentCommune, setCurrentCommune] = useState<DataListTable>();
  const [addCommune, setAddCommune] = useState(false);
  const [deleteCommuneVisible, setDeleteCommuneVisible] = useState(false);
  const [activateCommuneVisible, setActivateCommuneVisible] = useState(false);

  const [communeTrigger, communeResponse] = useLazyGetCommuneQuery();

  const [deleteCommune] = useDeleteCommuneMutation();
  const [activateCommune] = useUnlockCommuneMutation();
  const [lockCommune] = useLockCommuneMutation();

  const canDelete = checkPermission(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.XOA);
  const canAddNew = checkPermission(
    PERMISSION_KEYS.DANH_MUC.PHUONG_XA.THEM_MOI
  );
  const canEdit = checkPermission(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.SUA);

  useEffect(() => {
    getData();
  }, [searchParam]);

  useEffect(() => {
    if (communeResponse?.data?.content?.length) {
      let newTableData: DataListTable[] = [];
      // totalPages.current = communeResponse?.data?.totalPages;
      communeResponse.data.content.forEach((item: DataCommuneResponse) => {
        newTableData.push({
          key: item.id,
          status: item.status,
          communeCode: item.communeCode,
          communeName: item.communeName,
          createdDate: item.createdDate
            ? moment(new Date(item.createdDate)).format("HH:mm DD/MM/YYYY")
            : "",
          modifiedDate: item.modifiedDate
            ? moment(new Date(item.modifiedDate)).format("HH:mm DD/MM/YYYY")
            : "",
          user: item.modifiedBy,
          provinceName: item.district
            ? item?.district.province.provinceName
            : "",
          districtName: item.district ? item?.district?.districtName : "",
          districtId: item?.districtId,
          // isUsed: item.isUsed,
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
      // totalPages.current = 1;
    }
  }, [communeResponse]);

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, item, index) =>
        (page - 1) * pageSizeRef.current + index + 1,
    },
    {
      title: "Thao Tác",
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <Dropdown placement="bottomLeft" overlay={dropdownOverlay(record)}>
            <Manipulation onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        );
      },
    },
    {
      title: "Mã Phường/Xã",
      dataIndex: "communeCode",
      key: "communeCode",
      align: "left",
    },
    {
      title: "Phường xã",
      dataIndex: "communeName",
      key: "communeName",
      align: "left",
    },
    {
      title: "Quận/Huyện",
      dataIndex: "districtName",
      key: "districtName",
      align: "left",
    },
    {
      title: "Tỉnh/Thành phố",
      dataIndex: "provinceName",
      key: "provinceName",
      align: "left",
    },
    {
      title: "Trạng Thái",
      key: "status",
      dataIndex: "status",
      render: (tag) => (
        <Tag
          style={{ border: "none" }}
          className={`tag-status${!tag ? "_active" : "_err"}`}
        >
          {!tag ? "Hoạt động" : "Không hoạt động"}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "Ngày cập nhật",
      key: "modifiedDate",
      dataIndex: "modifiedDate",
      align: "left",
    },
    {
      title: "Người cập nhật",
      key: "user",
      dataIndex: "user",
      align: "left",
    },
  ];

  const dropdownOverlay = (record: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          // items={menuItems}
          className="menu-actions"
          style={{ padding: "8px", width: "140px" }}
        >
          {canDelete && (
            <Menu.Item
              key="remove-detail"
              onClick={() => deletecommuneToggle(record)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <RemoveDetail style={{ height: 24, width: 24 }} />
                Xóa
              </div>
            </Menu.Item>
          )}
          {record.status ? (
            <Menu.Item
              key="unlock"
              onClick={() => activateCommuneToggle(record)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Unlock style={{ height: 24, width: 24 }} />
                Kích hoạt
              </div>
            </Menu.Item>
          ) : (
            <Menu.Item key="lock" onClick={() => activateCommuneToggle(record)}>
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Lock style={{ height: 24, width: 24 }} />
                Khóa
              </div>
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  };

  // get table data
  const getData = () => {
    communeTrigger({
      searchParam: searchParam
        ? {
            ...searchParam,
          }
        : "",
      page: 0,
      size: pageSizeRef.current,
    });
    setPage(1);
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    setPage(newPage);
    pageSizeRef.current = pageSize;
    communeTrigger({
      searchParam: searchParam
        ? {
            ...searchParam,
          }
        : "",
      page: newPage - 1,
      size: pageSizeRef.current,
    });
  };

  // Thêm mới
  const toggleAddCommune = (record?: DataListTable) => {
    setAddCommune(!addCommune);
    if (record) setCurrentCommune(record);
  };
  const onAddSuccessful = () => {
    toggleAddCommune();
    setCurrentCommune(undefined);
    setPage(1);
    communeTrigger({ size: pageSizeRef.current, page: 0 });
  };

  // Xóa
  const deletecommuneToggle = (record: DataListTable) => {
    setDeleteCommuneVisible(!deleteCommuneVisible);
    setCurrentCommune(record);
  };

  const onDelete = () => {
    if (currentCommune) {
      if (!loadingBtnSubmit) {
        setLoadingBtnSubmit(true);
        deleteCommune([currentCommune.key])
          .unwrap()
          .then((res) => {
            notification.success({
              message: "Xóa Phường/Xã thành công",
            });
            setDeleteCommuneVisible(false);
            setCurrentCommune(undefined);
            setPage(1);
            form.resetFields();
            setLoadingBtnSubmit(false);
            communeTrigger({ size: pageSizeRef.current, page: 0 });
          })
          .catch((err) => {
            notification.error({
              message:
                err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
            });
            setLoadingBtnSubmit(false);
          });
      }
    }
  };

  // // Khóa, kích hoạt
  const activateCommuneToggle = (record: DataListTable) => {
    setActivateCommuneVisible(!activateCommuneVisible);
    setCurrentCommune(record);
  };

  const onActivate = () => {
    if (currentCommune) {
      if (currentCommune.status === 0) {
        if (!loadingBtnSubmit) {
          setLoadingBtnSubmit(true);
          lockCommune([currentCommune.key])
            .unwrap()
            .then((res) => {
              notification.success({
                message: "Khóa Phường/Xã thành công.",
              });
              setActivateCommuneVisible(false);
              setCurrentCommune(undefined);
              setPage(1);
              form.resetFields();
              setLoadingBtnSubmit(false);
              communeTrigger({ size: pageSizeRef.current, page: 0 });
            })
            .catch((err) => {
              notification.error({
                message:
                  err.data?.message ||
                  "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
              });
              setLoadingBtnSubmit(false);
            });
        }
      }

      if (currentCommune.status === 1) {
        if (!loadingBtnSubmit) {
          setLoadingBtnSubmit(true);
          activateCommune([currentCommune.key])
            .unwrap()
            .then((res) => {
              notification.success({
                message: "Kích hoạt Phường/Xã thành công.",
              });
              setActivateCommuneVisible(false);
              setCurrentCommune(undefined);
              setPage(1);
              form.resetFields();
              setLoadingBtnSubmit(false);
              communeTrigger({ size: pageSizeRef.current, page: 0 });
            })
            .catch((err) => {
              notification.error({
                message:
                  err.data?.message ||
                  "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
              });
              setLoadingBtnSubmit(false);
            });
        }
      }
    }
  };

  // xuất excel
  const handleExportExcel = () => {
    let submitValues: any = {};
    // if (searchParam.communeCode) {
    //     submitValues.communeCode = searchParam.communeCode;
    // }
    // if (searchParam.communeName) {
    //     submitValues.communeName = searchParam.communeName;
    // }
    // if (searchParam.districtName) {
    //     submitValues.districtName = searchParam.districtName;
    // }
    // if (searchParam.provinceName) {
    //     submitValues.provinceName = searchParam.provinceName;
    // }
    // if (typeof (searchParam.status) === 'number') {
    //     submitValues.status = searchParam.status;
    // }

    const param = encodeURIComponent(JSON.stringify(submitValues));

    const url =
      process.env.REACT_APP_API_URL +
      `/api/v1/common/commune/export/excel?search=${param}&pageNumber=0&pageSize=20000&sortByProperty=modifiedDate&sortBy=desc`;
    // const url = `http://10.10.10.101:8024/tool-so-hoa/api/v1/common/commune/export/excel?pageNumber=0&pageSize=20&search=%7B%7D&sortBy=desc&sortByProperty=modifiedDate`
    let filename = `${format(
      new Date(),
      "dd-MM-yyyy"
    )}_Danh sách Phường Xã.xlsx`;
    setLoadingExcel(true);
    exportFile(url, filename)
      .then(() => setLoadingExcel(false))
      .catch((error) => {
        setLoadingExcel(false);
        notification.error({
          message:
            error.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
        });
      });
  };

  // reset All state
  const resetState = () => {
    setCurrentCommune(undefined);
    setDeleteCommuneVisible(false);
    setActivateCommuneVisible(false);
    setAddCommune(false);
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <Col span={6}>
          <div>
            Tổng số:{" "}
            <strong>{communeResponse?.data?.totalElements ?? 0}</strong> bản ghi
          </div>
        </Col>
        <div className="search-box-button">
          <Button
            htmlType="button"
            style={{ marginRight: 15 }}
            className="secondary-button"
            onClick={handleExportExcel}
            icon={<DownloadOutlined />}
            loading={loadingExcel}
          >
            Xuất excel
          </Button>
          {canAddNew && (
            <Button
              htmlType="button"
              onClick={() => toggleAddCommune()}
              className="secondary-button"
              type="primary"
            >
              Thêm mới
            </Button>
          )}
        </div>
      </Row>
      <Table<DataListTable>
        loading={communeResponse?.isFetching}
        dataSource={tableData}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        onRow={(record) => {
          return {
            onClick: (event) => toggleAddCommune(record),
          };
        }}
      />

      <Spin spinning={communeResponse?.isFetching} tip="Đang tải...">
        <Pagination
          total={communeResponse?.data?.totalElements || 0}
          defaultPageSize={pageSizeRef.current}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={page}
        />
      </Spin>

      {/* form thêm mới, sửa Phường/Xã */}
      {addCommune && (
        <AddCommuneModal
          visible={addCommune}
          onCancel={resetState}
          currentCommune={currentCommune}
          onAddSuccessful={onAddSuccessful}
          canEdit={canEdit}
        />
      )}

      {/* modal xóa Phường/Xã */}
      {deleteCommuneVisible && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Đóng"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={"error"}
          danger={true}
          title="Xóa Phường/Xã"
          content="Bạn có chắc chắn muốn xóa Phường/Xã này hay không?"
          visible={deleteCommuneVisible}
          onOk={onDelete}
          loadingBtnOk={loadingBtnSubmit}
        />
      )}

      {/* modal khóa, kích hoạt Phường/Xã*/}
      {activateCommuneVisible && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Đóng"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={currentCommune?.status === 0 ? "warning" : "success"}
          title={
            currentCommune?.status === 0
              ? "Khóa Phường/Xã"
              : "Kích hoạt Phường/Xã"
          }
          content={
            currentCommune?.status === 0
              ? "Bạn có chắc chắn muốn khóa Phường/Xã này hay không?"
              : "Bạn có chắc chắn muốn kích hoạt Phường/Xã này hay không?"
          }
          visible={activateCommuneVisible}
          onOk={onActivate}
          loadingBtnOk={loadingBtnSubmit}
        />
      )}
    </div>
  );
};

export default CommuneList;
