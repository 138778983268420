import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../modules/login/redux/UserSlice";
import accountApi from "./api/AccountApi";
import fileApi from "./api/FileApi";
import publicApi from "./api/PublicApi";
import commonApi from "./api/commonApi";
import dataCheckApi from "../modules/data/redux/dataCheckApi";
import campaignApi from "../modules/campaign/redux/campaignApi";
import performReportApi from "../modules/dashboard/perform-report/redux/performReportApi";
import fieldReportApi from "../modules/dashboard/field-report/redux/fieldReportApi";
import emplReportApi from "../modules/dashboard/employee-report/redux/emplReportApi";
import filePdfApi from "../modules/file-pdf/redux/filePdfApi";
import dataInitApi from "../modules/data_init/redux/dataInitApi";
import checkDataApi from "../modules/check-data/redux/checkDataApi";
import dataSyncApi from "../modules/data-sync/redux/dataSyncApi";
import fieldConfigApi from "../modules/field_config/redux/fieldConfigApi";
import dataInitHistoryApi from "../modules/data_init_history/redux/dataInitHistoryApi";
import userApi from "../modules/user/redux/userApi";
import provinceApi from "../modules/category/province/redux/provinceApi";
import communeApi from "../modules/category/commune/redux/communeApi";
import districtApi from "../modules/category/district/redux/districtApi";
import permissionsApi from "../modules/permissions/redux/permissionsApi";
import { checkedReportApi } from "./../modules/info-init-report/checked-report/redux/checkedReportApi";
import { boxDataApi } from "./../modules/box-Data/redux/boxDataApi";
import { rtkQueryErrorLogger } from "./commonService/rtkQueryErrorLogger";
import importReportApi from "../modules/info-init-report/import-report/redux/importReportApi";
import tienDoKiemTraReportApi from "../modules/dashboard/tien-do-kiem-tra/redux/tienDoKiemTraReportApi";
import dataInitHomeApi from "../modules/home/redux/dataInitHomeApi";
import uploadDataReportApi from "../modules/dashboard/upload-du-lieu/redux/uploadDataReportApi";
import nangXuatNhapLieuReportApi from "../modules/dashboard/nang-xuat-nhap-lieu/redux/nangXuatNhapLieuReportApi";
import nangXuatKiemTraReportApi from "../modules/dashboard/nang-xuat-kiem-tra/redux/nangXuatKiemTraReportApi";
import cacTHHTErrorReportApi from "../modules/dashboard/cac-truong-hop-loi/redux/cacTHHTErrorReportApi";
import tienDoNhapLieuReportApi from "../modules/dashboard/tien-do-nhap-lieu/redux/tienDoNhapLieuReportApi";
import nhanSuBaoLoiReportApi from "../modules/dashboard/theo-doi-nhan-su-bao-loi/redux/nhanSuBaoLoiReportApi";
import timeEditReportApi from "../modules/dashboard/theo-doi-thoi-gian-thao-tac-nhan-su/redux/timeEditReportApi";
import groupUserApi from "../modules/group-user/redux/groupUserApi";
import accessHistoryApi from "../modules/access-history/redux/AccessHistoryApi";
import uploadDataPdfReportApi from "../modules/dashboard/upload-du-lieu-pdf/redux/uploadDataPdfReportApi";
import compareDataApi from "../modules/compare-data/redux/compareApi";
import recheckCampaignApi from "../modules/recheck-campaign/redux/recheckCampaignApi";
import { loginApi } from "../modules/login/redux/LoginApi";

export const store = configureStore({
  reducer: {
    [accountApi.reducerPath]: accountApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [dataCheckApi.reducerPath]: dataCheckApi.reducer,
    [performReportApi.reducerPath]: performReportApi.reducer,
    [fieldReportApi.reducerPath]: fieldReportApi.reducer,
    [emplReportApi.reducerPath]: emplReportApi.reducer,
    [filePdfApi.reducerPath]: filePdfApi.reducer,
    [dataInitApi.reducerPath]: dataInitApi.reducer,
    [checkDataApi.reducerPath]: checkDataApi.reducer,
    [dataSyncApi.reducerPath]: dataSyncApi.reducer,
    [fieldConfigApi.reducerPath]: fieldConfigApi.reducer,
    [dataInitHistoryApi.reducerPath]: dataInitHistoryApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [provinceApi.reducerPath]: provinceApi.reducer,
    [communeApi.reducerPath]: communeApi.reducer,
    [districtApi.reducerPath]: districtApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [checkedReportApi.reducerPath]: checkedReportApi.reducer,
    [importReportApi.reducerPath]: importReportApi.reducer,
    [boxDataApi.reducerPath]: boxDataApi.reducer,
    [tienDoKiemTraReportApi.reducerPath]: tienDoKiemTraReportApi.reducer,
    [uploadDataReportApi.reducerPath]: uploadDataReportApi.reducer,
    [dataInitHomeApi.reducerPath]: dataInitHomeApi.reducer,
    [nangXuatNhapLieuReportApi.reducerPath]: nangXuatNhapLieuReportApi.reducer,
    [nangXuatKiemTraReportApi.reducerPath]: nangXuatKiemTraReportApi.reducer,
    [cacTHHTErrorReportApi.reducerPath]: cacTHHTErrorReportApi.reducer,
    [tienDoNhapLieuReportApi.reducerPath]: tienDoNhapLieuReportApi.reducer,
    [nhanSuBaoLoiReportApi.reducerPath]: nhanSuBaoLoiReportApi.reducer,
    [timeEditReportApi.reducerPath]: timeEditReportApi.reducer,
    [groupUserApi.reducerPath]: groupUserApi.reducer,
    [accessHistoryApi.reducerPath]: accessHistoryApi.reducer,
    [uploadDataPdfReportApi.reducerPath]: uploadDataPdfReportApi.reducer,

    [compareDataApi.reducerPath]: compareDataApi.reducer,
    [recheckCampaignApi.reducerPath]: recheckCampaignApi.reducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      accountApi.middleware,
      fileApi.middleware,
      publicApi.middleware,
      loginApi.middleware,
      commonApi.middleware,
      campaignApi.middleware,
      dataCheckApi.middleware,
      performReportApi.middleware,
      fieldReportApi.middleware,
      emplReportApi.middleware,
      filePdfApi.middleware,
      dataInitApi.middleware,
      checkDataApi.middleware,
      dataSyncApi.middleware,
      fieldConfigApi.middleware,
      dataInitHistoryApi.middleware,
      provinceApi.middleware,
      districtApi.middleware,
      communeApi.middleware,
      checkedReportApi.middleware,
      importReportApi.middleware,
      userApi.middleware,
      permissionsApi.middleware,
      boxDataApi.middleware,
      tienDoKiemTraReportApi.middleware,
      uploadDataReportApi.middleware,
      dataInitHomeApi.middleware,
      nangXuatNhapLieuReportApi.middleware,
      nangXuatKiemTraReportApi.middleware,
      cacTHHTErrorReportApi.middleware,
      tienDoNhapLieuReportApi.middleware,
      nhanSuBaoLoiReportApi.middleware,
      timeEditReportApi.middleware,
      groupUserApi.middleware,
      uploadDataPdfReportApi.middleware,

      compareDataApi.middleware,
      recheckCampaignApi.middleware,
      rtkQueryErrorLogger
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
