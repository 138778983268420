import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonListQuery, } from '../../../redux/api/apiTypes';
import { FilePdf, FilePdfParams, UploadFileResponse, UploadFileType } from './apiTypes';
import moment from 'moment';

export const filePdfApi = createApi({
    reducerPath: 'filePdfApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        // lưu ý body của createCampaign, getFilePdf, getCountFilePdf là giống hệt nhau
        // Thêm mới chiến dịch
        createCampaign: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-campaign-input/add`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getFilePdf: builder.query<FilePdf[], { searchParam?: FilePdfParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/file/search-pdf`,
                method: 'POST',
                body: {
                    // status: body?.searchParam?.status ? 1 : undefined,
                    reason: body?.searchParam?.reason || undefined,
                    isLack: body?.searchParam?.isLack || undefined,
                    fileName: body?.searchParam?.fileName,
                    provinceCode: body?.searchParam?.provinceCode,
                    districtCode: body?.searchParam?.districtCode,
                    communeCode: body?.searchParam?.communeCode,
                    createdBy: body?.searchParam?.createdBy,
                    checkStatus: body?.searchParam?.isCheck,
                    fromYear: body?.searchParam?.fromYear,
                    toYear: body?.searchParam?.toYear,
                    numberCheckFrom: body?.searchParam?.numberCheckFrom ? Number(body?.searchParam?.numberCheckFrom) : undefined,
                    numberCheckTo: body?.searchParam?.numberCheckTo ? Number(body?.searchParam?.numberCheckTo) : undefined,
                    fromDateComplete: body?.searchParam?.completeDates && body.searchParam.completeDates[0] ? moment(body.searchParam.completeDates[0]).format("YYYY-MM-DD") : undefined,
                    toDateComplete: body?.searchParam?.completeDates && body.searchParam.completeDates[1] ? moment(body.searchParam.completeDates[1]).format("YYYY-MM-DD") : undefined,
                    fromDateUpload: body?.searchParam?.uploadDates && body.searchParam.uploadDates[0] ? moment(body.searchParam.uploadDates[0]).format("YYYY-MM-DD") : undefined,
                    toDateUpload: body?.searchParam?.uploadDates && body.searchParam.uploadDates[1] ? moment(body.searchParam.uploadDates[1]).format("YYYY-MM-DD") : undefined,
                    paperSize: body?.searchParam?.paperSize && body.searchParam.paperSize !== 'All' ? body.searchParam.paperSize : undefined,
                },
                params: {
                    pageNumber: body.page || 0,
                    pageSize: body.size || 10,
                }
            }),
            transformResponse: (response: { data: FilePdf[] }, meta, arg) => {
                return response.data;
            },
        }),
        getCountFilePdf: builder.query<number, { searchParam?: FilePdfParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/file/amount`,
                method: 'POST',
                body: {
                    // status: body?.searchParam?.status ? 1 : undefined,
                    paperSize: body?.searchParam?.paperSize || undefined,
                    reason: body?.searchParam?.reason || undefined,
                    isLack: body?.searchParam?.isLack || undefined,
                    fileName: body?.searchParam?.fileName,
                    createdBy: body?.searchParam?.createdBy,
                    provinceCode: body?.searchParam?.provinceCode,
                    districtCode: body?.searchParam?.districtCode,
                    communeCode: body?.searchParam?.communeCode,
                    checkStatus: body?.searchParam?.isCheck, // 1: Chưa nhập, 2: Đã nhập, 3: Đã nhập xong
                    fromYear: body?.searchParam?.fromYear,
                    toYear: body?.searchParam?.toYear,
                    numberCheckFrom: body?.searchParam?.numberCheckFrom ? Number(body?.searchParam?.numberCheckFrom) : undefined,
                    numberCheckTo: body?.searchParam?.numberCheckTo ? Number(body?.searchParam?.numberCheckTo) : undefined,
                    fromDateComplete: body?.searchParam?.completeDates && body.searchParam.completeDates[0] ? moment(body.searchParam.completeDates[0]).format("YYYY-MM-DD") : undefined,
                    toDateComplete: body?.searchParam?.completeDates && body.searchParam.completeDates[1] ? moment(body.searchParam.completeDates[1]).format("YYYY-MM-DD") : undefined,
                    fromDateUpload: body?.searchParam?.uploadDates && body.searchParam.uploadDates[0] ? moment(body.searchParam.uploadDates[0]).format("YYYY-MM-DD") : undefined,
                    toDateUpload: body?.searchParam?.uploadDates && body.searchParam.uploadDates[1] ? moment(body.searchParam.uploadDates[1]).format("YYYY-MM-DD") : undefined
                },
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        // Upload file 
        uploadFilePdf: builder.mutation<UploadFileResponse, UploadFileType>({
            query: (body) => ({
                url: `/file/upload`,
                method: 'POST',
                body: body.file
            }),
            transformResponse: (response: { data: UploadFileResponse }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useLazyGetFilePdfQuery,
    useLazyGetCountFilePdfQuery,
    useUploadFilePdfMutation,
    useCreateCampaignMutation
} = filePdfApi;
export default filePdfApi;
