import { Button, Col, Form, Input, Row, Select } from "antd";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { helpers } from "../../assets/js/helper";
import { TYPE_DATA } from "../../assets/ts/constant";
import { DatePicker } from "../../custom-components";
import { Commune, District } from "../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
  useLazyGetGroupUserCheckDataQuery,
  useLazyGetUserCheckDataQuery,
} from "../../redux/api/commonApi";
import { useAppSelector } from "../../redux/Hooks";
import { useLazyGetAllProvinceNoAuthQuery } from "../category/province/redux/provinceApi";
import DataInfoInitHistory from "./components/DataInfoInitHistoryV2";
import { IFilter } from "./redux/apiTypes";
import { useLazyGetAllCheckDataQuery } from "./redux/dataInitHistoryApi";
const { RangePicker } = DatePicker;

const RegisterInfo = () => {
  const [form] = Form.useForm();
  const isAdmin = useAppSelector((state) => state.user.isAdmin);
  const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
  // const [triggerCampaign, responseCampaign] = useLazyGetAllCampaignQuery();
  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();
  const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
  const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);

  const [dataSource, setDataSource] = useState<any[]>([]);

  const [triggerListData, { data, isFetching, error }] =
    useLazyGetAllCheckDataQuery();

  const [triggerGroupUserCheck, responseGroupUserCheck] =
    useLazyGetGroupUserCheckDataQuery();
  const [total, setTotal] = useState<number>(0);

  const [filter, setFilter] = useState<IFilter>({
    provinceId: undefined,
    districtId: undefined,
    communeId: undefined,

    type: undefined,
    fileName: undefined,
    fileName158: undefined,

    fromTimeCheck: undefined,
    toTimeCheck: undefined,
    userCheck: undefined,

    status: undefined,
    numberCheck: undefined,

    dataSource: undefined,
    groupUserIds: undefined,

    page: 0,
    size: 10,
  });

  useEffect(() => {
    triggerGroupUserCheck({});
    triggerUserCheck({});
    triggerProvince({});
    triggerListData({
      page: 0,
      size: 10,
    });
  }, [
    triggerListData,
    triggerGroupUserCheck,
    triggerProvince,
    triggerUserCheck,
  ]);

  useEffect(() => {
    if (data?.content) {
      let newData = data?.content.map((item: any) => ({
        id: item?.id,
        fileName: item?.filePDFName,
        fileName158: item?.dataKeyMap?.find(
          (item: any) => item.dataKey === "tenfilepdf"
        )?.dataValue,
        userChecks: item?.userChecks,
        status: item?.status,
        dataSource: item?.type,
        numberCheck: item?.numberCheck,
        createdDateStr: item?.createdDateStr,
        groupUserIds: item?.groupUserIds,
      }));
      console.log("newData", data?.content, newData);
      setDataSource(newData);
      setTotal(data?.totalElements || 0);
    }
    if (error) {
      setDataSource([]);
      setTotal(0);
    }
  }, [data, error]);

  const formatMomentToDateStartDay = (data?: Moment) => {
    return data ? moment(data).startOf("day").format("yyyy-MM-DD") : undefined;
  };

  const onFinish = (values: any) => {
    const {
      numberCheck,
      status,
      dataSource,
      createDates,
      userChecks,
      ...rest
    } = values;

    const newCreateDates = createDates
      ? createDates.map((item: Moment) => {
          return formatMomentToDateStartDay(item);
        })
      : undefined;

    let newValues = {
      ...filter,
      ...rest,
      page: 0,
      userChecks: userChecks?.length ? userChecks : undefined,
      fromTimeCheck: newCreateDates?.length ? newCreateDates[0] : undefined,
      toTimeCheck: newCreateDates?.length ? newCreateDates[1] : undefined,
      numberCheck: numberCheck ? parseInt(numberCheck) : undefined,
      status: status !== undefined ? parseInt(status) : undefined,
      dataSource: dataSource ? parseInt(dataSource) : undefined,
    };
    // console.log("newValues", newValues);
    triggerListData(newValues);
    setFilter(newValues);
  };

  const onValuesChanged = (changedValues: any) => {
    if (Object.keys(changedValues)[0] === "provinceId") {
      setCommunes([]);
      setDistricts([]);
      if (changedValues.provinceId) {
        districtTrigger(changedValues.provinceId)
          .unwrap()
          .then((res) => setDistricts(res));
      }
      form.setFieldsValue({
        districtId: undefined,
        communeId: undefined,
      });
    } else if (Object.keys(changedValues)[0] === "districtId") {
      setCommunes([]);
      if (changedValues.districtId) {
        communeTrigger(changedValues.districtId)
          .unwrap()
          .then((res) => setCommunes(res));
      }
      form.setFieldsValue({
        communeId: undefined,
      });
    }
  };

  const onChangeTable = (pageNum?: number, pageSize?: number) => {
    // console.log("pageNum", pageNum, "pageSize", pageSize);
    const newFilter = {
      ...filter,
      page: pageNum !== undefined ? pageNum - 1 : filter.page,
      size: pageSize !== undefined ? pageSize : filter.size,
    };
    // console.log("newFilter", newFilter);
    triggerListData(newFilter);
    setFilter(newFilter);
  };

  const resetFormData = () => {
    form.resetFields();
    const newFilter = {
      provinceId: undefined,
      districtId: undefined,
      communeId: undefined,

      type: undefined,
      fileName: undefined,
      fileName158: undefined,

      fromTimeCheck: undefined,
      toTimeCheck: undefined,
      userChecks: undefined,

      status: undefined,
      numberCheck: undefined,

      dataSource: undefined,
      groupUserIds: undefined,

      page: 0,
      size: 10,
    };
    setFilter(newFilter);
  };
  return (
    <>
      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
          onValuesChange={onValuesChanged}
        >
          <div>
            <label className="search-title" style={{ cursor: "pointer" }}>
              TÌM KIẾM
            </label>
          </div>
          <>
            <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Ngày tạo"
                  name="createDates"
                >
                  <RangePicker
                    format="DD/MM/Y"
                    placeholder={["Bắt đầu", "Kết thúc"]}
                    ranges={{
                      "Hôm nay": [new Date(), new Date()],
                      "Tháng này": [startOfMonth(new Date()), new Date()],
                      "Tháng trước": [
                        startOfMonth(subMonths(new Date(), 1)),
                        endOfMonth(subMonths(new Date(), 1)),
                      ],
                      "3 tháng gần nhất": [
                        startOfMonth(subMonths(new Date(), 2)),
                        new Date(),
                      ],
                      "6 tháng gần nhất": [
                        startOfMonth(subMonths(new Date(), 5)),
                        new Date(),
                      ],
                      "Năm nay": [
                        startOfYear(subMonths(new Date(), 5)),
                        new Date(),
                      ],
                      "Năm trước": [
                        startOfYear(subYears(new Date(), 1)),
                        endOfYear(subYears(new Date(), 1)),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Tỉnh/Thành phố"
                  name="provinceId"
                >
                  <Select
                    allowClear
                    placeholder="Chọn tỉnh thành"
                    optionFilterProp="label"
                    options={(responseProvince?.data || []).map((item) => ({
                      label: item.provinceName,
                      value: item.id,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Quận/Huyện"
                  name="districtId"
                >
                  <Select
                    allowClear
                    placeholder="Chọn quận huyện"
                    optionFilterProp="label"
                    options={(districts || []).map((item) => ({
                      label: item.districtName,
                      value: item.id,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Phường/Xã"
                  name="communeId"
                >
                  <Select
                    allowClear
                    optionFilterProp="label"
                    placeholder="Chọn phường xã"
                    options={(communes || []).map((item) => ({
                      label: item.communeName,
                      value: item.id,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Loại dữ liệu"
                  name="type"
                >
                  <Select
                    allowClear
                    placeholder="Chọn loại dữ liệu"
                    optionFilterProp="label"
                    options={Object.keys(TYPE_DATA).map((item) => ({
                      label: `${TYPE_DATA[item]} (${item})`,
                      value: item,
                    }))}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Tên file"
                  name="fileName"
                >
                  <Input
                    placeholder="Nhập tên file"
                    style={{ minWidth: "100%" }}
                    autoComplete="on"
                  />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Tên file 158"
                  name="fileName158"
                >
                  <Input
                    placeholder="Nhập tên file"
                    style={{ minWidth: "100%" }}
                    autoComplete="on"
                  />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Số lần kiểm tra"
                  name="numberCheck"
                >
                  <Input
                    placeholder="Nhập số lần kiểm tra"
                    style={{ minWidth: "100%" }}
                    autoComplete="on"
                  />
                </Form.Item>
              </Col>
              {isAdmin && (
                <Col span={6} xs={24} md={12} lg={6}>
                  <Form.Item
                    label="Nhóm người dùng"
                    name="groupUserIds"
                    style={{ marginBottom: 10 }}
                  >
                    <Select
                      allowClear
                      placeholder="Chọn nhóm người dùng"
                      options={(responseGroupUserCheck?.data || [])
                        .map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))
                        .sort((item1, item2) =>
                          helpers.compareListByName(item1, item2, "label")
                        )}
                      showSearch
                      optionFilterProp="label"
                      mode="multiple"
                      maxTagCount="responsive"
                      loading={
                        responseGroupUserCheck.isFetching ||
                        responseGroupUserCheck.isLoading
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Người kiểm tra"
                  name="userChecks"
                >
                  <Select
                    allowClear
                    placeholder="Chọn người kiểm tra"
                    options={(responseUserCheck?.data || []).map((item) => ({
                      label: `${item.fullName} (${item.username})`,
                      value: item.username,
                    }))}
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  label="Nguồn dữ liệu"
                  name="dataSource"
                >
                  <Select
                    allowClear
                    placeholder="Chọn nguồn dữ liệu"
                    optionFilterProp="label"
                    options={[
                      { label: "Kho dữ liệu nhập liệu", value: 1 },
                      { label: "Kho dữ liệu đối chiếu", value: 2 },
                    ]}
                    showSearch
                    showArrow
                  ></Select>
                </Form.Item>
              </Col>

              <Col span={6} xs={24} md={12} lg={6}>
                <Form.Item
                  label="Trạng thái"
                  name="status"
                  style={{ marginBottom: 10 }}
                >
                  <Select allowClear placeholder="Chọn trạng thái" showArrow>
                    <Select.Option value={0}>Chưa kiểm tra</Select.Option>
                    <Select.Option value={2}>Chờ kiểm tra</Select.Option>
                    <Select.Option value={1}>Đã kiểm tra</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="search-box-button">
              <Button htmlType="button" onClick={() => resetFormData()}>
                Xóa điều kiện
              </Button>
              <Button htmlType="submit" type="primary">
                Tìm kiếm
              </Button>
            </div>
          </>
        </Form>
      </div>

      <DataInfoInitHistory
        total={total}
        isLoading={isFetching}
        filter={filter}
        dataSource={dataSource}
        onChangeTable={onChangeTable}
      />
    </>
  );
};

export default RegisterInfo;
