import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const AccessHistoryApi = createApi({
    reducerPath: "AccessHistoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/v1/log",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({

        // get access history list
        getAccessHistoryLst: builder.mutation({
            query: (body) => ({
                url: `accessLst`,
                method: 'POST',
                body: {
                    fromDate: body.fromDate,
                    toDate: body.toDate,
                    ip: body.ip,
                    username: body.username,
                    type: body.type,
                    page: body.page || 0,
                    sizeOfPage: body.size || 10,
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            }
        }),

    })
});

export const {
    useGetAccessHistoryLstMutation,
} = AccessHistoryApi;
export default AccessHistoryApi;
