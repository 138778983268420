import "./assets/css/index.css";
import { Row } from "antd";
// Components
import { checkPermission } from "../common/assets/CommonFunctions";
import UserChartComponent from "./components/UserChartComponent";
import AdminChartComponent from "./components/AdminChartComponent";
// Commons

const Home = () => {
    return (
        <>
            <Row
                gutter={[15, 15]}
                style={{ width: "100%" }}
                className="main-card"
            >
                {/* {!canDelete ? <AdminChartComponent /> : <UserChartComponent />} */}
                <UserChartComponent />
            </Row>
        </>
    );
};

export default Home;
