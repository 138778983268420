import "../assets/css/index.css";
import { useEffect, useState } from "react";
import { Checkbox, Col, Row, Select, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
// Components
import PieChartUserComponent, { IPieChartData } from "./charts/user/PieChart";
import BarChartUserComponent, { IBarchartData } from "./charts/user/BarChart";
import LegendChartCheckbox, {
    ILegendData,
} from "./charts/user/LegendChartCheckbox";
// Commons
// Reduxs
import { useLazyGetReportDashboardQuery } from "../redux/dataInitHomeApi";
import {
    EDataType,
    ESearchDateType,
    EStatisticalType,
    COLOR_OF_THHT_TYPE,
    LIST_COLOR_DISPLAY_THHT,
    CURRENT_YEAR,
} from "../utils/constants";
import {
    IReportDashboardData,
    IReportDashboardParams,
} from "../redux/apiTypes";
import { CustomSearchDate, ISearchDate } from "./customs/CustomSearchDate";

const { Title } = Typography;

interface IOptionSelectGroup {
    label: string;
    value: EDataType;
    disabled: boolean;
}

interface IColorTypeId {
    id: string;
    name: string;
    color: string;
}

type RangeValue = [Date | null, Date | null] | null;

const optionsPdfType = [
    { label: "THHT thuộc sổ A3", value: "A3" },
    { label: "THHT thuộc sổ A4", value: "A4" },
];

const optionsTHHTType: IOptionSelectGroup[] = [
    { label: "THHT đã nhập", value: EDataType.ENTERED, disabled: false },
    { label: "THHT đã kiểm tra", value: EDataType.CHECKED, disabled: false },
];

const statisticalChartTypeOption: { value: EStatisticalType; label: string }[] =
    [
        { value: EStatisticalType.ALL, label: "Cả 2 biểu đồ" },
        { value: EStatisticalType.BAR, label: "Biểu đồ cột" },
        { value: EStatisticalType.PIE, label: "Biểu đồ tròn" },
    ];

const UserChartComponent = () => {
    const [listShowTypeIdChart, setListShowTypeIdChart] = useState<string[]>(
        []
    );
    const [listTHHTType, setListTHHTType] = useState<number[]>([
        EDataType.CHECKED,
        EDataType.ENTERED,
    ]);
    const [listPdfType, setListPdfType] = useState<string[]>(["A3", "A4"]);
    const [listColorWithTypeId, setListColorWithTypeId] = useState<
        IColorTypeId[]
    >([]);
    const [pieChartDataChecked, setPieChartDataChecked] = useState<
        IPieChartData[]
    >([]);
    const [pieChartDataEntered, setPieChartDataEntered] = useState<
        IPieChartData[]
    >([]);
    const [barChartData, setBarChartData] = useState<IBarchartData[]>([]);
    const [selectedStatisticalChartType, setSelectedStatisticalChartType] =
        useState<EStatisticalType>(EStatisticalType.ALL);
    const [searchDateType, setSearchDateType] = useState<ESearchDateType>(
        ESearchDateType.MONTH
    );
    const [isGetAllData, setIsGetAllData] = useState<boolean>(false);
    const [searchValuesRangeDay, setSearchValuesRangeDay] =
        useState<RangeValue>([new Date(CURRENT_YEAR, 0, 1), new Date()]);
    const [
        triggerGetReportDashBoardByChecked,
        responseReportDashboardByChecked,
    ] = useLazyGetReportDashboardQuery();
    const [
        triggerGetReportDashBoardByEntered,
        responseReportDashboardByEntered,
    ] = useLazyGetReportDashboardQuery();

    const onChangePieChartCheckbox = (e: CheckboxChangeEvent, name: string) => {
        const checked = e.target.checked;
        if (checked && !listShowTypeIdChart.includes(name)) {
            setListShowTypeIdChart(() => {
                return [...listShowTypeIdChart, name];
            });
        } else {
            setListShowTypeIdChart(() => {
                return listShowTypeIdChart.filter((item) => item !== name);
            });
        }
    };

    const onChangeSearchCivil = (data: string[]) => {
        setListPdfType(data);
    };

    const onChangeSearchTHHTTYpe = (data: number[]) => {
        setListTHHTType(data);
    };

    const onChangeStatisticalChartType = (value: EStatisticalType) => {
        setSelectedStatisticalChartType(value);
    };

    const handleSearchDate = (searchParams: ISearchDate) => {
        setSearchValuesRangeDay(searchParams.ranges);
        setSearchDateType(searchParams.type);
    };

    useEffect(() => {
        const searchParams: IReportDashboardParams = {};

        setIsGetAllData(false);
        if (searchDateType === ESearchDateType.MONTH) {
            searchParams.groupByMonth = true;
        } else {
            searchParams.groupByDay = true;
        }

        if (
            searchValuesRangeDay &&
            searchValuesRangeDay[0] &&
            searchValuesRangeDay[1]
        ) {
            searchParams.fromDate = searchValuesRangeDay[0].toISOString();
            searchParams.toDate = searchValuesRangeDay[1].toISOString();
        } else {
            if (ESearchDateType.MONTH) {
                setIsGetAllData(true);
            }
        }

        triggerGetReportDashBoardByChecked({
            searchParam: { ...searchParams, numberCheck: 1 },
        });

        triggerGetReportDashBoardByEntered({
            searchParam: { ...searchParams, numberCheck: 0 },
        });
    }, [
        triggerGetReportDashBoardByChecked,
        triggerGetReportDashBoardByEntered,
        searchDateType,
        searchValuesRangeDay,
    ]);

    const sortReportDashboardData = <T,>(data: T[]): T[] => {
        return data?.sort((a: T, b: T) => {
            //@ts-ignore
            const dateA = (a.name || "").split("-");
            //@ts-ignore
            const dateB = (b.name || "").split("-");
            const valueA =
                searchDateType === ESearchDateType.MONTH
                    ? new Date(Number(dateA[1]), Number(dateA[0]), 1).getTime()
                    : new Date(
                          Number(dateA[2]),
                          Number(dateA[1]),
                          Number(dateA[0])
                      ).getTime();
            const valueB =
                searchDateType === ESearchDateType.MONTH
                    ? new Date(Number(dateB[1]), Number(dateB[0]), 1).getTime()
                    : new Date(
                          Number(dateB[2]),
                          Number(dateB[1]),
                          Number(dateB[0])
                      ).getTime();
            return valueA - valueB;
        });
    };

    const generatePieChartData = (data: IReportDashboardData[]) => {
        if (data.length) {
            const pieChartDataRes: IPieChartData[] =
                data
                    .filter((elm: IReportDashboardData) => {
                        if (listPdfType.includes(elm._id.typePdf)) {
                            return true;
                        }
                        return false;
                    })
                    .map((item: IReportDashboardData, idx: number) => {
                        return {
                            id: idx.toString(),
                            name: item._id.typeId,
                            color: "",
                            value: item.count,
                        };
                    }) ||
                ([] as IPieChartData[]).reduce(
                    (pre: IPieChartData[], curr: IPieChartData) => {
                        const preValue = pre?.find(
                            (item) => item.name === curr.name
                        );
                        if (preValue) {
                            preValue.value = preValue.value + curr.value;
                        } else {
                            pre.push(curr);
                        }
                        return pre;
                    },
                    []
                );

            const pieChartDataMergeDuplicateRes = pieChartDataRes.reduce(
                (pre: IPieChartData[], curr: IPieChartData) => {
                    const preValue = pre?.find(
                        (item) => item.name === curr.name
                    );
                    if (preValue) {
                        preValue.value = preValue.value + curr.value;
                    } else {
                        pre.push(curr);
                    }
                    return pre;
                },
                []
            );

            pieChartDataMergeDuplicateRes.forEach(
                (pieChart: IPieChartData, idx: number) => {
                    pieChart.color = COLOR_OF_THHT_TYPE[pieChart.name] || "";
                }
            );

            return pieChartDataMergeDuplicateRes;
        }
        return [];
    };

    useEffect(() => {
        if (responseReportDashboardByChecked?.data?.data?.length) {
            const pieChartDataMergeDuplicateRes = generatePieChartData(
                responseReportDashboardByChecked.data.data
            );
            setListColorWithTypeId(
                pieChartDataMergeDuplicateRes
                    .map((item: IPieChartData, idx: number) => {
                        return {
                            id: idx.toString(),
                            name: item.name,
                            color: item.color,
                        };
                    })
                    .sort(
                        (a: ILegendData, b: ILegendData) =>
                            LIST_COLOR_DISPLAY_THHT.indexOf(a.name) -
                            LIST_COLOR_DISPLAY_THHT.indexOf(b.name)
                    )
            );
            setPieChartDataChecked(
                sortReportDashboardData<IPieChartData>(
                    pieChartDataMergeDuplicateRes
                )
            );
        } else {
            setPieChartDataChecked([]);
        }

        if (responseReportDashboardByEntered?.data?.data?.length) {
            const pieChartDataMergeDuplicateRes = generatePieChartData(
                responseReportDashboardByEntered.data.data
            );
            setListColorWithTypeId(
                pieChartDataMergeDuplicateRes
                    .map((item: IPieChartData, idx: number) => {
                        return {
                            id: idx.toString(),
                            name: item.name,
                            color: item.color,
                        };
                    })
                    .sort(
                        (a: ILegendData, b: ILegendData) =>
                            LIST_COLOR_DISPLAY_THHT.indexOf(a.name) -
                            LIST_COLOR_DISPLAY_THHT.indexOf(b.name)
                    )
            );
            setPieChartDataEntered(
                sortReportDashboardData<IPieChartData>(
                    pieChartDataMergeDuplicateRes
                )
            );
        } else {
            setPieChartDataEntered([]);
        }
    }, [
        responseReportDashboardByEntered,
        responseReportDashboardByChecked,
        listPdfType,
        listTHHTType,
    ]);

    const getBarChartDate = (data: IReportDashboardData[]) => {
        const dataBarChartRes: { [key: string]: any }[] = [];
        if (data.length) {
            data
                .filter((elm: IReportDashboardData) => {
                    if (listPdfType.includes(elm._id.typePdf)) {
                        return true;
                    }
                    return false;
                })
                .map((item: IReportDashboardData, idx: number) => {
                    const name =
                        searchDateType === ESearchDateType.DAY
                            ? item._id?.createdDateStr
                            : item._id?.createdMonthStr;
                    const hasDataResWithName = dataBarChartRes.find(
                        (el) => el.name === name
                    );
                    if (hasDataResWithName) {
                        hasDataResWithName[item._id.typeId] =
                            hasDataResWithName[item._id.typeId]
                                ? hasDataResWithName[item._id.typeId] +
                                  item.count
                                : item.count;
                    } else {
                        dataBarChartRes.push({
                            name,
                            [item._id.typeId]: item.count,
                        });
                    }
                }) || ([] as IPieChartData[]);
        }
        return dataBarChartRes as IBarchartData[];
    };

    useEffect(() => {
        let dataBarChartCheckedRes: IBarchartData[] = [];
        if (responseReportDashboardByChecked?.data?.data?.length) {
            dataBarChartCheckedRes = getBarChartDate(
                responseReportDashboardByChecked.data.data
            );
        }
        let dataBarChartEnteredRes: IBarchartData[] = [];
        if (responseReportDashboardByEntered?.data?.data?.length) {
            dataBarChartEnteredRes = getBarChartDate(
                responseReportDashboardByEntered.data.data
            );
        }
        let dataBarChartRes: IBarchartData[] = [];
        if (listTHHTType.includes(EDataType.CHECKED)) {
            dataBarChartRes = [...dataBarChartRes, ...dataBarChartCheckedRes];
        }
        if (listTHHTType.includes(EDataType.ENTERED)) {
            dataBarChartRes = [...dataBarChartRes, ...dataBarChartEnteredRes];
        }
        if (dataBarChartRes.length) {
            const dataBarChartMergeData = dataBarChartRes.reduce(
                (pre: IBarchartData[], curr: IBarchartData, idx) => {
                    const foundDataInPre = pre.find(
                        (item: any) => item.name === curr.name
                    );

                    if (foundDataInPre) {
                        Object.keys(foundDataInPre).forEach((el: string) => {
                            if (el !== "name") {
                                if (foundDataInPre[el] && curr[el]) {
                                    foundDataInPre[el] =
                                        //@ts-ignore
                                        foundDataInPre[el] + curr[el];
                                }
                            }
                        });
                    } else {
                        pre.push(curr);
                    }
                    return pre;
                },
                []
            );
            setBarChartData(
                sortReportDashboardData<IBarchartData>(dataBarChartMergeData)
            );
        } else {
            setBarChartData([]);
        }
    }, [
        responseReportDashboardByChecked,
        responseReportDashboardByEntered,
        listPdfType,
        listTHHTType,
    ]);
    return (
        <>
            <div
                className="chart"
                style={{ marginTop: -15, marginBottom: -15 }}
            >
                <Title level={3} className="text-center title">
                    Thống kê tình hình kiểm tra/nhập liệu dữ liệu theo tháng
                </Title>
                <Row justify="center">
                    <div className="time-picker">
                        <label style={{ marginRight: 15 }}>
                            Khoảng thời gian:
                        </label>
                        <CustomSearchDate handleSearch={handleSearchDate} />
                    </div>
                    <div className="time-picker">
                        <label style={{ marginRight: 15 }}>Thống kê:</label>
                        <Select
                            defaultValue={selectedStatisticalChartType}
                            style={{ width: 150 }}
                            onChange={onChangeStatisticalChartType}
                            options={statisticalChartTypeOption}
                        />
                    </div>
                </Row>
                <Checkbox.Group
                    className="checkbox"
                    options={optionsPdfType}
                    onChange={(e) => onChangeSearchCivil(e as string[])}
                    defaultValue={listPdfType}
                />
                <LegendChartCheckbox
                    data={listColorWithTypeId}
                    onChange={onChangePieChartCheckbox}
                />
                <Checkbox.Group
                    style={{
                        textAlign: "right",
                    }}
                    options={optionsTHHTType}
                    onChange={(e) => onChangeSearchTHHTTYpe(e as number[])}
                    defaultValue={listTHHTType}
                />
                <Row
                    align="middle"
                    justify="space-around"
                    style={{ width: "100%", marginTop: 40 }}
                >
                    {[EStatisticalType.ALL, EStatisticalType.PIE].includes(
                        selectedStatisticalChartType
                    ) && (
                        <Col
                            span={12}
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                            }}
                        >
                            <PieChartUserComponent
                                data={pieChartDataChecked}
                                listShow={listShowTypeIdChart}
                                height={240}
                                width={240}
                                type={EDataType.CHECKED}
                            />
                            <PieChartUserComponent
                                data={pieChartDataEntered}
                                listShow={listShowTypeIdChart}
                                height={240}
                                width={240}
                                type={EDataType.ENTERED}
                            />
                        </Col>
                    )}
                    {[EStatisticalType.ALL, EStatisticalType.BAR].includes(
                        selectedStatisticalChartType
                    ) && (
                        <Col span={12}>
                            <BarChartUserComponent
                                data={barChartData}
                                listColor={listColorWithTypeId}
                                listShow={listShowTypeIdChart}
                                maxBarSize={27}
                                searchType={searchDateType}
                                isGetAllData={isGetAllData}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
};

export default UserChartComponent;
