import {
  ClockCircleOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Dropdown,
  Layout,
  Menu,
  Row,
  Typography,
  notification,
} from "antd";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout, setUser } from "../modules/login/redux/UserSlice";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import Sidebar from "./sidebar/Sidebar";
// import { DataHearderContext } from './context/dataHearderProvider';
import { DataInfoContext } from "./context/DataInfoProvider";
import { useLogoutMutation } from "../modules/login/redux/LoginApi";

const { Text } = Typography;
const { Content } = Layout;

const SHOW_ONE_DATA_TABLE = [
  "/info_init/create", //MinhND fix thêm hiển thị "Dữ liệu nhập: ... THHT"
  "/info_init/createLack",
  "/data/file/detail",
];

const SHOW_ONE_DATARESPON = [
  "/data/info_init_detail",
  "/data/info_init_history_detail",
];

const SHOW_ALL = ["/data/check-data", "/data/sync-data"];

const AppLayout = () => {
  const ref = useRef<any>();
  const [title, setTitle] = useState<string>();
  const user = useAppSelector((state) => state.user);
  const [headerHeight, setHeaderHeight] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [triggerLogout] = useLogoutMutation();

  const {
    dataResponse,
    tableData,
    toggleShowTableData,
    isShowTitle,
    setShowTableData,
  } = useContext(DataInfoContext);

  // const { state, dispatchA } = useContext(DataHearderContext);

  const handleDataClick = () => {
    toggleShowTableData();
  };

  useEffect(() => {
    if (location.pathname) {
      setShowTableData(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    selectedKeys();
    if (ref?.current) {
      setHeaderHeight(ref.current.clientHeight);
    }
  }, [location, isShowTitle]);

  const logOut = () => {
    triggerLogout({})
      .unwrap()
      .then(() => {
        dispatch(logout());
        dispatch(setUser({}));
        navigate("/login", { replace: true, state: { from: location } });
      })
      .catch((err) => {
        notification.error({
          message: err.data?.message || "Đăng xuất thất bại!",
        });
      });
  };
  const selectedKeys = () => {
    let activeKey = "";
    if (location?.pathname?.includes("/")) activeKey = "TRANG CHỦ";
    if (location?.pathname?.includes("data/list")) activeKey = "KHO DỮ LIỆU";
    if (location?.pathname?.includes("data/import")) activeKey = "DATA";
    if (location?.pathname?.includes("data/detail")) activeKey = "DATA";
    if (location?.pathname?.includes("data/campaign"))
      activeKey = "DANH SÁCH CHIẾN DỊCH";
    if (location?.pathname?.includes("data/data-entry-campaign"))
      activeKey = "CHIẾN DỊCH NHẬP LIỆU";
    if (location?.pathname?.includes("data/check")) activeKey = "DATA/CHECK";
    // if (location?.pathname?.includes("report/perform")) activeKey = "perform";
    // if (location?.pathname?.includes("report/field")) activeKey = "field";
    // if (location?.pathname?.includes("report/empl")) activeKey = "empl";

    if (location?.pathname?.includes("report-data-info-init/perform"))
      activeKey = "BÁO CÁO HIỆU NĂNG CHỈNH SỬA DỮ LIỆU";
    if (location?.pathname?.includes("report-data-info-init/field"))
      activeKey = "BÁO CÁO THEO TRƯỜNG DỮ LIỆU";
    if (location?.pathname?.includes("report-data-info-init/empl"))
      activeKey = "BÁO CÁO THỜI GIAN LÀM VIỆC";
    if (location?.pathname?.includes("report-data-info-init/import"))
      activeKey = "BÁO CÁO NHẬP LIỆU";
    if (location?.pathname?.includes("report-data-info-init/checked"))
      activeKey = "BÁO CÁO KIỂM TRA";
    if (location?.pathname?.includes("report/tien-do"))
      activeKey = "BÁO CÁO TIẾN ĐỘ KIỂM TRA";
    if (location?.pathname?.includes("report/nang-suat-nhap-lieu"))
      activeKey = "BÁO CÁO NĂNG SUẤT NHẬP LIỆU";
    if (location?.pathname?.includes("report/nang-suat-kiem-tra"))
      activeKey = "BÁO CÁO NĂNG SUẤT KIỂM TRA";
    // if (location?.pathname?.includes("report/upload-Excel")) activeKey = "BÁO CÁO UPLOAD DỮ LIỆU Excel";
    // if (location?.pathname?.includes("report/upload-PDF")) activeKey = "BÁO CÁO UPLOAD DỮ LIỆU PDF";
    if (location?.pathname?.includes("report/upload"))
      activeKey = "BÁO CÁO UPLOAD DỮ LIỆU";
    if (location?.pathname?.includes("report/tien-do-nhap-lieu"))
      activeKey = "BÁO CÁO TIẾN ĐỘ NHẬP LIỆU";
    if (location?.pathname?.includes("report/cac-truong-hop-loi"))
      activeKey = "BÁO CÁO CÁC TRƯỜNG HỢP LỖI";
    if (location?.pathname?.includes("report/nhan-su-bao-loi"))
      activeKey = "BÁO CÁO NHÂN SỰ BÁO LỖI DỮ LIỆU";
    if (location?.pathname?.includes("report/thoi-gian-thao-tac-nhan-su"))
      activeKey = "BÁO CÁO THEO DÕI THỜI GIAN THAO TÁC CỦA NHÂN SỰ";

    if (location?.pathname?.includes("data/du-lieu-khai-sinh"))
      activeKey = "QUẢN LÝ DỮ LIỆU KHAI SINH";
    if (location?.pathname?.includes("data/du-lieu-khai-tu"))
      activeKey = "QUẢN LÝ DỮ LIỆU KHAI TỬ";
    if (location?.pathname?.includes("data/du-lieu-ket-hon"))
      activeKey = "QUẢN LÝ DỮ LIỆU KẾT HÔN";
    if (location?.pathname?.includes("data/du-lieu-hon-nhan"))
      activeKey = "QUẢN LÝ DỮ LIỆU HÔN NHÂN";
    if (location?.pathname?.includes("/data/du-lieu-CMC"))
      activeKey = "QUẢN LÝ DỮ LIỆU CHA,MẸ,CON";
    if (location?.pathname?.includes("data/du-lieu-giam-ho"))
      activeKey = "QUẢN LÝ DỮ LIỆU GIÁM HỘ";
    if (location?.pathname?.includes("data/du-lieu-CDGH"))
      activeKey = "QUẢN LÝ DỮ LIỆU CHẤM DỨT GIÁM HỘ";
    if (location?.pathname?.includes("data/du-lieu-ly-hon"))
      activeKey = "QUẢN LÝ DỮ LIỆU LY HÔN";
    if (location?.pathname?.includes("data/du-lieu-cai-chinh"))
      activeKey = "QUẢN LÝ DỮ LIỆU CẢI CHÍNH";
    if (location?.pathname?.includes("data/du-lieu-con-nuoi"))
      activeKey = "QUẢN LÝ DỮ LIỆU CON NUÔI ";

    if (location?.pathname?.includes("info_init/create"))
      activeKey = " NHẬP MỚI DỮ LIỆU";
    if (location?.pathname?.includes("info_init/createv2"))
      activeKey = "NHẬP LIỆU CÓ GỢI Ý";
    if (location?.pathname?.includes("info_init/createv3"))
      activeKey = "NHẬP LIỆU DỮ RESIZE";
    if (location?.pathname?.includes("info_init/createLack"))
      activeKey = "NHẬP LIỆU BỔ SUNG";
    if (location?.pathname?.includes("data/info_init"))
      activeKey = "KHO DỮ LIỆU NHẬP LIỆU";
    if (location?.pathname?.includes("data/field"))
      activeKey = "CẤU HÌNH TRƯỜNG THÔNG TIN";
    if (location?.pathname?.includes("info_init/createv2"))
      activeKey = "NHẬP NHIỀU THHT";
    if (location?.pathname?.includes("data/file"))
      activeKey = "DANH SÁCH FILE SCAN";
    // new feature
    if (location?.pathname?.includes("data/recheck"))
      activeKey = "ĐỐI CHIẾU DỮ LIỆU";
    if (location?.pathname?.includes("data/recheck-list"))
      activeKey = "KHO DỮ LIỆU ĐỐI CHIẾU";
    if (location?.pathname?.includes("/data/recheck-campaign"))
      activeKey = "CHIẾN DỊCH ĐỐI CHIẾU";
    if (location?.pathname?.includes("data/check-data"))
      activeKey = "KIỂM TRA DỮ LIỆU";
    //
    if (location?.pathname?.includes("data/info_init_history"))
      activeKey = "KHO DỮ LIỆU KIỂM TRA";
    if (location?.pathname?.includes("data/user-list"))
      activeKey = "DATA/USER-LIST";
    if (location?.pathname?.includes("category/province"))
      activeKey = "DANH MỤC TỈNH THÀNH";
    if (location?.pathname?.includes("category/district"))
      activeKey = "DANH MỤC QUẬN HUYỆN";
    if (location?.pathname?.includes("category/commune"))
      activeKey = "DANH MỤC PHƯỜNG XÃ";
    if (location?.pathname?.includes("data/box-data"))
      activeKey = "KHO DỮ LIỆU PX";
    if (location?.pathname?.includes("data/sync-data"))
      activeKey = "ĐỒNG BỘ DỮ LIỆU";

    if (location?.pathname?.includes("setting/user"))
      activeKey = "QUẢN LÝ NGƯỜI DÙNG";
    if (location?.pathname?.includes("setting/user/add"))
      activeKey = "THÊM MỚI NGƯỜI DÙNG";
    if (location?.pathname?.includes("setting/user/detail")) {
      if (isShowTitle === false) {
        activeKey = "THÔNG TIN NGƯỜI DÙNG";
      } else activeKey = "CHỈNH SỬA NGƯỜI DÙNG";
    }
    // if (location?.pathname?.includes("setting/user/detail")) {
    //     useEffect(() => {
    //         if (isShowTitle === false) {
    //             activeKey = "THÔNG TIN NGƯỜI DÙNG";
    //         } else activeKey = "CHỈNH SỬA NGƯỜI DÙNG";
    //     })
    // }
    if (location?.pathname?.includes("setting/role")) activeKey = "PHÂN QUYỀN";
    if (location?.pathname?.includes("setting/group-user"))
      activeKey = "NHÓM NGƯỜI DÙNG";
    if (location?.pathname?.includes("setting/access-history"))
      activeKey = "LỊCH SỬ TRUY CẬP";

    if (location?.pathname?.includes("data/list-sync-data"))
      activeKey = "DANH SÁCH ĐỒNG BỘ";
    setTitle(activeKey);
  };
  const menu = (
    <Menu>
      <Menu.Item key="detail">
        <Link to="/account/detail">
          <span>Thông tin tài khoản</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="change_pw">
        <Link to="/account/change-password">
          <span>Đổi mật khẩu</span>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => logOut()} key="logout">
        <span>Đăng xuất</span>
      </Menu.Item>
    </Menu>
  );

  // const [triggerData, dataResponse] = useLazyGetRandomDataQuery<any>();

  const handleCopy = () => {
    const textToCopy =
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port +
      "/data/info_init_detail/" +
      dataResponse?.id;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          notification.success({ message: "Đã sao chép thành công" });
          // console.log("Đã sao chép thành công:", textToCopy);
        })
        .catch((error) => {
          notification.error({ message: "Lỗi sao chép" });
          // console.error("Lỗi sao chép:", error);
        });
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        notification.success({ message: "Đã sao chép thành công" });
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Sidebar headerHeight={headerHeight} />
        <Layout>
          {/*<div ref={ref}>*/}
          {/*    <AppHeader />*/}
          {/*</div>*/}
          <Row
            align="middle"
            justify="space-between"
            style={{ paddingRight: 20, height: 42 }}
          >
            <div style={{ display: "flex", marginTop: 10 }}>
              <Breadcrumb
                separator=""
                style={{
                  marginBottom: "0.531rem",
                  marginLeft: 20,
                  marginRight: 5,
                }}
              >
                {title && (
                  <Breadcrumb.Item href="">
                    <span>
                      <strong> {title}</strong>
                    </span>
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
              <div
                className="header-block"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 9,
                  alignItems: "center",
                }}
              >
                <div style={{ marginTop: -1 }}>
                  {SHOW_ALL.concat(SHOW_ONE_DATARESPON).includes(
                    location?.pathname
                  ) ||
                  SHOW_ONE_DATARESPON.some((SHOW_ONE_DATARESPON) =>
                    location?.pathname.includes(SHOW_ONE_DATARESPON)
                  ) ? (
                    <>
                      <span style={{ color: "white" }}>-</span>
                      <UserOutlined
                        style={{
                          cursor: "pointer",
                          width: 20,
                          height: 20,
                          marginBottom: 0,
                          color: "white",
                        }}
                      />
                      {/* <span className='font-size-16 cursor-pointer'>{user.user.fullName || user.user.username} - </span> */}
                      <span className="font-size-16 cursor-pointer">
                        {dataResponse?.createBy} -{" "}
                      </span>
                      <ClockCircleOutlined
                        style={{
                          cursor: "pointer",
                          width: 20,
                          height: 20,
                          marginBottom: 0,
                          color: "white",
                        }}
                      />
                      <span style={{ margin: "0px 3px", color: "white" }}>
                        {moment(dataResponse?.createdDate).format(
                          "HH:mm DD/MM/YYYY"
                        )}{" "}
                      </span>

                      <span style={{ color: "white" }}>
                        -
                        <a
                          href={"/data/info_init_detail/" + dataResponse?.id}
                          target="_blank"
                        >
                          <span style={{ color: "white" }}>
                            {" "}
                            <u> ID</u>
                          </span>
                        </a>{" "}
                        <span
                          onClick={handleCopy}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          - <u>Copy link</u>{" "}
                        </span>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {SHOW_ALL.concat(SHOW_ONE_DATA_TABLE).includes(
                    location?.pathname
                  ) ||
                  SHOW_ONE_DATA_TABLE.some((SHOW_ONE_DATA_TABLE) =>
                    location?.pathname.includes(SHOW_ONE_DATA_TABLE)
                  ) ? (
                    <>
                      <span style={{ color: "white" }}>-</span>
                      <span
                        style={{
                          marginTop: -1,
                          color: "#FFFF00",
                          marginRight: 20,
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataClick()}
                      >{`Dữ liệu đã nhập: ${tableData} THHT`}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              overlayStyle={{ zIndex: 9999 }}
            >
              <Text className="font-size-16 cursor-pointer">
                {user.user.fullName || user.user.username} <DownOutlined />
              </Text>
            </Dropdown>
          </Row>

          <Content
            className="layout-content"
            style={{
              height: `calc(100vh - ${headerHeight}px)`,
              overflow: "auto",
              paddingBottom: "60px",
              paddingTop: 20,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
