import { Button, Checkbox, Form, Input, InputNumber, Modal, Select } from "antd"
import React, { useEffect, useState } from "react"
import { TYPE_FIELD } from "../../../assets/ts/constant"
import CreateData from "./CreateData"

type Props = {
    visible: boolean,
    toggleModal: () => void,
    createFieldItemSuccess: (item: any) => void,
    currentItem: any
}

const CreateFieldItem = ({ visible, toggleModal, currentItem, createFieldItemSuccess }: Props) => {
    const [form] = Form.useForm();
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        if (currentItem) {
            form.setFieldsValue({ ...currentItem });
        }
    }, [currentItem]);

    const onSave = () => {
        let values = form.getFieldsValue();
        form.validateFields().then(res => {
            createFieldItemSuccess({ ...values, data: tags.filter(item => item) });
            toggleModal();
        })
    }

    const onSaveNext = () => {
        let values = form.getFieldsValue();
        form.validateFields().then(res => {
            createFieldItemSuccess({ ...values, data: tags.filter(item => item) });
            form.resetFields();
        })
    }

    return (
        <Modal
            visible={visible}
            title={currentItem ? "Chỉnh sửa trường thông tin" : "Thêm mới trường thông tin"}
            onCancel={() => toggleModal()}
            width={600}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={toggleModal}>
                        Đóng
                    </Button>
                    {!currentItem ? <Button htmlType='button' type='primary' onClick={onSaveNext}>
                        Thêm trường (Tiếp tục)
                    </Button> : <></>}
                    <Button htmlType='button' type='primary' onClick={onSave}>
                        {currentItem ? "Lưu" : "Thêm trường"}
                    </Button>
                </React.Fragment>
            ]}>
            <Form
                name="searchBox"
                autoComplete="off"
                form={form}
                labelWrap
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                labelAlign="left"
                requiredMark={false}
                colon={false}
            >
                <Form.Item
                    label="Tên trường dữ liệu"
                    name="dataKey"
                    rules={[
                        { required: true, whitespace: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <Input un-disabled placeholder="Nhập tên trường dữ liệu" style={{ minWidth: '100%' }}  autoComplete="on"/>
                </Form.Item>
                <Form.Item
                    label="Mã trường dữ liệu"
                    name="dataKeyAscii"
                    rules={[{
                        required: true,
                        message: 'Trường dữ liệu bắt buộc.'
                    }, {
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: 'Mã trường dữ liệu không chứa kí tự đặc biệt hoặc dấu cách.'
                    }]}
                >
                    <Input un-disabled placeholder="Nhập mã trường dữ liệu" style={{ minWidth: '100%' }} autoComplete="on"/>
                </Form.Item>
                <Form.Item
                    label="Gợi ý"
                    name="placeholder"
                >
                    <Input un-disabled placeholder="Nhập gợi ý" style={{ minWidth: '100%' }} autoComplete="on"/>
                </Form.Item>
                <Form.Item
                    label="Loại dữ liệu"
                    name="type"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <Select
                        un-disabled
                        allowClear
                        placeholder="Chọn loại dữ liệu"
                        optionFilterProp='label'
                        options={TYPE_FIELD.map(item => ({
                            label: item.id,
                            value: item.name
                        }))}
                        showSearch
                        showArrow
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Định dạng"
                    name="format"
                >
                    <Input un-disabled placeholder="Nhập định dạng" style={{ minWidth: '100%' }} autoComplete="on"/>
                </Form.Item>
                <Form.Item
                    label="Mặc định"
                    name="isDefault"
                    valuePropName="checked"
                >
                    <Checkbox un-disabled />
                </Form.Item>
                <Form.Item
                    label="Độ dài tối thiểu"
                    name="minLength"
                >
                    <InputNumber un-disabled min={0} style={{ minWidth: '100%' }} precision={0} autoComplete="on"/>
                </Form.Item>
                <Form.Item
                    label="Độ dài tối đa"
                    name="maxLength"
                >
                    <InputNumber un-disabled min={0} style={{ minWidth: '100%' }} precision={0} autoComplete="on"/>
                </Form.Item>
                <Form.Item
                    label="Hiển thị nhập liệu"
                    name="isDisplay"
                    valuePropName="checked"
                >
                    <Checkbox un-disabled />
                </Form.Item>
                <Form.Item
                    label="Hiển thị chỉnh sửa"
                    name="isEdit"
                    valuePropName="checked"
                >
                    <Checkbox un-disabled />
                </Form.Item>
                {/* <Form.Item
                    label="Dữ liệu mặc định"
                    name="data"
                    valuePropName="data"
                >
                    <CreateData
                        tags={tags}
                        setTags={setTags}
                    />
                </Form.Item> */}
            </Form>
        </Modal>
    )
}

export default CreateFieldItem;