import { CheckOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import {
  Col,
  Dropdown,
  FormInstance,
  Menu,
  notification,
  Pagination,
  Row,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useLazyGetCampaignQuery, useUpdateStatusMutation } from "../redux/campaignApi";
import { ReactComponent as Manipulation } from "../../../assets/logos/action.svg";
import UpdateCampaign from "./UpdateCampaign";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";

interface DataListTable {
  key: string;
  index: number;
  createBy?: string;
  createdDate?: string;
  campaignName: string;
  number: string;
  campaignType: string;
  description: string;
  isDefault?: any;
  type: string;
  percentComplete: string;
  totalTHHTError?: string;
  listGroupUsers?: string;
  percent?: number;
  status?: number;
  groupUserIdsStr?: string[]
}

type Props = {
  form: FormInstance<any>;
  searchParam: any;
};

const STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

const CampaignList = ({ form, searchParam }: Props) => {
  const [triggerCampaign, responseDataInfo] = useLazyGetCampaignQuery();
  const [tableData, setTableData] = useState<DataListTable[]>();
  const [page, setPage] = useState(1);
  const [currentCampaign, setCurrentCampaign] = useState<DataListTable>();
  const pageSizeRef = useRef(10);

  const [updateCampaignVisible, setUpdateCampaignVisible] = useState<{
    isOpen: boolean;
    key: string;
  }>({
    isOpen: false,
    key: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  useEffect(() => {
    if (responseDataInfo?.data?.content?.length) {
      let newTableData: DataListTable[] = [];
      responseDataInfo.data.content.forEach((item, index) => {
        newTableData.push({
          key: item.id,
          index: index + 1,
          createBy: item.createBy,
          createdDate: item.createdDate
            ? moment(item.createdDate).format("HH:mm DD/MM/YYYY")
            : "",
          campaignName: item.campaignName || "",
          description: item.description || "",
          // number: item.number && item.number > 0 ? (item.number + "") : (item.percent + "%"),
          number: (item.totalTHHTComplete ?? 0) + "/" + (item.totalTHHT ?? 0),
          campaignType:
            item.number && item.number > 0
              ? "Phân công tuyệt đối"
              : "Phân công tương đối",
          type: item.type ? "Dữ liệu nhập liệu" : "Dữ liệu excel",
          isDefault: item.isDefault ? (
            <CheckOutlined style={{ color: "#49cc90" }} />
          ) : (
            <></>
          ),
          status: item?.status,
          percent: item?.percent,
          percentComplete:
            item.percentComplete == null ? "0%" : item.percentComplete + "%",
          totalTHHTError: item.totalTHHTError ?? "0",
          groupUserIdsStr: item?.listGroupUsers?.length > 0
              ? item?.listGroupUsers?.map(e => e.id)
              : [],
          listGroupUsers:
            item?.listGroupUsers?.length > 0
              ? item?.listGroupUsers?.map(e => e.name).join(", ")
              : "",
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
    }
  }, [responseDataInfo]);

  const getData = () => {
    triggerCampaign({
      searchParam,
      page: 0,
      size: pageSizeRef.current,
    });
    setPage(1);
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    setPage(newPage);
    pageSizeRef.current = pageSize;
    triggerCampaign({
      searchParam: searchParam
        ? {
            ...searchParam,
            isCheck: searchParam.isCheck
              ? searchParam.isCheck === 1
                ? true
                : false
              : undefined,
          }
        : "",
      page: newPage - 1,
      size: pageSizeRef.current,
    });
  };

  const StatusElement = ({ value }: { value: number }) => {
    const statusStyle = {
      padding: "4px",
      borderRadius: "4px",
    };
    const STATUS = {
      ACTIVE: 1,
      INACTIVE: 0,
    };

    const statusMapping = {
      [STATUS.INACTIVE]: {
        color: "#E11D48",
        text: "Không hoạt động",
        bg: "#FFE4E6",
      },
      [STATUS.ACTIVE]: {
        color: "green",
        text: "Hoạt động",
        bg: "#CCFBF1",
      },
    };
    const status = statusMapping[value] || { color: "gray", text: "Unknown" };

    return (
      <span
        style={{
          ...statusStyle,
          color: status.color,
          backgroundColor: status.bg,
        }}
      >
        {status.text}
      </span>
    );
  };

  const onClickItem = (item: any, key: string) => {
    setCurrentCampaign(item);
    setUpdateCampaignVisible({
      isOpen: true,
      key: key,
    });
  };

  const getContent = (value: number, record: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu>
          <Menu.Item key="activate" onClick={() => onClickItem(record, "1")}>
            {value === STATUS.ACTIVE ? (
              <>
                <LockOutlined style={{ marginRight: "4px" }} />
                Khóa
              </>
            ) : (
              <>
                <UnlockOutlined style={{ marginRight: "4px" }} />
                Kích hoạt
              </>
            )}
          </Menu.Item>
        </Menu>
      </div>
    );
  };

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, record, index) =>
        (page - 1) * pageSizeRef.current + index + 1,
      width: 70,
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value, record) => {
        return (
          <Dropdown placement="bottomLeft" overlay={getContent(value, record)}>
            <Manipulation
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
        );
      },
    },
    {
      title: "Tên chiến dịch",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (value, record, index) => (
        <span
          style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }}
          onClick={(e) => onClickItem(record, "2")}
        >
          {record.campaignName}
        </span>
      ),
    },
    {
      title: "Phần trăm hoàn thành",
      dataIndex: "percentComplete",
      key: "percentComplete",
    },
    {
      title: "Loại phân công",
      dataIndex: "typeDivide",
      key: "typeDivide",
      render: (value) => (
        <span>
          {value === 1 ? "Phân công tuyệt đối" : "Phân công tương đối"}
        </span>
      ),
    },
    {
      title: "Phần trăm dữ liệu",
      dataIndex: "percent",
      key: "percent",
      align: "center",
      render: (value) => <span>{value || "-"}</span>,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => <StatusElement value={value} />,
    },
    {
      title: "Nhóm người dùng",
      dataIndex: "listGroupUsers",
      key: "listGroupUsers",
      render: (value) => <span>{value || "-"}</span>,
    },
    // {
    //     title: "Loại chiến dịch",
    //     dataIndex: "campaignType",
    //     key: "campaignType",
    // },
    // {
    //     title: "Số lượng THHT",
    //     dataIndex: "number",
    //     key: "number",
    // },
    // {
    //     title: "Số THHT lỗi",
    //     dataIndex: "totalTHHTError",
    //     key: "totalTHHTError",
    // },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    // {
    //     title: "Người tạo",
    //     dataIndex: "createBy",
    //     key: "createBy",
    // },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
    },
  ];

  const resetState = () => {
    setCurrentCampaign(undefined);
    setUpdateCampaignVisible({
      isOpen: false,
      key: "",
    });
  };

  const [triggerStatusCampaign, { isLoading: isLoadingUpdate }] =
    useUpdateStatusMutation();

  const handleActivate = () => {
    triggerStatusCampaign({
      id: currentCampaign?.key,
      status:
        currentCampaign?.status === STATUS.INACTIVE
          ? STATUS.ACTIVE
          : STATUS.INACTIVE,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: `${
            currentCampaign?.status === STATUS.ACTIVE ? "Khóa" : "Kích hoạt"
          } chiến dịch thành công!`,
        });
        resetState();
        getData()
      })
      .catch(() => {
        notification.error({ message: "Có lỗi xảy ra!" });
      });
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <div>
          Tổng số: <strong>{responseDataInfo?.data?.totalElements ?? 0}</strong>{" "}
          bản ghi
        </div>
        <Col span={6}></Col>
        {/* <div className='search-box-button'>
                    <Button
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Tạo chiến dịch</Button>
                </div> */}
      </Row>
      <Table<DataListTable>
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onClickItem(record, "2"), // click row
          };
        }}
        loading={responseDataInfo?.isFetching}
        dataSource={tableData}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <Pagination
        total={responseDataInfo?.data?.totalElements || 0}
        defaultPageSize={pageSizeRef.current}
        defaultCurrent={1}
        locale={{ items_per_page: " dòng" }}
        pageSizeOptions={[10, 20, 50, 100]}
        onChange={changePage}
        showSizeChanger
        current={page}
      />
      {/* {createCampaignVisible ? (
        <CreateCampaign
          currentCampaign={currentCampaign}
          visible={createCampaignVisible}
          toggleModal={toggleModal}
          createCampaignSuccess={createCampaignSuccess}
        />
      ) : (
        <></>
      )} */}
      <UpdateCampaign
        visible={
          updateCampaignVisible.isOpen && updateCampaignVisible.key === "2"
        }
        onClose={resetState}
        campaign={currentCampaign}
        resetState={resetState}
      />

      <ConfirmModalCustom
        onCancel={resetState}
        cancelText="Đóng"
        okText="Đồng ý"
        showCancel={true}
        showOk={true}
        type={currentCampaign?.status === STATUS.ACTIVE ? "warning" : "success"}
        centered
        title={`${
          currentCampaign?.status === STATUS.ACTIVE ? "Khóa" : "Kích hoạt"
        } chiến dịch`}
        content={`Bạn có chắc chắn muốn ${
          currentCampaign?.status === STATUS.ACTIVE ? "khóa" : "kích hoạt"
        } chiến dịch này hay không?`}
        visible={
          updateCampaignVisible.isOpen && updateCampaignVisible.key === "1"
        }
        onOk={handleActivate}
        loadingBtnOk={isLoadingUpdate}
      />
    </div>
  );
};

export default CampaignList;
