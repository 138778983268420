import { Button, Checkbox, Col, Dropdown, Form, Input, Menu, notification, Row, Select, Spin, Table } from "antd";
import { useInsertDataInfoInitMutation, useLazyGetFieldConfigQuery, useLazyGetFilePdfByIdQuery, useLazyGetRandomDataPdfQuery, useUpdateStatusFilePdfMutation } from "../redux/dataInitApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FieldConfig } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import { DEFAULT_FIELD_CONFIG, QN_UBND, TYPE_FILE_PDF } from "../../../assets/ts/constant";
import { useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { helpers } from "../../../assets/js/helper";
import { BlockOutlined, CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusCircleFilled, SettingOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PDFPageProxy } from "react-pdf";
import SplitPane from "react-split-pane";
import moment from "moment";

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
    return width;
};

const CreateDataInfoVer2 = () => {
    const [form] = Form.useForm();
    const param = useParams();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [year, setYear] = useState<string>();
    const [displayFlex, setDisplayFlex] = useState(true);
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();
    const [height, setHeight] = useState<any>();
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [insertData, { isLoading }] = useInsertDataInfoInitMutation();
    const [updateStatusPdf] = useUpdateStatusFilePdfMutation();
    const [triggerFilePdf, filePdfResponse] = useLazyGetRandomDataPdfQuery();
    const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmSuccessVisible, setConfirmSuccessVisible] = useState(false);
    const [multiDataInit, setMultiDataInit] = useState(false);
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [provinceObject, setProvinceObject] = useState();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districtObject, setDistrictObject] = useState();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [communeObject, setCommuneObject] = useState();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetCommuneAllQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetDistrictAllQuery();
    const [pdfFileName, setPdfFileName] = useState('');
    const [listActive, setListActive] = useState<String[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const wrapperDiv = useRef<HTMLInputElement>(null);
    // const width = useWidth(wrapperDiv);

    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const canvas1 = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    const [docKey, setDocKey] = useState(0);
    useEffect(() => {
        if (clientY === 0 || !canvas.current || !isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
        if (canvas1.current) {
            const context1 = canvas1.current.getContext('2d');
            const width1 = canvas1.current.width;
            const rect1 = canvas1.current.getBoundingClientRect();
            context1.save();
            context1.beginPath();
            context1.moveTo(0, clientY);
            context1.lineTo(width1, clientY);
            context1.strokeStyle = 'red';
            context1.stroke();
            context1.restore();
        }
    }, [clientY, isRendered]);
    useEffect(() => {
        if (prevScale.current === scale) {
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
            setDocKey((s) => ++s);
            setIsRendered(false);
        }
    }, [scale])
    const onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if (!isDrag) {
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top) * scaleY);
        }
    }

    const file = useMemo(() => {
        if(filePdfResponse?.data?.id){
            return {
                url: process.env.REACT_APP_API_URL + `/api/v1/file/download-pdf/${filePdfResponse?.data?.id}`,
                httpHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        }

    }, [filePdfResponse?.data?.id]);
    const [initialPos, setInitialPos] = useState(0);
    const [initialSize, setInitialSize] = useState(0);

    useEffect(() => {
        triggerFilePdf({});
    }, [triggerFilePdf]);

    useEffect(() => {
        const slider = document.getElementById("block-view-pdf");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.clientY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
                setIsDrag(false);
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                setIsDrag(true);
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.clientY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
                setIsDrag(true);
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    const initial = (e: any) => {
        let resizable = document.getElementById('block-view-pdf');
        setInitialPos(e.clientX);
        setInitialSize(resizable?.offsetWidth || 0);
    }

    const resize = (e: any) => {
        let resizable = document.getElementById('block-view-pdf');
        if (resizable) {
            resizable.style.width = `${initialSize + e.clientX - initialPos}px`;
        }
    }

    useEffect(() => {
        let typeFile = filePdfResponse?.data?.fileName?.split(".")?.[0];
        if (typeFile && !filePdfResponse.isFetching) {
            triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) });
        }
    }, [triggerGetFieldConfig, filePdfResponse?.data?.fileName, filePdfResponse.isFetching]);

    useEffect(() => {
        triggerProvince({}).unwrap().then(res => {
            setProvinceObject(res?.reduce((a: any, v: any) => ({ ...a, [DEFAULT_FIELD_CONFIG.tinhThanh]: v.id }), {}));
        });
        communeAllTrigger({}).unwrap().then(res => {
            setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
            setCommunes(res);
        });
        districtAllTrigger({}).unwrap().then(res => {
            setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
            setDistricts(res)
        });
    }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

    useEffect(() => {
        if (fieldConfigResponse?.data?.fieldKeyMap?.length &&
            filePdfResponse?.data?.id &&
            provinceObject &&
            districtObject &&
            responseCommuneAll?.data
        ) {
            setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
        } else {
            setKeyMaps([]);
        }
    }, [fieldConfigResponse?.data?.type, fieldConfigResponse?.data?.year, filePdfResponse?.data?.id, provinceObject, districtObject, responseCommuneAll?.data]);

    const getContent = (text: string, record: any) => {
        return (
            <Menu>
                {tableData?.length > 1 ? <Menu.Item key="delete" onClick={() => deleteData(record)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DeleteOutlined style={{ width: 24, height: 24, marginRight: 10, marginTop: 10 }} />Xóa
                    </div>
                </Menu.Item> : <></>}
                <Menu.Item key="duplicate" onClick={() => duplicateData(record)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BlockOutlined style={{ width: 24, height: 24, marginRight: 10, marginTop: 10 }} />Nhân bản
                    </div>
                </Menu.Item>
                <Menu.Item key="create" onClick={() => createInitData(record)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PlusCircleFilled style={{ width: 24, height: 24, marginRight: 10, marginTop: 10 }} />Thêm mới
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    const getColumns = (getContentProps: any, convertTypeDataToElementV2: any) => {
        return columns.map(item => {
            if (item.key === "action") {
                return {
                    ...item,
                    render: ((text: any, record: any) => (
                        <Dropdown overlay={() => getContentProps(text, record)}>
                            <SettingOutlined />
                        </Dropdown>
                    )),
                }
            }
            return {
                ...item,
                render: (text: any, record: any) => {
                    return convertTypeDataToElementV2(item?.key && record?.[item.key], year === "A3" ? record?.key : "")
                }
            };
        })
    }

    const duplicateData = (record: any) => {
        let key = Math.random();
        let keySo = DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so;
        let newSo = record[keySo].dataValue ? ("00000".substr(0, (record[keySo].dataValue || "").length - ((parseInt(record[keySo].dataValue || '', 10) + 1 + "").length))
            + (parseInt(record[keySo].dataValue || '', 10) + 1)) : undefined;
        let arrayKeyMap = Object.keys(record).map(item => {
            if (item === keySo && record?.[item]?.dataValue) {
                return {
                    ...record[item],
                    dataValue: newSo
                }
            }
            return record[item];
        });
        form.setFieldsValue({
            ...form.getFieldsValue(),
            ...arrayKeyMap.reduce((a: any, v: any) => ({ ...a, [`${v.dataKeyAscii}${key || ''}`]: v.dataValue }), {})
        })
        setTableData([
            ...(tableData || []),
            {
                ...record,
                [keySo]: { ...record[keySo], dataValue: newSo },
                key
            }
        ]);

        // console.log(DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so);
        // console.log(record[keySo]);

    }

    const deleteData = (record: any) => {
        setTableData(tableData.filter(row => row.key !== record.key));
    }

    const createInitData = (record: any) => {
        let key = Math.random();
        let keySo = DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so;
        let newSo = record[keySo].dataValue ? ("00000".substr(0, (record[keySo].dataValue || "").length - ((parseInt(record[keySo].dataValue || '', 10) + 1 + "").length))
            + (parseInt(record[keySo].dataValue || '', 10) + 1)) : undefined;
        let arrayKeyMap = keyMaps.map(item => {
            if (item.dataKeyAscii === keySo) {
                return {
                    ...item,
                    dataValue: newSo
                }
            }
            return item;
        });
        form.setFieldsValue({
            ...form.getFieldsValue(),
            ...arrayKeyMap.reduce((a: any, v: any) => ({ ...a, [`${v.dataKeyAscii}${key || ''}`]: v.dataValue }), {})
        })
        setTableData([
            ...(tableData || []),
            {
                ...arrayKeyMap.reduce((a: any, v: any) => ({ ...a, [`${v.dataKeyAscii}`]: v }), {}),
                [keySo]: { ...record[keySo], dataValue: newSo },
                key
            }
        ]);
    }

    const convertYearToTypePdf = (typeFile: string) => {
        // KS.1994.01.--.001.PDF
        let yearStr = filePdfResponse?.data?.fileName?.split(".")?.[1];
        let yearNum = yearStr ? parseInt(yearStr) : 0;
        // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
        // Chuyển từ năm sang file loại file: A3, A4
        if (filePdfResponse?.data?.paperSize) {
            setYear(filePdfResponse?.data?.paperSize);
            return filePdfResponse?.data?.paperSize;
        }
        if (typeFile === "KH" && yearNum === 2007 && filePdfResponse?.data?.communeCode === "xatinhantay") {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum > 2006) {
            if (typeFile === "KH" &&
                filePdfResponse?.data?.communeCode === "sotuphap" &&
                filePdfResponse?.data?.districtCode === "thanhphoquangngai") {
                if (yearNum < 2011) {
                    setYear(TYPE_FILE_PDF.A3);
                    return TYPE_FILE_PDF.A3;
                }
            }
            setYear(TYPE_FILE_PDF.A4);
            return TYPE_FILE_PDF.A4;
        }
        if (yearNum > 1977) {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        switch (typeFile) {
            case "KS":
                setYear(TYPE_FILE_PDF.A4);
                return TYPE_FILE_PDF.A4
            default:
                setYear(TYPE_FILE_PDF.A4);
                return TYPE_FILE_PDF.A4;
        }
    }

    const onFinish = (values: any) => {
        form.validateFields().then(res => {
            toggleConfirmModal();
        }).catch(err => {
            notification.error({
                message: err?.errorFields?.[0].name?.[0] &&
                    err?.errorFields?.[0].errors?.[0] ?
                    `${err?.errorFields?.[0].name?.[0]}: ${err?.errorFields?.[0].errors?.[0]}!`
                    : "Có lỗi nhập liệu"
            });
        })
    }

    const onSubmitCheckData = (isCheck?: Boolean) => {
        let keyMapsObj = keyMaps.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})
        if (fieldConfigResponse?.data?.type &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa]) {
            if (multiDataInit) {
                let listDataInfoInit = tableData.map(x => {
                    let arrKey = Object.keys(x);
                    return arrKey.map(y => x?.[y]).filter(z => typeof z === "object");
                })
                insertData({
                    keyMaps: listDataInfoInit,
                    file_pdf_id: filePdfResponse?.data?.id,
                    isCheck: isCheck || false,
                    typeId: fieldConfigResponse?.data?.type,
                    communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
                    districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
                    provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
                }).unwrap()
                    .then((res: any) => {
                        let oldData = form.getFieldsValue();
                        notification.success({ message: "Nhập liệu thành công" });
                        resetData();
                        setConfirmVisible(false);
                        if (isCheck) {
                            triggerFilePdf({});
                            triggerFilePdfId(null)
                        } else {
                            // TODO: Không thấy call api chỗ này
                            triggerFilePdfId(filePdfResponse?.data?.id);
                        }
                        setInitData(fieldConfigResponse?.data?.fieldKeyMap, oldData);
                        setListActive([]);
                    }).catch((err: any) => {
                        notification.error({ message: err.data?.message || "Cập nhật thất bại" });
                    })
            } else {
                insertData({
                    keyMaps: [keyMaps],
                    file_pdf_id: filePdfResponse?.data?.id,
                    isCheck: isCheck || false,
                    typeId: fieldConfigResponse?.data?.type,
                    communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
                    districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
                    provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
                }).unwrap()
                    .then((res: any) => {
                        let oldData = form.getFieldsValue();
                        notification.success({ message: "Nhập liệu thành công" });
                        resetData();
                        setConfirmVisible(false);
                        if (isCheck) {
                            triggerFilePdf({});
                            triggerFilePdfId(null)
                        } else {
                            // TODO: Không thấy call api chỗ này
                            triggerFilePdfId(filePdfResponse?.data?.id);
                        }
                        setInitData(fieldConfigResponse?.data?.fieldKeyMap, oldData);
                        setListActive([]);
                    }).catch((err: any) => {
                        notification.error({ message: err.data?.message || "Cập nhật thất bại" });
                    })
            }
        } else {
            notification.error({ message: "Có lỗi nhập liệu!" });
        }
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    const toggleConfirmSuccessModal = () => {
        setConfirmSuccessVisible(!confirmSuccessVisible);
    }

    const setInitData = (dataKeyMaps: any, oldData?: any) => {
        // TODO: Thêm trường dữ liệu mặc định trong cấu hình trường dữ liệu
        if (dataKeyMaps?.length) {
            let key = multiDataInit ? Math.random() : '';
            let defaultDataKeyMaps = dataKeyMaps.map((item: any, index: number) => {
                let newItem = setDataItem(item, oldData, key);
                if (newItem) {
                    return newItem;
                } else if (item.defaultValue) {
                    form.setFieldsValue({
                        [`${item.dataKeyAscii}${key || ''}`]: item.defaultValue
                    })
                    return {
                        ...item,
                        dataValue: item.defaultValue
                    }
                }
                return item;
            });
            setColumns([
                {
                    title: '',
                    dataIndex: "action",
                    key: "action",
                    align: "center",
                    fixed: 'left',
                    width: 70,
                },
                ...dataKeyMaps.map((item: any, index: number) => {
                    return {
                        title: <div>{item.dataKey}{item.isDefault ? <span style={{ color: "rgb(255, 77, 79)", marginLeft: 5 }}>*</span> : <></>}</div>,
                        dataIndex: item.dataKeyAscii,
                        key: item.dataKeyAscii,
                        align: "center",
                        width: 250,
                    }
                })
            ]);
            setKeyMaps(defaultDataKeyMaps);
            setTableData([defaultDataKeyMaps.reduce((a: any, v: any) => ({
                ...a,
                [v.dataKeyAscii]: v
            }), { key })]);

        }
    }

    // Set dữ liệu ban đầu
    const setDataItem = (item: FieldConfig, oldData?: any, key?: any) => {
        if (item.dataKeyAscii) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                    districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh]).unwrap().then(res => {
                        setDistricts(res);
                    });
                    if (item.dataKeyAscii) {
                        form.setFieldsValue({ [`${item.dataKeyAscii}${key || ''}`]: oldData?.[item.dataKeyAscii] || provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] });
                    }
                    return {
                        ...item,
                        dataValue: oldData?.[item.dataKeyAscii] || provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] || "",
                    }
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                    if (filePdfResponse?.data?.districtCode) {
                        communeTrigger(districtObject?.[filePdfResponse?.data?.districtCode]).unwrap().then(res => {
                            setCommunes(res);
                        });
                        if (item.dataKeyAscii) {
                            form.setFieldsValue({ [`${item.dataKeyAscii}${key || ''}`]: oldData?.[item.dataKeyAscii] || districtObject?.[filePdfResponse?.data?.districtCode] });
                        }
                        return {
                            ...item,
                            dataValue: oldData?.[item.dataKeyAscii] || districtObject?.[filePdfResponse?.data?.districtCode] || "",
                        }
                    }
                    return null;
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
                    if (filePdfResponse?.data?.communeCode) {
                        let newCommuneObject = responseCommuneAll?.data?.filter((item) => filePdfResponse?.data?.districtCode && item.districtId === districtObject?.[filePdfResponse?.data?.districtCode])?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {});
                        setCommuneObject(newCommuneObject);
                        if (item.dataKeyAscii) {
                            form.setFieldsValue({ [`${item.dataKeyAscii}${key || ''}`]: oldData?.[item.dataKeyAscii] || newCommuneObject?.[filePdfResponse?.data?.communeCode] });
                        }
                        return {
                            ...item,
                            dataValue: oldData?.[item.dataKeyAscii] || newCommuneObject?.[filePdfResponse?.data?.communeCode] || "",
                        }
                    }
                    return null;
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo:
                    let pdfFileNames = filePdfResponse?.data?.fileName?.split('.');
                    let quyenSo = `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}`;
                    if (item.dataKeyAscii) {
                        form.setFieldsValue({ [`${item.dataKeyAscii}${key || ''}`]: oldData?.[item.dataKeyAscii] || quyenSo });
                    }
                    return {
                        ...item,
                        dataValue: oldData?.[item.dataKeyAscii] || `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}` || "",
                    }
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf:
                    let pdfFileNames1 = filePdfResponse?.data?.fileName?.split('.');
                    let type = filePdfResponse?.data?.fileName?.split(".")?.[0];
                    let tenFilePdf = `${type}.${pdfFileNames1?.[1] || '--'}.${pdfFileNames1?.[2] || '--'}.--..pdf`;
                    form.setFieldsValue({
                        [`${item.dataKeyAscii}${key || ''}`]: tenFilePdf,
                    })
                    setPdfFileName(tenFilePdf);
                    return {
                        ...item,
                        dataValue: tenFilePdf,
                    }
                case DEFAULT_FIELD_CONFIG.noiDangKy:
                case DEFAULT_FIELD_CONFIG.noiCap:
                    if (filePdfResponse?.data?.districtCode && filePdfResponse?.data?.communeCode && item?.data?.length) {
                        let key = `${DEFAULT_FIELD_CONFIG.tinhThanh}-${filePdfResponse?.data?.districtCode}-${filePdfResponse?.data?.communeCode}`;
                        form.setFieldsValue({
                            [item.dataKeyAscii]: QN_UBND[key],
                        });
                        return {
                            ...item,
                            dataValue: QN_UBND[key],
                        }
                    }
            }
        }
        return null
    }


    const changeData = async (value: any, key: any, item: FieldConfig) => {
        // Nếu không cho sửa thì không được sửa
        if (!item.isEdit) return;
        if (item?.dataKeyAscii) {
            form.setFieldsValue({ [`${item.dataKeyAscii}${key || ''}`]: value });
        }
        switch (item.dataKeyAscii) {
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                form.setFieldsValue({ [`quanHuyen${key || ''}`]: undefined, [`phuongXa${key || ''}`]: undefined })
                setDistricts([]);
                setCommunes([]);
                districtTrigger(value).unwrap()
                    .then((res: any) => setDistricts(res));
                break;
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                form.setFieldsValue({ [`phuongXa${key || ''}`]: undefined })
                setCommunes([]);
                communeTrigger(value).unwrap()
                    .then(res => setCommunes(res));
                break;
        }
        // KS.2007.01.2007-07-11.082.pdf
        let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy;
        let tenFilePdf = "";
        if (changeFilePdf) {
            let type = filePdfResponse?.data?.fileName?.split(".")?.[0];
            let so = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ? value : form?.getFieldsValue()?.so;
            let quyenSo = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ? value : form?.getFieldsValue()?.quyenSo) || "").split("/");
            let ngayDangKy = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "").split(".");
            // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
            let temp = '';
            if (ngayDangKy?.length === 3) {
                temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}`;
            } else if (ngayDangKy?.length === 2) {
                temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
            } else {
                temp = `${ngayDangKy?.[0] || ""}--`;
            }
            tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${temp}.${so || ""}.pdf`;
            form.setFieldsValue({
                [`${DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf}${key || ''}`]: tenFilePdf,
            })
            setPdfFileName(tenFilePdf);
        }
        if (multiDataInit) {
            let newTableData = [...tableData];
            newTableData = newTableData.map(row => {
                if (row.key === key) return {
                    ...row,
                    [item.dataKeyAscii]: {
                        ...item,
                        dataValue: value,
                    },
                    [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]: {
                        item,
                        dataValue: changeFilePdf ? tenFilePdf : row?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]?.dataValue
                    }
                }
                return row;
            })
            setTableData(newTableData);
        } else {
            setKeyMaps((keyMaps || []).map((x: any, i: number) => {
                if (x.dataKeyAscii === item.dataKeyAscii) return {
                    ...x,
                    dataValue: value || "",
                }
                if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf && changeFilePdf) return {
                    ...x,
                    dataValue: tenFilePdf
                }
                if ((x.dataKeyAscii || "").toLowerCase().includes((item.dataKeyAscii || "").toLowerCase()) &&
                    (x.dataKeyAscii || "").toLowerCase().includes("bangchu")) {
                    let txt = helpers.dateToWord(value || '');
                    form.setFieldsValue({ [`${x.dataKeyAscii}${key || ''}`]: txt });
                    return {
                        ...x,
                        dataValue: txt
                    }
                }
                return x;
            }));
        }
        if (item?.dataKeyAscii) {
            form.setFieldsValue({ [`${item.dataKeyAscii}${key || ''}`]: value });
        }
    }

    // Chuyển trường dữ liệu thành item
    // <ExclamationCircleOutlined title="Đã duyệt qua" style={{ marginLeft: 5, color: "#faad14" }} />
    const convertTypeDataToElementV2 = (item: FieldConfig, key: any) => {
        if (!item) return <></>;
        if (item.data && item.data.length) {
            return <Form.Item
                name={`${item.dataKeyAscii}${key || ''}`}
                style={{ marginBottom: 10 }}
                rules={createRules(item)}
            >
                <Select
                    disabled={!item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id + ''
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                    onBlur={() => onBlurItem(item, key)}
                >
                </Select>
            </Form.Item>
        }
        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                    return <Form.Item
                        name={`${item.dataKeyAscii}${key || ''}`}
                        style={{ marginBottom: 10 }}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item, key)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                    return <Form.Item
                        name={`${item.dataKeyAscii}${key || ''}`}
                        style={{ marginBottom: 10 }}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item, key)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
                    return <Form.Item
                        name={`${item.dataKeyAscii}${key || ''}`}
                        style={{ marginBottom: 10 }}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item, key)}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            name={`${item.dataKeyAscii}${key || ''}`}
            style={{ marginBottom: 10 }}
            rules={createRules(item)}
        ><Input.TextArea
                placeholder={item.placeholder || ""}
                disabled={!item.isEdit || item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo}
                onFocus={(e) => e.currentTarget.select()}
                // onChange={(e) => changeData(e.target.value, index, item)}
                // value={item.dataValue}
                onBlur={(e) => updateData(e.target.value, key, item)}
                autoComplete="on"
            /></Form.Item>;
    }

    // const convertDataToDataTable = () => {
    //     return tableData?.map((item: any, index: number) => )
    // }

    // Chuyển trường dữ liệu thành item
    // <ExclamationCircleOutlined title="Đã duyệt qua" style={{ marginLeft: 5, color: "#faad14" }} />
    const convertTypeDataToElement = (item: FieldConfig, key?: any) => {
        if (item.data && item.data.length) {
            return <Form.Item
                name={item.dataKeyAscii}
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                    <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                </div>}
                rules={createRules(item)}
            >
                <Select
                    disabled={!item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id + ''
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                    onBlur={() => onBlurItem(item)}
                >
                </Select>
            </Form.Item>
        }
        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, key, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            name={item.dataKeyAscii}
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                    <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{item.dataKey || ""}</div>
            </div>}
            rules={createRules(item)}
        ><Input.TextArea
                placeholder={item.placeholder || ""}
                disabled={!item.isEdit || item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo}
                onFocus={(e) => e.currentTarget.select()}
                // onChange={(e) => changeData(e.target.value, index, item)}
                // value={item.dataValue}
                onBlur={(e) => updateData(e.target.value, key, item)}
                autoComplete="on"
            /></Form.Item>;
    }

    const onBlurItem = (item: any, key?: any) => {
        if (listActive.indexOf(item.dataKeyAscii) === -1) {
            setListActive([...listActive, `${item.dataKeyAscii}${key || ''}`]);
        }
    }

    // Format lại dữ liệu sau khi nhập liệu
    const updateData = (value: any, key: any, item: FieldConfig) => {
        if (item.type === "date") {
            value = helpers.customDate(value);
            changeData(value, key, item);
        } else if (item.type === "time") {
            value = helpers.customTime(value, item.format);
            changeData(value, key, item);
        } else if (item.format?.toLowerCase().includes("capitalcase")) {
            value = helpers.toTitleCase(value);
            changeData(value, key, item);
        }
        changeData((value || "").trim(), key, item);
        form.validateFields([`${item.dataKeyAscii}${key || ''}`]);
        onBlurItem(item, key);
    }


    // Tạo rules validate cho trường dữ liệu
    const createRules = (item: any) => {
        let rules: Object[] = [];
        if (item.isDefault) {
            rules = [...rules, { required: true, message: "Trường dữ liệu bắt buộc" }];
        }
        if (item.format && item.format !== "capitalcase") {
            rules = [...rules, { pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng" }];
        }
         // Check ngày không hợp lệ
         if (item.type === "date") {
            rules = [
              ...rules,
              () => ({
                validator(_: any, value: any) {
                  let pattern = new RegExp(item.format);
                  if (
                    item.type === "date" &&
                    value &&
                    !moment(value, "DD.MM.YYYY").isValid() &&
                    pattern.test(value)
                  ) {
                    return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
                  }
                  return Promise.resolve();
                },
              }),
            ];
        }
        return rules;
    }

    const resetData = () => {
        if (fieldConfigResponse?.data?.fieldKeyMap?.length) {
            setKeyMaps(fieldConfigResponse?.data?.fieldKeyMap);
        } else {
            setKeyMaps([]);
        }
        form.resetFields();
    }

    const onSubmitSuccess = () => {
        updateStatusPdf({ id: filePdfResponse?.data?.id, isCheck: true, status: ((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") }).unwrap().then((res: any) => {
            resetData();
            setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
            triggerFilePdf({});
            triggerFilePdfId(null);
            toggleConfirmSuccessModal();
            setListActive([]);
        })
    }

    const passFile = () => {
        resetData();
        setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
        triggerFilePdf({});
        setListActive([]);
        if (detailResponse?.currentData?.id) {
            triggerFilePdfId(null);
        }
    }

    const checkActiveSave = () => {
        let check = true;
        keyMaps.filter(x => x.isEdit && x.isDisplay).map(item => {
            if (item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo &&
                listActive.indexOf(item.dataKeyAscii) === -1) check = false;
        })
        return check;
    }

    const changeSize = (size: number) => {
        if (displayFlex) {
            setWidth(size);
            setClientY(clientY * (size / width));
        } else {
            setHeight(size);
            setClientY(clientY * (size / height));
        }
        setIsRendered(false);
    }

    return (
        // <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
        <>
            <div className="content-box" >
                {/* <div className="header-block" style={{ display: "flex", justifyContent: "flex-end" }}>

                </div> */}
                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    >
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <label className='search-title'>File PDF: {filePdfResponse?.data?.fileName || ''}</label>
                                <ZoomOut
                                    onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale === 1 ? 1 : scale - 1 })}
                                    style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                <ZoomIn
                                    onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale + 1 })}
                                    style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                <Rotate
                                    onClick={() => setRotate(rotate - 90)}
                                    style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                                <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                            </div>
                            <div style={{
                                overflowY: "auto",
                                height: displayFlex ? "calc(100vh - 160px)" : "calc(100% - 55px)",
                            }}
                                id="block-view-pdf" >
                                {/* <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page width={width} rotate={rotate} scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document> */}
                                <TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev + 1 });
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev - 1 || 1 });
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            {Array.from(new Array(Math.round(numPages / 2)), (el, index) => (
                                                <div style={{ display: "flex" }} key={`grouppage${index + 1}`}>
                                                    <Page
                                                        width={width}
                                                        // height={height}
                                                        rotate={rotate}
                                                        scale={scale}
                                                        key={`page_${index * 2 + 1}`}
                                                        pageNumber={index * 2 + 1}
                                                        canvasRef={canvas}
                                                        onRenderSuccess={onRenderSuccess}
                                                        onClick={onClickPage}
                                                    />
                                                    {
                                                        (index * 2 + 2) <= numPages ? <Page
                                                            width={width}
                                                            // height={height}
                                                            rotate={rotate}
                                                            scale={scale}
                                                            key={`page_${index * 2 + 2}`}
                                                            pageNumber={index * 2 + 2}
                                                            canvasRef={canvas1}
                                                            onRenderSuccess={onRenderSuccess}
                                                            onClick={onClickPage}
                                                        /> : <></>
                                                    }
                                                </div>
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </div>
                        {/* {displayFlex ? <div
                            id='Draggable'
                            draggable='true'
                            onDragStart={initial}
                            onDrag={resize}
                        ></div> : <></>} */}
                        <div
                            style={displayFlex ? {
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                position: "absolute"
                                // resize: "horizontal",
                                // overflow: "auto"
                            } : {
                                // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                // position: "absolute"
                            }}
                        >
                            <Form
                                name="searchBox"
                                autoComplete="off"
                                form={form}
                                colon={false}
                                style={{ marginTop: 0 }}
                                layout="vertical"
                                labelWrap
                                labelAlign="left"
                            >
                                <label className='search-title'>
                                    Dữ liệu THHT: {!multiDataInit && pdfFileName}
                                    {year === "A3" ? <Checkbox
                                        style={{ marginLeft: 10 }}
                                        checked={multiDataInit}
                                        onChange={(e) => setMultiDataInit(e.target.checked)}
                                    >Nhập nhiều THHT 1 lần</Checkbox> : <></>}
                                </label>
                                {multiDataInit ?
                                    <div>
                                        <Table<any>
                                            dataSource={tableData}
                                            columns={getColumns(getContent, convertTypeDataToElementV2)}
                                            tableLayout="auto"
                                            pagination={false}
                                            scroll={{ x: "max-content" }}
                                        />
                                    </div>
                                    : <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                            {keyMaps.length && keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                                return (<Col span={8} key={item.dataKeyAscii}>
                                                    {convertTypeDataToElement(item, null)}
                                                </Col>)
                                            })}
                                        </Row>
                                    </div>}

                            </Form>
                            {filePdfResponse?.data?.id ? <div className='search-box-button' style={{ marginTop: 15 }}>
                                <Button onClick={() => passFile()}>Bỏ qua</Button>
                                <Button onClick={() => { resetData(); setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined); setListActive([]); }} htmlType='button'>
                                    Xóa ô nhập liệu
                                </Button>
                                <Button disabled={!checkActiveSave() && !multiDataInit} type='primary' onClick={onFinish}>Lưu dữ liệu</Button>
                                <Button
                                    type='primary'
                                    style={((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                                        { backgroundColor: "#ff4d4f", border: "#ff4d4f" } :
                                        { backgroundColor: "#49cc90", border: "#49cc90" }}
                                    onClick={toggleConfirmSuccessModal}>
                                    {((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                                        "Báo lỗi" : `Nhập đủ (${detailResponse?.currentData?.numberCheck || filePdfResponse?.data?.numberCheck})`}
                                </Button>
                            </div> : <></>}
                        </div>
                    </SplitPane>
                </div>
            </div >
            {/* okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"} */}
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"}
                    note={year === "A4" ? undefined : "*Chú ý: Lưu (Nhập THHT khác) chỉ áp dụng cho ảnh có nhiều trường hợp hộ tịch"}
                    okSuccess="Lưu (Chuyển file pdf khác)"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn lưu thông tin?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={() => onSubmitCheckData()}
                    handleSuccess={() => onSubmitCheckData(true)}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={570}
                    okBtnDanger={true} />
                : <></>}
            {confirmSuccessVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={((!filePdfResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                        ["Bạn có chắc chắn muốn báo cáo lỗi!"] :
                        ["Bạn có chắc chắn hoàn thành nhập liệu file này và chuyển sang file khác?",
                            "(Dữ liệu hiện tại đang nhập sẽ không được lưu)"]}
                    handleCancel={toggleConfirmSuccessModal}
                    handleOk={onSubmitSuccess}
                    type={1}
                    visible={confirmSuccessVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>}
        </ >
    )
}

export default CreateDataInfoVer2;
