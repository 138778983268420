import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const compareDataApi = createApi({
  reducerPath: "compareDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/api/v1/tool/",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (params) => {
        const { page, size, ...rest } = params;
        return {
          url: `/data-campaign-recheck/getRecheckFilePage?search=${
            rest ? encodeURIComponent(JSON.stringify(rest)) : ""
          }`,
          method: "GET",
          params: {
            page,
            size,
          },
        };
      },
      transformResponse: (response: any) => {
        return response.data;
      },
      // transformErrorResponse: (response: any) => {
      //   return response.data;
      // }
    }),

    createCampaign: builder.mutation({
      query: (body) => ({
        url: `data-campaign-recheck/add`,
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: any }, meta, arg) => {
        return response.data;
      },
      // transformErrorResponse: (response: any) => {
      //   return response.data;
      // }
    }),

    getInfoPdfFile: builder.query<any, any>({
      query: (body) => ({
        url: `/data-campaign-recheck/recheck-data/${body}`,
        method: "GET",
      }),
      transformResponse: (response: { data: any }, meta, arg) => {
        return response.data;
      },
      // transformErrorResponse: (response: any) => {
      //   return response.data;
      // }
    }),
  }),
});

export const {
  useLazyGetListDataQuery,
  useCreateCampaignMutation,
  useLazyGetInfoPdfFileQuery,
} = compareDataApi;
export default compareDataApi;
