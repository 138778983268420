import {
    PieChart,
    Pie,
    Cell,
    Label,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { EDataType } from "../../../utils/constants";
import { CustomToolTipUserPieChart } from "../../customs/CustomToolTipUserPieChart";

export interface IPieChartData {
    id: string;
    name: string;
    color: string;
    value: number;
}

interface ITotalTextType {
    type: EDataType;
    text: string;
}

interface Props {
    data: IPieChartData[];
    listShow: string[];
    width: number;
    height: number;
    type: number;
}

const totalTextList: ITotalTextType[] = [
    {
        type: EDataType.CHECKED,
        text: "đã kiểm tra",
    },
    {
        type: EDataType.ENTERED,
        text: "đã nhập",
    },
];

const defautEmptyData: IPieChartData[] = [
    {
        id: "1",
        name: "empty",
        color: "#5d5959",
        value: 1,
    },
];

export default function PieChartUserComponent({
    data,
    listShow,
    width,
    height,
    type,
}: Props) {
    const dataShow = data?.filter((item) => {
        return !listShow.length || listShow.includes(item.name);
    });

    const totalData = dataShow?.reduce(
        (pre: number, curr: IPieChartData, i: number) => {
            return pre + (curr?.value | 0);
        },
        0
    );

    return (
        <ResponsiveContainer width="100%" height={height}>
            <PieChart className="pie-chart">
                <Pie
                    data={dataShow.length ? dataShow : defautEmptyData}
                    dataKey="value"
                    innerRadius="70%"
                    outerRadius="100%"
                    fill="#8884d8"
                    startAngle={80}
                    endAngle={450}
                >
                    <Label
                        width={100}
                        value={"Tống số THHT"}
                        position="centerBottom"
                        className="label-top"
                    />
                     <Label
                        width={100}
                        value={
                            totalTextList.find(
                                (totalText) => totalText.type === type
                            )?.text
                        }
                        position="center"
                        className="label-center"
                    />
                    <Label
                        width={100}
                        value={new Intl.NumberFormat().format(totalData)}
                        position="centerTop"
                        className="label-bottom"
                    />
                    {(dataShow.length ? dataShow : defautEmptyData)?.map(
                        (entry: any, index: number) => {
                            return (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            );
                        }
                    )}
                </Pie>

                {dataShow.length && <Tooltip content={<CustomToolTipUserPieChart />} />}
            </PieChart>
        </ResponsiveContainer>
    );
}
