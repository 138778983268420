import { createSlice } from "@reduxjs/toolkit";
import { loginApi } from "./LoginApi";
import { User } from "../../../redux/api/apiTypes";

interface UserState {
  userToken: string;
  isLoading: boolean;
  errorMessage: string;
  user: User;
  isAdmin: boolean;
}

const initialState: UserState = {
  userToken: "",
  isLoading: false,
  errorMessage: "",
  user: {},
  isAdmin: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    let localStateStr = localStorage.getItem("user");
    let localState: User = localStateStr ? JSON.parse(localStateStr) : {};
    return {
      ...initialState,
      ...(Object.keys(localState)?.length
        ? { user: localState, isAdmin: localState?.username?.startsWith("admin") }
        : {}),
    };
  },
  reducers: {
    logout: (state: UserState) => {
      state.userToken = "";
      state.errorMessage = "";
      state.isAdmin = false;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
    },
    setToken: (state: UserState, action: { payload: string }) => {
      state.userToken = action.payload;
    },
    setUser: (state: UserState, action: { payload: User }) => {
      state.user = action.payload;
      // console.log(action.payload);
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAdmin = ["admin", "admin1", "admin2", "admin3", "DiuPT1", "HangLT", "adminHuynv"].includes(
        action.payload.username || ""
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(loginApi.endpoints.login.matchFulfilled, (state, action) => {
        state.userToken = action.payload?.data?.accessTokenInfo?.accessToken;
        state.user = action.payload?.data;
        state.errorMessage = "";
      })
      .addMatcher(loginApi.endpoints.login.matchRejected, (state, action) => {
        state.userToken = "";
        let data = action.payload?.data as any;
        state.errorMessage = data?.message;
      });
  },
});

export const { logout, setToken, setUser } = userSlice.actions;
export default userSlice.reducer;
