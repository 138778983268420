import { Col, Row, Table, Pagination, FormInstance, Button, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { exportFileUsingPOST } from "../../../common/assets/CommonFunctions";
import { useExportDataMutation, useLazyGetReportQuery, useLazyGetReportTotalQuery } from "../redux/cacTHHTErrorReportApi";

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetReportQuery();
    const [triggerReportTotal, responseReportTotal] = useLazyGetReportTotalQuery();
    const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<any[]>();
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(searchParam);
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.content) {
            let newTableData: any[] = [];
            responseReport.data.content.forEach((item: any, index: any) => {
                newTableData.push({
                    key: index + 1,
                    ...item
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    const columns: ColumnsType<any> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Số",
            dataIndex: "",
            key: "",
        },
        {
            title: "Quyển số",
            dataIndex: "",
            key: "",
        },
        {
            title: "Trang số",
            dataIndex: "",
            key: "",
        },
        {
            title: "Quận/huyện",
            dataIndex: "district_name",
            key: "district_name",
        },
        {
            title: "Phường/xã",
            dataIndex: "commune_name",
            key: "commune_name",
        },
        {
            title: "Họ tên con nuôi",
            dataIndex: "",
            key: "",
        },
        {
            title: "Ngày sinh con nuôi",
            dataIndex: "",
            key: "",
        },
        {
            title: "Giới tính con nuôi",
            dataIndex: "",
            key: "",
        },
        {
            title: "Họ tên cha nuôi",
            dataIndex: "",
            key: "",
        },
        {
            title: "Họ tên mẹ nuôi",
            dataIndex: "",
            key: "",
        },
        {
            title: "Ngày đăng ký",
            dataIndex: "",
            key: "",
        },
        {
            title: "Nơi đăng ký",
            dataIndex: "",
            key: "",
        },
        {
            title: "Ngày nhập",
            dataIndex: "",
            key: "",
        },
        {
            title: "Người nhập",
            dataIndex: "",
            key: "",
        },
        {
            title: "Tên file pdf",
            dataIndex: "",
            key: "",
        },
        
    ];

    const getData = () => {
        // console.log(searchParam);
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportTotal({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                {/* <Col span={6}></Col> */}
                <div>Tổng số: <strong>{responseReportTotal?.data || 0}</strong> bản ghi</div>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                </div>
            </Row>
            <Table<any>
                loading={responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;
