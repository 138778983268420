import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign, Commune, District, Province, User } from './apiTypes';

export const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getProvince: builder.query<Province[], any>({
            query: () => '/common/province/all',
            transformResponse: (response: { data: Province[] }, meta, arg) => {
                return response.data;
            },
        }),
        getDistrictAll: builder.query<District[], any>({
            query: () => '/common/district/all',
            transformResponse: (response: { data: District[] }, meta, arg) => {
                return response.data;
            },
        }),
        getCommuneAll: builder.query<Commune[], any>({
            query: () => '/common/commune/all',
            transformResponse: (response: { data: Commune[] }, meta, arg) => {
                return response.data;
            },
        }),
        getDistrict: builder.query<District[], any>({
            query: (provinceId) => `/common/district-by-province/${provinceId}`,
            transformResponse: (response: { data: District[] }, meta, arg) => {
                return response.data;
            },
        }),
        getCommune: builder.query<Commune[], any>({
            query: (districtId) => `/common/commune-by-district/${districtId}`,
            transformResponse: (response: { data: Commune[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhân viên có quyền check dữ liệu
        getUserCheckData: builder.query<User[], any>({
            query: () => `/tool/data-info/get-user-check`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhân viên có quyền nhập và nhập đối chiếu
        getUserRecheckData: builder.query<User[], any>({
            query: () => `/tool/data-info/get-user-recheck`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhóm người dùng có quyền check dữ liệu
        getGroupUserCheckData: builder.query<User[], any>({
            query: () => `/tool/data-info/get-group-user-check`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Thêm mới chiến dịch
        createCampaign: builder.mutation<any, Campaign>({
            query: (body) => ({
                url: `/common/data-campaign/add`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            }
        }),
        // Lấy danh sách chiến dịch
        getAllCampaign: builder.query<Campaign[], any>({
            query: (body) => ({
                url: `/common/data-campaign/all`,
                method: 'GET',
            }),
            transformResponse: (response: { data: Campaign[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách chiến dịch theo trang
        getCampaignByPage: builder.query<{ content: Campaign[] }, any>({
            query: (body) => ({
                url: `/common/data-campaign/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: { content: Campaign[] } }, meta, arg) => {
                return response.data;
            },
        }),

        getAllRoles: builder.query<any[], any>({
            query: () => '/common/all-roles',
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getSyncReport: builder.query<any, any>({
            query: (rpName) => `/reports/report-sync?rpName=${rpName.rpName}`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
});

export const {
    useLazyGetAllCampaignQuery,
    useLazyGetCampaignByPageQuery,
    useLazyGetProvinceQuery,
    useLazyGetDistrictQuery,
    useLazyGetDistrictAllQuery,
    useLazyGetCommuneQuery,
    useLazyGetCommuneAllQuery,
    useLazyGetUserCheckDataQuery,
    useLazyGetUserRecheckDataQuery,
    useLazyGetGroupUserCheckDataQuery,
    useCreateCampaignMutation,
    useLazyGetAllRolesQuery,
    useLazyGetSyncReportQuery,
} = commonApi;
export default commonApi;
