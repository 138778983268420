import { Button, Col, Form, Input, Modal, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/logos/edit_icon.svg";
import { ReactComponent as UpdateIcon } from "../../../../assets/logos/update_icon.svg";
import { ReactComponent as AddIcon } from "../../../../assets/logos/add_icon.svg";
// import "../assets/scss/styles.scss";
// import { useAddCommuneMutation, useEditCommuneMutation } from "../redux/CommuneApi";
import { DataListTable } from "./CommuneList";
import { useAddCommuneMutation, useEditCommuneMutation } from "../redux/communeApi";
import { useLazyGetPageDistrictQuery } from "../../district/redux/districtApi";
import { useLazyListProvinceQuery } from "../../province/redux/provinceApi";
// import { useAddCommuneMutation, useEditCommuneMutation } from "../redux/CommuneApi";
// import { useAppSelector } from "../../../redux/Hooks";
// import { PERMISSION_KEYS } from '../../../modules/permissions/assets/ts/Constants'
// import { usePrompt } from "../../../prompts";

interface AddCommuneProps {
    visible: boolean;
    onCancel: () => void;
    currentCommune: DataListTable | undefined;
    onAddSuccessful: () => void;
    canEdit: boolean;
}

const AddCommuneModal = ({ visible, onCancel, currentCommune, onAddSuccessful, canEdit }: AddCommuneProps) => {
    const [form] = Form.useForm();
    const [editing, setEditing] = useState(false);
    const [provinceId, setProvinceId] = useState<any>('');
    const [addTrigger] = useAddCommuneMutation();
    const [editTrigger] = useEditCommuneMutation();

    //
    const [districtTrigger, districtResponse] = useLazyGetPageDistrictQuery();
    const [provinceTrigger, provinceResponse] = useLazyListProvinceQuery();

    // // khi reload
    // const [isFormChange, setFormChange] = useState<boolean>(false);
    // const [reloadModal, setReloadModal] = useState<boolean>(false);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    // usePrompt(isFormChange);

    useEffect(() => {
        provinceTrigger({});
    }, [provinceTrigger])

    const handleChangeDropdownProvince = (value: { value: string; label: React.ReactNode }) => {
        if (value) {
            setProvinceId(value);
        } else {
            setProvinceId('');
        }
    };

    useEffect(() => {
        districtTrigger({ provinceId: provinceId, size: 2000 });
    }, [districtTrigger, provinceId]);

    useEffect(() => {
        if (currentCommune) {
            form.setFieldsValue({
                communeCode: currentCommune.communeCode || "",
                communeName: currentCommune.communeName || "",
                district: currentCommune.districtId || "",
                province: currentCommune.provinceName || "",
                status: currentCommune.status !== undefined ? currentCommune.status : undefined,
            })
        }
        else {
            form.setFieldsValue({
                status: 0,
            })
        }
    }, [currentCommune, form]);

    const onFinish = (values: any) => {
        if (!loadingBtnSubmit) {
            setLoadingBtnSubmit(true);
            if (currentCommune) {
                editTrigger({
                    id: currentCommune.key,
                    communeCode: values.communeCode?.trim() || "",
                    communeName: values.communeName?.trim() || "",
                    districtId: values.district || "",
                    status: values.status,
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Cập nhật Phường/Xã thành công.'
                        });
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
            else {
                addTrigger({
                    communeCode: values.communeCode?.trim() || "",
                    communeName: values.communeName?.trim() || "",
                    districtId: values.district || "",
                    status: values.status,
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Thêm mới Phường/Xã thành công.'
                        })
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
        }
    };

    return (
        <>
            <Modal
                style={{ borderRadius: "8px", overflow: "hidden", minWidth: "360px" }}
                onCancel={onCancel}
                visible={visible}
                footer={false}
                title={currentCommune ? "Thông tin Tỉnh/Thành phố" : "Thêm mới Tỉnh/Thành phố"}
                width={"40%"}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    labelAlign="left"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={20}>
                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Mã Phường/Xã"
                                name="communeCode"
                                rules={[
                                    { required: true, message: "Vui lòng nhập Mã Phường/Xã." },
                                    {
                                        pattern: /^[^\sÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/,
                                        message: 'Mã Phường/Xã không chứa dấu cách và ký tự có dấu.',
                                    }
                                ]}
                            >
                                <Input
                                    className="propNameInput"
                                    placeholder="Nhập mã Phường/Xã "
                                    allowClear
                                    disabled={!editing && currentCommune ? true : false}
                                    maxLength={20}
                                    autoComplete="on"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Tên Phường/Xã"
                                name="communeName"
                                rules={[
                                    { required: true, message: "Vui lòng nhập Tên Phường/Xã." },
                                    { whitespace: true, message: "Vui lòng nhập Tên Phường/Xã." }
                                ]}
                            >
                                <Input
                                    className="propNameInput"
                                    placeholder="Nhập tên Phường/Xã"
                                    allowClear
                                    maxLength={50}
                                    disabled={!editing && currentCommune ? true : false}
                                    autoComplete="on"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} xl={24}>
                            <Form.Item
                                label="Tỉnh/Thành phố"
                                name="province"
                                rules={[
                                    { required: true, message: "Vui lòng chọn Tỉnh/Thành phố." },
                                ]}
                            >
                                <Select
                                    placeholder="Chọn Tỉnh/Thành phố"
                                    optionFilterProp="label"
                                    showSearch
                                    listHeight={130}
                                    allowClear
                                    disabled={(!editing && currentCommune) ? true : false}
                                    options={provinceResponse?.data?.filter((item) => item.isDelete === 0 && item.status === 0)?.map(item => ({
                                        label: item.provinceName,
                                        value: item.id,
                                    }))}
                                    filterSort={(optionA, optionB) =>
                                        optionA?.label?.toLowerCase().localeCompare(optionB?.label?.toLowerCase())
                                    }
                                    onChange={handleChangeDropdownProvince}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} xl={24}>
                            <Form.Item
                                label="Quận/Huyện"
                                name="district"
                                rules={[
                                    { required: true, message: "Vui lòng chọn Quận/Huyện." },
                                ]}
                            >
                                <Select
                                    placeholder="Chọn Quận/huyện"
                                    optionFilterProp="label"
                                    showSearch
                                    listHeight={130}
                                    allowClear
                                    disabled={!provinceId}
                                    options={districtResponse?.data?.content?.filter((item) => item.isDelete === 0 && item.status === 0)?.map(item => ({
                                        label: item.districtName,
                                        value: item.id,
                                    }))}
                                    filterSort={(optionA, optionB) =>
                                        optionA?.label?.toLowerCase().localeCompare(optionB?.label?.toLowerCase())
                                    }
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Trạng thái"
                                name="status"
                                rules={[
                                    { required: true, message: "Vui lòng chọn Trạng thái." }
                                ]}
                            >
                                <Select
                                    optionFilterProp="label"
                                    allowClear
                                    placeholder="--Lựa chọn--"
                                    disabled={!editing && currentCommune ? true : false}
                                >
                                    <Select.Option value={0}>Hoạt động</Select.Option>
                                    <Select.Option value={1}>Không hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>


                    {(!editing && currentCommune) && (
                        <div className="modal-action-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {canEdit && <Button
                                key="update"
                                style={{ height: "36px", width: "36px", padding: 0 }}
                                onClick={() => {
                                    setEditing(!editing);
                                }}
                            >
                                <EditIcon />
                            </Button>}
                            <Button key="close" style={{ height: "36px", width: "120px" }} onClick={onCancel}>
                                Đóng
                            </Button>
                        </div>
                    )}


                    {editing && (
                        <div className="modal-action-buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button key="edit" style={{ height: "36px", width: "120px", marginRight: 10 }} onClick={onCancel}>
                                Đóng
                            </Button>
                            <Button key="back" type="primary" style={{ height: "36px", width: "120p", display: "flex", alignItems: "center" }} htmlType="submit">
                                <UpdateIcon style={{ marginRight: 4 }} />
                                Cập nhật
                            </Button>
                        </div>
                    )}

                    {!currentCommune && (
                        <div className="modal-action-buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button key="update" style={{ height: "36px", width: "120px", marginRight: 10 }} onClick={onCancel}>
                                Đóng
                            </Button>
                            <Button type="primary" style={{ height: "36px", width: "120px", display: "flex", alignItems: "center" }} onClick={() => form.submit()}>
                                <AddIcon style={{ marginRight: 4 }} />
                                Thêm mới
                            </Button>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default AddCommuneModal;
