export enum ESearchDateType {
    MONTH = "month",
    DAY = "day",
}

export enum EStatisticalType {
    ALL = "all",
    PIE = "pie",
    BAR = "bar",
}

export enum EDataType {
    CHECKED = 1,
    ENTERED = 0,
}

export const COLOR_OF_THHT_TYPE: { [key: string]: string } = {
    KS: "#00BCD4",
    KT: "#0097A7",
    KH: "#3F51B5",
    HN: "#673AB7",
    CMC: "#607D8B",
    GH: "#9E9E9E",
    CN: "#BDBDBD",
    LH: "#F44336",
    CC: "#E91E63",
    CDGH: "#FF9800",
};

export const LIST_COLOR_DISPLAY_THHT: string[] = ["KS", "KT", "KH", "HN", "CMC", "GH", "CN", "LH", "CC", "CDGH"];

export const FORMAT_DATE_SEARCH = "dd/MM/yyyy";

export const MAX_SEARCH_DAY = 31;

export const CURRENT_YEAR = new Date().getFullYear();

export const CURRENT_MONTH = new Date().getMonth();

export const TODAY = new Date();
