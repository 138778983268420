import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { PDFPageProxy } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { useNavigate, useParams } from "react-router-dom";
import SplitPane from "react-split-pane";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { helpers } from "../../../assets/js/helper";
import { ReactComponent as Left } from "../../../assets/logos/layout-left-line.svg";
import { ReactComponent as Top } from "../../../assets/logos/layout-top-line.svg";
import { ReactComponent as Rotate } from "../../../assets/logos/rotate.svg";
import { ReactComponent as ZoomIn } from "../../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../../assets/logos/zoom-out-line.svg";
import { DEFAULT_FIELD_CONFIG } from "../../../assets/ts/constant";
import { Commune, District } from "../../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
} from "../../../redux/api/commonApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { FieldConfig } from "../../data_init/redux/apiTypes";
import { useLazyGetFilePdfByIdQuery } from "../../data_init/redux/dataInitApi";
import { useLazyGetInfoPdfFileQuery } from "../redux/dataInitHistoryApi";
import moment from "moment";

const DetailCheckData = () => {
  const [form] = Form.useForm();
  const param = useParams();
  const navigate = useNavigate();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [orderNumber, setOrderNumber] = useState(1);
  const [displayFlex, setDisplayFlex] = useState(true);
  const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
  const [keyMaps2, setKeyMaps2] = useState<FieldConfig[]>([]);

  const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();

  const [triggerFileId, dataFieldFile] = useLazyGetInfoPdfFileQuery();

  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();
  const [provinceObject, setProvinceObject] = useState();
  const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
  const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
  const [districtObject, setDistrictObject] = useState();
  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [communeObject, setCommuneObject] = useState();
  const [communeAllTrigger, responseCommuneAll] =
    useLazyGetAllCommuneNoAuthQuery();
  const [districtAllTrigger, responseDistrictAll] =
    useLazyGetAllDistrictNoAuthQuery();
  const [pdfFileName, setPdfFileName] = useState("");

  const wrapperDiv = useRef<HTMLInputElement>(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const canvas = useRef<any>();
  const canvas1 = useRef<any>();
  const [clientY, setClientY] = useState<number>(0);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const prevScale = useRef<number>(1);

  useEffect(() => {
    if (clientY === 0 || !canvas.current || !isRendered) {
      return;
    }
    const context = canvas.current.getContext("2d");
    const { width } = canvas.current;
    const rect = canvas.current.getBoundingClientRect();
    context.save();
    context.beginPath();
    context.moveTo(0, clientY);
    context.lineTo(width, clientY);
    context.strokeStyle = "red";
    context.stroke();
    context.restore();
    if (canvas1.current) {
      const context1 = canvas1.current.getContext("2d");
      const width1 = canvas1.current.width;
      const rect1 = canvas1.current.getBoundingClientRect();
      context1.save();
      context1.beginPath();
      context1.moveTo(0, clientY);
      context1.lineTo(width1, clientY);
      context1.strokeStyle = "red";
      context1.stroke();
      context1.restore();
    }
  }, [clientY, isRendered]);

  useEffect(() => {
    if (prevScale.current === scale) {
      return;
    } else {
      transformComponentRef.current.resetTransform(0);
      transformComponentRef.current.centerView(1, 0);
      setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
      setIsRendered(false);
    }
  }, [scale]);

  const onRenderSuccess = (page: PDFPageProxy) => {
    setIsRendered(true);
  };

  const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
    if (!isDrag) {
      const rect = canvas.current.getBoundingClientRect();
      const scaleY = canvas.current.height / rect.height;
      setClientY((event.clientY - rect.top) * scaleY);
    }
  };

  useEffect(() => {
    const slider = document.getElementById("block-view-pdf-image");
    let isDown = false;
    let startX: any;
    let startY: any;
    let scrollLeft: any;
    let scrollTop: any;
    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        startY = e.pageY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
        setIsDrag(false);
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        setIsDrag(true);
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageY - slider.offsetTop;
        const walk = (x - startY) * 3; //scroll-fast
        slider.scrollTop = scrollTop - walk;
        setIsDrag(true);
      });
    }
  }, []);

  useEffect(() => {
    if (displayFlex) {
      setWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
      setInitWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

  const changeLayout = (newDisplayFlex: boolean, order: number) => {
    setDisplayFlex(newDisplayFlex);
    setOrderNumber(order);
    if (!newDisplayFlex) {
      setWidth(initWidth * 2);
    }
  };

  const [height, setHeight] = useState<any>();
  const [width, setWidth] = useState<any>();
  const [initWidth, setInitWidth] = useState<any>();

  const changeSize = (size: number) => {
    if (displayFlex) {
      setWidth(size);
      setClientY(clientY * (size / width));
    } else {
      setHeight(size);
      setClientY(clientY * (size / height));
    }
    setIsRendered(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    triggerProvince({})
      .unwrap()
      .then((res) => {
        setProvinceObject(
          res?.reduce(
            (a: any, v: any) => ({
              ...a,
              [DEFAULT_FIELD_CONFIG.tinhThanh]: v.id,
            }),
            {}
          )
        );
      });
    communeAllTrigger({})
      .unwrap()
      .then((res) => {
        setCommuneObject(
          res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {})
        );
      });
    districtAllTrigger({})
      .unwrap()
      .then((res) => {
        setDistrictObject(
          res?.reduce(
            (a: any, v: any) => ({ ...a, [v.districtCode]: v.id }),
            {}
          )
        );
      });
  }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

  useEffect(() => {
    triggerFileId(param?.id);
  }, [param?.id, triggerFileId]);

  useEffect(() => {
    if (dataFieldFile?.data) {
      triggerFilePdfId(dataFieldFile?.data?.filePDFId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFieldFile, triggerFilePdfId]);

  useEffect(() => {
    if (
      dataFieldFile?.data?.dataKeyMap &&
      provinceObject &&
      districtObject &&
      responseCommuneAll?.data &&
      detailResponse?.data
    ) {
      setInitData(dataFieldFile?.data?.dataKeyMap);
    } else {
      setKeyMaps([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    provinceObject,
    districtObject,
    responseCommuneAll?.data,
    dataFieldFile?.data,
    detailResponse?.data,
  ]);

  const file = useMemo(() => {
    if (detailResponse?.data?.pathFile) {
      return {
        url:
          process.env.REACT_APP_API_FILE_URL +
          "/" +
          detailResponse?.data?.pathFile,
        httpHeaders: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
    }
  }, [detailResponse?.data?.pathFile]);

  const setInitData = (dataKeyMaps: any) => {
    if (dataKeyMaps?.length) {
      setKeyMaps(
        dataKeyMaps.map((item: any) => {
          let newItem = setDataItem(item);
          if (newItem) {
            return newItem;
          } else if (item.defaultValue || item.dataValue) {
            form.setFieldsValue({
              [item.dataKeyAscii]: item.defaultValue || item.dataValue,
            });
            return {
              ...item,
              dataValue: item.defaultValue || item.dataValue,
            };
          }
          return item;
        })
      );
    }
  };

  // Set dữ liệu ban đầu
  const setDataItem = (item: FieldConfig) => {
    const typeFile = dataFieldFile?.data?.typeId;
    if (item.dataKeyAscii) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
          districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh])
            .unwrap()
            .then((res) => setDistricts(res));

          form.setFieldsValue({
            [item.dataKeyAscii]:
              provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh],
          });
          return {
            ...item,
            dataValue: provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] || "",
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
          communeTrigger(item.dataValue)
            .unwrap()
            .then((res) => {
              setCommunes(res);
            });

          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue || "",
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa:
          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue || "",
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo:
          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue,
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf:
          let tenFilePdf = dataFieldFile?.data?.dataKeyMap?.filter(
            (elem: any) => elem.dataKeyAscii === "tenfilepdf"
          )[0]?.dataValue;
          form.setFieldsValue({
            [DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]: tenFilePdf,
          });
          setPdfFileName(tenFilePdf);
          return {
            ...item,
            dataValue: tenFilePdf,
          };

        case DEFAULT_FIELD_CONFIG.noiDangKy:
        case DEFAULT_FIELD_CONFIG.noiCap:
          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue,
          };
      }
    }
    return null;
  };

  // Chuyển trường dữ liệu thành item
  const convertTypeDataToElement = (item: FieldConfig, index: number) => {
    const typeFile = dataFieldFile?.data?.[0]?.typeId;
    if (item.data && item.data.length) {
      return (
        <Form.Item
          name={item.dataKeyAscii}
          style={{ marginBottom: 10 }}
          label={
            <div>
              <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                {index + 1}.{item.dataKey || ""}
              </div>
            </div>
          }
          rules={createRules(item)}
        >
          <Select
            disabled
            allowClear
            placeholder={item.placeholder || "--Lựa chọn--"}
            optionFilterProp="label"
            options={(item.data || []).map((x: any) => ({
              label: `${x.id}${x.name ? " - " + x.name : ""}`,
              value: x.id + "",
            }))}
            showSearch
            showArrow
          ></Select>
        </Form.Item>
      );
    }
    if (item.isDefault && typeFile && (!item.data || !item.data.length)) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              label={
                <div>
                  <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {index + 1}.{item.dataKey || ""}
                  </div>
                </div>
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                allowClear
                disabled
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(responseProvince?.data || []).map((item) => ({
                  label: item.provinceName,
                  value: item.id,
                }))}
                showSearch
                showArrow
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              label={
                <div>
                  <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {index + 1}.{item.dataKey || ""}
                  </div>
                </div>
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                allowClear
                disabled
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(districts || []).map((item) => ({
                  label: item.districtName,
                  value: item.id,
                }))}
                showSearch
                showArrow
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              label={
                <div>
                  <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {index + 1}.{item.dataKey || ""}
                  </div>
                </div>
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(communes || []).map((item) => ({
                  label: item.communeName,
                  value: item.id,
                }))}
                showSearch
                showArrow
              ></Select>
            </Form.Item>
          );
      }
    }
    return (
      <Form.Item
        name={item.dataKeyAscii}
        style={{ marginBottom: 10 }}
        label={
          <div>
            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
              {index + 1}.{item.dataKey || ""}
            </div>
          </div>
        }
        rules={createRules(item)}
      >
        <Input.TextArea
          placeholder={item.placeholder || ""}
          disabled
          onFocus={(e) => e.currentTarget.select()}
          onBlur={(e) => updateData(e.target.value, index, item)}
          autoComplete="on"
        />
      </Form.Item>
    );
  };

  // Format lại dữ liệu sau khi nhập liệu
  const updateData = (value: any, index: number, item: FieldConfig) => {
    if (item.type === "date") {
      value = helpers.customDate(value);
    } else if (item.type === "time") {
      value = helpers.customTime(value, item.format);
    } else if (item.format?.toLowerCase().includes("capitalcase")) {
      value = helpers.toTitleCase(value);
    }
    form.validateFields([item.dataKeyAscii]);
  };

  // Tạo rules validate cho trường dữ liệu
  const createRules = (item: any) => {
    let rules: Object[] = [];
    if (item.isDefault) {
      rules = [
        ...rules,
        { required: true, message: "Trường dữ liệu bắt buộc" },
      ];
    }
    if (item.format && item.format !== "capitalcase") {
      rules = [
        ...rules,
        {
          pattern: new RegExp(item.format),
          message: "Dữ liệu không đúng định dạng",
        },
      ];
    }
    // Check ngày không hợp lệ
    if (item.type === "date") {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            let pattern = new RegExp(item.format);
            if (
              item.type === "date" &&
              value &&
              !moment(value, "DD.MM.YYYY").isValid() &&
              pattern.test(value)
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    return rules;
  };

  return (
    <Spin tip="Đang tải..." spinning={dataFieldFile.isFetching}>
      <div className="content-box">
        <div className="body-block-create-info-init" ref={wrapperDiv}>
          <SplitPane
            split={displayFlex ? "vertical" : "horizontal"}
            size={displayFlex ? width : height}
            minSize={"50%"}
            onChange={(size) => changeSize(size)}
            maxSize={"100%"}
          >
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
                  : {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
              }
            >
              <div className="header-block">
                <label
                  style={{
                    backgroundColor: "#fefbd8",
                    fontWeight: "bold",
                    fontSize: 10,
                  }}
                  className="search-title"
                >
                  {detailResponse?.data?.pathFile || ""}
                </label>
              </div>
              <div>
                <ZoomOut
                  onClick={() =>
                    setScale((prev: number) => {
                      prevScale.current = prev;
                      return scale === 1 ? 1 : scale - 1;
                    })
                  }
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <ZoomIn
                  onClick={() =>
                    setScale((prev: number) => {
                      prevScale.current = prev;
                      return scale + 1;
                    })
                  }
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Rotate
                  onClick={() => setRotate(rotate - 90)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Top
                  onClick={() => changeLayout(false, 1)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Left
                  onClick={() => changeLayout(true, 1)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
              </div>
              <div
                style={{
                  overflowY: "auto",
                  height: displayFlex
                    ? "calc(100vh - 160px)"
                    : "calc(100% - 55px)",
                }}
                id="block-view-pdf-image"
              >
                <TransformWrapper
                  maxScale={3}
                  initialScale={1}
                  disablePadding={true}
                  ref={transformComponentRef}
                  onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                    if (ref.state.scale >= 3) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev + 1;
                      });
                    } else if (ref.state.scale <= 1) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev - 1 || 1;
                      });
                    }
                  }}
                >
                  <TransformComponent>
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(
                        new Array(Math.round(numPages / 2)),
                        (el, index) => (
                          <div
                            style={{ display: "flex" }}
                            key={`grouppage${index + 1}`}
                          >
                            <Page
                              width={width}
                              rotate={rotate}
                              scale={scale}
                              canvasRef={canvas}
                              key={`page_${index * 2 + 1}`}
                              pageNumber={index * 2 + 1}
                              onRenderSuccess={onRenderSuccess}
                              onClick={onClickPage}
                            />
                            {index * 2 + 2 <= numPages ? (
                              <Page
                                width={width}
                                rotate={rotate}
                                scale={scale}
                                key={`page_${index * 2 + 2}`}
                                pageNumber={index * 2 + 2}
                                canvasRef={canvas1}
                                onRenderSuccess={onRenderSuccess}
                                onClick={onClickPage}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      )}
                    </Document>
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            {/* enter field value */}
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      boxSizing: "border-box",
                      position: "absolute",
                    }
                  : {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      boxSizing: "border-box",
                    }
              }
            >
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "calc(100vh - 180px)",
                  paddingRight: 10,
                }}
              >
                <Form
                  name="searchBox"
                  autoComplete="off"
                  form={form}
                  colon={false}
                  style={{ marginTop: 0 }}
                  layout="vertical"
                  labelWrap
                  labelAlign="left"
                >
                  <label
                    style={{ backgroundColor: "#fefbd8" }}
                    className="search-title"
                  >
                    <p style={{ textAlign: "center" }}>
                      {dataFieldFile?.data?.[0]?.createBy}
                    </p>
                    Dữ liệu THHT: {pdfFileName}
                  </label>
                  <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                    {keyMaps.length > 0 &&
                      keyMaps
                        .filter((x) => x.isDisplay)
                        .map((item: any, index) => {
                          return (
                            <Col span={8} key={item.dataKeyAscii}>
                              {convertTypeDataToElement(item, index)}
                            </Col>
                          );
                        })}
                  </Row>
                </Form>
              </div>
              <div className="search-box-button">
                <Button
                  onClick={() => navigate("/data/info_init_history")}
                  htmlType="button"
                >
                  Quay lại
                </Button>
              </div>
            </div>
          </SplitPane>
        </div>
      </div>
    </Spin>
  );
};

export default DetailCheckData;
