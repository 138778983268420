import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { ESearchDateType } from "../../../utils/constants";
import { CustomToolTipUserBarChart } from "../../customs/CustomToolTipUserBarChart";

export interface IBarchartData {
    name: string;
    [key: string]: number | string;
}

interface IColorShow {
    color: string;
    name: string;
}

interface Props {
    data: IBarchartData[];
    listColor: IColorShow[];
    listShow: string[];
    maxBarSize: number;
    searchType: ESearchDateType;
    isGetAllData: boolean;
}

export default function BarChartUserComponent({
    data,
    listColor,
    listShow,
    maxBarSize,
    searchType,
    isGetAllData,
}: Props) {
    const generateData = () => {
        if (!data) return [];
        if (searchType === ESearchDateType.MONTH && !isGetAllData) {
            const result: IBarchartData[] = [];
            const listMonthExist = data.map(
                (item: IBarchartData, idx: number) => {
                    const monthToNumber = item.name
                        ? Number(item.name.split("-")[0].replace("T", ""))
                        : 0;
                    return monthToNumber;
                }
            );

            for (let i = 1; i <= 12; i++) {
                if (listMonthExist.includes(i)) {
                    const hasData = data.find((item: IBarchartData) => {
                        const monthToNumber = item.name
                            ? Number(item.name.split("-")[0].replace("T", ""))
                            : 0;
                        return monthToNumber === i;
                    });
                    if (hasData) result.push({ ...hasData, name: `T${i}` });
                } else
                    result.push({
                        name: `T${i}`,
                    });
            }

            return result;
        } else {
            return data;
        }
    };

    const yaxisFommater = (value: string) => {
        return new Intl.NumberFormat().format(Number(value));
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={700}
                height={300}
                data={generateData()}
                style={{
                    margin: "auto",
                }}
                className="bar-chart"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{ fill: "#253645" }}/>
                <YAxis
                    tick={{ fill: "#253645" }}
                    padding={{ top: 40 }}
                    tickFormatter={yaxisFommater}
                    label={{
                        value: "THHT",
                        position: { x: 54, y: 7 },
                        className: "chart__label",
                    }}
                />
                <Tooltip content={<CustomToolTipUserBarChart />} />
                {listColor
                    ?.filter((item: { color: string; name: string }) => {
                        if (
                            listShow.length === 0 ||
                            listShow.includes(item.name)
                        )
                            return true;
                        return false;
                    })
                    .map((item: { color: string; name: string }, i: number) => {
                        return (
                            <Bar
                                key={i}
                                dataKey={item.name}
                                stackId="a"
                                fill={item.color}
                                maxBarSize={maxBarSize}
                            />
                        );
                    })}
            </BarChart>
        </ResponsiveContainer>
    );
}
