import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { PDFPageProxy } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { useNavigate, useParams } from "react-router-dom";
import SplitPane from "react-split-pane";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { helpers } from "../../../assets/js/helper";
import { ReactComponent as Left } from "../../../assets/logos/layout-left-line.svg";
import { ReactComponent as Top } from "../../../assets/logos/layout-top-line.svg";
import { ReactComponent as Rotate } from "../../../assets/logos/rotate.svg";
import { ReactComponent as ZoomIn } from "../../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../../assets/logos/zoom-out-line.svg";
import { DEFAULT_FIELD_CONFIG } from "../../../assets/ts/constant";
import { Commune, District } from "../../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
} from "../../../redux/api/commonApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { FieldConfig } from "../../data_init/redux/apiTypes";
import { useLazyGetFilePdfByIdQuery } from "../../data_init/redux/dataInitApi";
import "../assets/css/index.css";
import { useLazyGetInfoPdfFileQuery } from "../redux/compareApi";

const DetailDataCompare = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const param = useParams();
  const navigate = useNavigate();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [orderNumber, setOrderNumber] = useState(1);
  const [displayFlex, setDisplayFlex] = useState(true);
  const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
  const [keyMaps2, setKeyMaps2] = useState<FieldConfig[]>([]);

  const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();

  const [triggerFileId, dataFieldFile] = useLazyGetInfoPdfFileQuery();

  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();
  const [provinceObject, setProvinceObject] = useState();
  const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
  const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
  const [districtObject, setDistrictObject] = useState();
  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [communeObject, setCommuneObject] = useState();
  const [communeAllTrigger, responseCommuneAll] =
    useLazyGetAllCommuneNoAuthQuery();
  const [districtAllTrigger, responseDistrictAll] =
    useLazyGetAllDistrictNoAuthQuery();
  const [pdfFileName, setPdfFileName] = useState("");

  const wrapperDiv = useRef<HTMLInputElement>(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const canvas = useRef<any>();
  const canvas1 = useRef<any>();
  const [clientY, setClientY] = useState<number>(0);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const prevScale = useRef<number>(1);

  useEffect(() => {
    if (clientY === 0 || !canvas.current || !isRendered) {
      return;
    }
    const context = canvas.current.getContext("2d");
    const { width } = canvas.current;
    const rect = canvas.current.getBoundingClientRect();
    context.save();
    context.beginPath();
    context.moveTo(0, clientY);
    context.lineTo(width, clientY);
    context.strokeStyle = "red";
    context.stroke();
    context.restore();
    if (canvas1.current) {
      const context1 = canvas1.current.getContext("2d");
      const width1 = canvas1.current.width;
      const rect1 = canvas1.current.getBoundingClientRect();
      context1.save();
      context1.beginPath();
      context1.moveTo(0, clientY);
      context1.lineTo(width1, clientY);
      context1.strokeStyle = "red";
      context1.stroke();
      context1.restore();
    }
  }, [clientY, isRendered]);

  useEffect(() => {
    if (prevScale.current === scale) {
      return;
    } else {
      transformComponentRef.current.resetTransform(0);
      transformComponentRef.current.centerView(1, 0);
      setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
      setIsRendered(false);
    }
  }, [scale]);

  const onRenderSuccess = (page: PDFPageProxy) => {
    setIsRendered(true);
  };

  const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
    if (!isDrag) {
      const rect = canvas.current.getBoundingClientRect();
      const scaleY = canvas.current.height / rect.height;
      setClientY((event.clientY - rect.top) * scaleY);
    }
  };

  useEffect(() => {
    const slider = document.getElementById("block-view-pdf-image");
    let isDown = false;
    let startX: any;
    let startY: any;
    let scrollLeft: any;
    let scrollTop: any;
    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        startY = e.pageY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
        setIsDrag(false);
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        setIsDrag(true);
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageY - slider.offsetTop;
        const walk = (x - startY) * 3; //scroll-fast
        slider.scrollTop = scrollTop - walk;
        setIsDrag(true);
      });
    }
  }, []);

  useEffect(() => {
    if (displayFlex) {
      setWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
      setInitWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

  const changeLayout = (newDisplayFlex: boolean, order: number) => {
    setDisplayFlex(newDisplayFlex);
    setOrderNumber(order);
    if (!newDisplayFlex) {
      setWidth(initWidth * 2);
    }
  };

  const [height, setHeight] = useState<any>();
  const [width, setWidth] = useState<any>();
  const [initWidth, setInitWidth] = useState<any>();

  const changeSize = (size: number) => {
    if (displayFlex) {
      setWidth(size);
      setClientY(clientY * (size / width));
    } else {
      setHeight(size);
      setClientY(clientY * (size / height));
    }
    setIsRendered(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    triggerProvince({})
      .unwrap()
      .then((res) => {
        setProvinceObject(
          res?.reduce(
            (a: any, v: any) => ({
              ...a,
              [DEFAULT_FIELD_CONFIG.tinhThanh]: v.id,
            }),
            {}
          )
        );
      });
    communeAllTrigger({})
      .unwrap()
      .then((res) => {
        setCommuneObject(
          res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {})
        );
      });
    districtAllTrigger({})
      .unwrap()
      .then((res) => {
        setDistrictObject(
          res?.reduce(
            (a: any, v: any) => ({ ...a, [v.districtCode]: v.id }),
            {}
          )
        );
      });
  }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

  useEffect(() => {
    triggerFileId(param?.id);
    // triggerFilePdfId(param?.id)
  }, [param?.id, triggerFileId]);

  useEffect(() => {
    if (dataFieldFile?.data) {
      triggerFilePdfId(dataFieldFile?.data[0]?.filePDFId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFieldFile, triggerFilePdfId]);

  useEffect(() => {
    if (
      dataFieldFile?.data?.[0]?.dataKeyMap &&
      provinceObject &&
      districtObject &&
      responseCommuneAll?.data &&
      detailResponse?.data
    ) {
      setInitData(
        dataFieldFile?.data[0]?.dataKeyMap,
        dataFieldFile?.data[1]?.dataKeyMap
      );
    } else {
      setKeyMaps([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    provinceObject,
    districtObject,
    responseCommuneAll?.data,
    dataFieldFile?.data,
    detailResponse?.data,
  ]);

  // const file = useMemo(() => {
  //   let uri = "";
  //   if (detailResponse?.data?.id) {
  //     uri = `/api/v1/file/download-pdf/${detailResponse?.data?.id}`;
  //   } else if (detailResponse?.data?.excelName && pdfFileName) {
  //     let excelNames = detailResponse?.data?.excelName?.split("_");
  //     let excelNames1 = detailResponse?.data?.excelName?.split(".");
  //     let pathName = `${excelNames?.[1]}/${excelNames?.[0]}/${detailResponse?.data?.typeId}/${excelNames1?.[2]}/${excelNames1?.[3]}/${pdfFileName}`;
  //     uri = `/api/v1/file/download-pdf?dataInfoId=${detailResponse?.data?.id}&pathFile=${pathName}`;
  //   }
  //   if (uri) {
  //     return {
  //       url: process.env.REACT_APP_API_URL + uri,
  //       httpHeaders: {
  //         Authorization: "Bearer " + localStorage.getItem("accessToken"),
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   detailResponse?.data?.excelName,
  //   detailResponse?.data?.id,
  //   detailResponse?.data?.typeId,
  // ]);

  const file = useMemo(() => {
    if (detailResponse?.data?.pathFile) {
      return {
        url:
          process.env.REACT_APP_API_FILE_URL +
          "/" +
          detailResponse?.data?.pathFile,
        httpHeaders: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
    }
  }, [detailResponse?.data?.pathFile]);

  // const onFinish = (values: any) => {
  //   console.log("values", values);
  // };

  const setInitData = (dataKeyMaps: any, dataKeyMaps2: any) => {
    if (dataKeyMaps?.length) {
      setKeyMaps(
        dataKeyMaps.map((item: any) => {
          let newItem = setDataItem(item, form);
          if (newItem) {
            return newItem;
          } else if (item.defaultValue || item.dataValue) {
            form.setFieldsValue({
              [item.dataKeyAscii]: item.defaultValue || item.dataValue,
            });
            return {
              ...item,
              dataValue: item.defaultValue || item.dataValue,
            };
          }
          return item;
        })
      );
    }
    if (dataKeyMaps2?.length) {
      setKeyMaps2(
        dataKeyMaps2.map((item: any) => {
          let newItem = setDataItem(item, form2);
          if (newItem) {
            return newItem;
          } else if (item.defaultValue || item.dataValue) {
            form2.setFieldsValue({
              [item.dataKeyAscii]: item.defaultValue || item.dataValue,
            });
            return {
              ...item,
              dataValue: item.defaultValue || item.dataValue,
            };
          }
          return item;
        })
      );
    }
  };

  // Set dữ liệu ban đầu
  const setDataItem = (item: FieldConfig, form: any) => {
    const typeFile = dataFieldFile?.data[0]?.typeId;
    if (item.dataKeyAscii) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
          districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh])
            .unwrap()
            .then((res) => setDistricts(res));

          form.setFieldsValue({
            [item.dataKeyAscii]:
              provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh],
          });
          return {
            ...item,
            dataValue: provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] || "",
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
          communeTrigger(item.dataValue)
            .unwrap()
            .then((res) => {
              setCommunes(res);
            });

          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue || "",
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa:
          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue || "",
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo:
          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue,
          };

        case DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf:
          let pdfFileNames1 = detailResponse?.data?.fileName?.split(".");
          let type = detailResponse?.data?.fileName?.split(".")?.[0];
          let tenFilePdf = `${type}.${pdfFileNames1?.[1] || "--"}.${
            pdfFileNames1?.[2] || "--"
          }.--..pdf`;
          form.setFieldsValue({
            [DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]: tenFilePdf,
          });
          setPdfFileName(tenFilePdf);
          return {
            ...item,
            dataValue: tenFilePdf,
          };

        case DEFAULT_FIELD_CONFIG.noiDangKy:
        case DEFAULT_FIELD_CONFIG.noiCap:
          form.setFieldsValue({
            [item.dataKeyAscii]: item.dataValue,
          });
          return {
            ...item,
            dataValue: item.dataValue,
          };
      }
    }
    return null;
  };

  // const changeData = async (value: any, index: number, item: FieldConfig) => {
  //   // Nếu không cho sửa thì không được sửa
  //   if (!item.isEdit) return;
  //   if (item?.dataKeyAscii) {
  //     form.setFieldsValue({ [item.dataKeyAscii]: value });
  //   }
  //   switch (item.dataKeyAscii) {
  //     case DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.tinhThanh:
  //       form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined });
  //       setDistricts([]);
  //       setCommunes([]);
  //       districtTrigger(value)
  //         .unwrap()
  //         .then((res: any) => setDistricts(res));
  //       break;
  //     case DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.quanHuyen:
  //       form.setFieldsValue({ phuongXa: undefined });
  //       setCommunes([]);
  //       communeTrigger(value)
  //         .unwrap()
  //         .then((res) => setCommunes(res));
  //       break;
  //   }
  //   // KS.2007.01.2007-07-11.082.pdf
  //   let changeFilePdf =
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.so ||
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.quyenSo ||
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.ngayDangKy;
  //   let tenFilePdf = "";
  //   if (changeFilePdf) {
  //     let type = detailResponse?.data?.fileName?.split(".")?.[0];
  //     let so =
  //       item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.so
  //         ? value
  //         : form?.getFieldsValue()?.so;
  //     let quyenSo = (
  //       (item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.quyenSo
  //         ? value
  //         : form?.getFieldsValue()?.quyenSo) || ""
  //     ).split("/");
  //     let ngayDangKy = (
  //       (item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.ngayDangKy
  //         ? value
  //         : form?.getFieldsValue()?.ngayDangKy) || ""
  //     ).split(".");
  //     // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
  //     let temp = "";
  //     if (ngayDangKy?.length === 3) {
  //       temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${
  //         ngayDangKy?.[0] || ""
  //       }`;
  //     } else if (ngayDangKy?.length === 2) {
  //       temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
  //     } else {
  //       temp = `${ngayDangKy?.[0] || ""}--`;
  //     }
  //     tenFilePdf = `${type}.${quyenSo?.[1] || "--"}.${
  //       quyenSo?.[0] || "--"
  //     }.${temp}.${so || ""}.pdf`;
  //     form.setFieldsValue({
  //       [DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.tenfilepdf]:
  //         tenFilePdf,
  //     });
  //     setPdfFileName(tenFilePdf);
  //   }
  //   let loaiDangKy: any;
  //   let ngayDangKy1 =
  //     (item.dataKeyAscii ===
  //     DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.ngayDangKy
  //       ? value
  //       : form?.getFieldsValue()?.ngayDangKy) || "";

  //   // Loại đăng ký KS
  //   let changeLoaiDangKy =
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.ngayDangKy ||
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.nksNgaySinh;
  //   let nksNgaySinh =
  //     (item.dataKeyAscii ===
  //     DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.nksNgaySinh
  //       ? value
  //       : form?.getFieldsValue()?.nksNgaySinh) || "";

  //   // Loại đăng ký KT
  //   let changeLoaiDangKyKT =
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.ngayDangKy ||
  //     item.dataKeyAscii ===
  //       DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.nktNgayChet;
  //   let nktNgayChet =
  //     (item.dataKeyAscii ===
  //     DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.nktNgayChet
  //       ? value
  //       : form?.getFieldsValue()?.nktNgayChet) || "";

  //   if (
  //     changeLoaiDangKy &&
  //     dataFieldFile?.data[0]?.typeId === "KS" &&
  //     ngayDangKy1 &&
  //     nksNgaySinh
  //   ) {
  //     loaiDangKy = autoFillLoaiDangKy(ngayDangKy1, nksNgaySinh);
  //   }

  //   if (
  //     changeLoaiDangKyKT &&
  //     dataFieldFile?.data[0]?.typeId === "KT" &&
  //     ngayDangKy1 &&
  //     nktNgayChet
  //   ) {
  //     loaiDangKy = autoFillLoaiDangKyKT(ngayDangKy1, nktNgayChet);
  //   }

  //   setKeyMaps(
  //     (keyMaps || []).map((x: any, i: number) => {
  //       if (x.dataKeyAscii === item.dataKeyAscii)
  //         return {
  //           ...x,
  //           dataValue: value || "",
  //         };
  //       if (
  //         x.dataKeyAscii ===
  //           DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.tenfilepdf &&
  //         changeFilePdf
  //       )
  //         return {
  //           ...x,
  //           dataValue: tenFilePdf,
  //         };
  //       if (
  //         (x.dataKeyAscii || "")
  //           .toLowerCase()
  //           .includes((item.dataKeyAscii || "").toLowerCase()) &&
  //         (x.dataKeyAscii || "").toLowerCase().includes("bangchu")
  //       ) {
  //         let txt = helpers.dateToWord(value || "");
  //         form.setFieldsValue({ [x.dataKeyAscii]: txt });
  //         return {
  //           ...x,
  //           dataValue: txt,
  //         };
  //       }
  //       // Tự động điền trường loại đăng ký cho KS
  //       if (
  //         x.dataKeyAscii ===
  //           DEFAULT_FIELD_CONFIG[dataFieldFile?.data[0]?.typeId]?.loaiDangKy &&
  //         (changeLoaiDangKy || changeLoaiDangKyKT)
  //       ) {
  //         form.setFieldsValue({
  //           [x.dataKeyAscii]: loaiDangKy ? loaiDangKy : undefined,
  //         });
  //         return {
  //           ...x,
  //           dataValue: loaiDangKy ? loaiDangKy : undefined,
  //         };
  //       }
  //       return x;
  //     })
  //   );
  // };

  // const autoFillLoaiDangKy = (ngayDangKy: string, ngaySinh: string) => {
  //   let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
  //   let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
  //   let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
  //   if (timeCompare < 0) return undefined;
  //   if (timeCompare <= 60) return "1";
  //   if (timeCompare / 365 > 10) return "2";
  //   if (timeCompare > 60) return "4";
  // };

  // const autoFillLoaiDangKyKT = (ngayDangKy: string, ngaySinh: string) => {
  //   let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
  //   let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
  //   let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
  //   if (timeCompare < 0) return undefined;
  //   if (timeCompare <= 15) return "1";
  //   if (timeCompare / 365 > 10) return "2";
  //   if (timeCompare > 15) return "4";
  // };

  // Chuyển trường dữ liệu thành item
  const convertTypeDataToElement = (
    item: FieldConfig,
    index: number,
    showLabel: boolean
  ) => {
    const typeFile = dataFieldFile?.data?.[0]?.typeId;
    if (item.data && item.data.length) {
      return (
        <Form.Item
          name={item.dataKeyAscii}
          style={{ marginBottom: 10 }}
          className={!showLabel ? "no-label" : ""}
          labelCol={{ span: showLabel ? 12 : 0 }}
          label={
            showLabel ? (
              <div>
                <div
                  style={{
                    fontWeight: 700,
                    wordBreak: "break-word",
                    color: item?.isRecheckEdited ? "red" : "#000000D9",
                  }}
                >
                  {index + 1}.{item.dataKey || ""}
                </div>
              </div>
            ) : (
              <></>
            )
          }
          rules={createRules(item)}
        >
          <Select
            disabled
            allowClear
            placeholder={item.placeholder || "--Lựa chọn--"}
            optionFilterProp="label"
            options={(item.data || []).map((x: any) => ({
              label: `${x.id}${x.name ? " - " + x.name : ""}`,
              value: x.id + "",
            }))}
            showSearch
            showArrow
            // onChange={(value) => changeData(value, index, item)}
            // value={item.dataValue}
          ></Select>
        </Form.Item>
      );
    }
    if (item.isDefault && typeFile && (!item.data || !item.data.length)) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              className={!showLabel ? "no-label" : ""}
              labelCol={{ span: showLabel ? 12 : 0 }}
              label={
                showLabel ? (
                  <div>
                    <div
                      style={{
                        fontWeight: 700,
                        wordBreak: "break-word",
                        color: item?.isRecheckEdited ? "red" : "#000000D9",
                      }}
                    >
                      {index + 1}.{item.dataKey || ""}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                allowClear
                disabled
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(responseProvince?.data || []).map((item) => ({
                  label: item.provinceName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                // onChange={(value) => changeData(value, index, item)}
                // value={item.dataValue}
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              className={!showLabel ? "no-label" : ""}
              labelCol={{ span: showLabel ? 12 : 0 }}
              label={
                showLabel ? (
                  <div>
                    <div
                      style={{
                        fontWeight: 700,
                        wordBreak: "break-word",
                        color: item?.isRecheckEdited ? "red" : "#000000D9",
                      }}
                    >
                      {index + 1}.{item.dataKey || ""}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                allowClear
                disabled
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(districts || []).map((item) => ({
                  label: item.districtName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                // onChange={(value) => changeData(value, index, item)}
                // value={item.dataValue}
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              className={!showLabel ? "no-label" : ""}
              labelCol={{ span: showLabel ? 12 : 0 }}
              label={
                showLabel ? (
                  <div>
                    <div
                      style={{
                        fontWeight: 700,
                        wordBreak: "break-word",
                        color: item?.isRecheckEdited ? "red" : "#000000D9",
                      }}
                    >
                      {index + 1}.{item.dataKey || ""}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(communes || []).map((item) => ({
                  label: item.communeName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                // onChange={(value) => changeData(value, index, item)}
                // value={item.dataValue}
              ></Select>
            </Form.Item>
          );
      }
    }
    return (
      <Form.Item
        name={item.dataKeyAscii}
        style={{ marginBottom: 10 }}
        className={!showLabel ? "no-label" : ""}
        labelCol={{ span: showLabel ? 12 : 0 }}
        label={
          showLabel ? (
            <div>
              <div
                style={{
                  fontWeight: 700,
                  wordBreak: "break-word",
                  color: item?.isRecheckEdited ? "red" : "#000000D9",
                }}
              >
                {index + 1}.{item.dataKey || ""}
              </div>
            </div>
          ) : (
            <></>
          )
        }
        rules={createRules(item)}
      >
        <Input.TextArea
          placeholder={item.placeholder || ""}
          disabled
          onFocus={(e) => e.currentTarget.select()}
          // onChange={(e) => changeData(e.target.value, index, item)}
          // value={item.dataValue}
          onBlur={(e) => updateData(e.target.value, index, item)}
          autoComplete="on"
        />
      </Form.Item>
    );
  };

  // Format lại dữ liệu sau khi nhập liệu
  const updateData = (value: any, index: number, item: FieldConfig) => {
    if (item.type === "date") {
      value = helpers.customDate(value);
      // changeData(value, index, item);
    } else if (item.type === "time") {
      value = helpers.customTime(value, item.format);
      // changeData(value, index, item);
    } else if (item.format?.toLowerCase().includes("capitalcase")) {
      value = helpers.toTitleCase(value);
      // changeData(value, index, item);
    }
    // changeData((value || "").trim(), index, item);
    form.validateFields([item.dataKeyAscii]);
  };

  // Tạo rules validate cho trường dữ liệu
  const createRules = (item: any) => {
    let rules: Object[] = [];
    if (item.isDefault) {
      rules = [
        ...rules,
        { required: true, message: "Trường dữ liệu bắt buộc" },
      ];
    }
    if (item.format && item.format !== "capitalcase") {
      rules = [
        ...rules,
        {
          pattern: new RegExp(item.format),
          message: "Dữ liệu không đúng định dạng",
        },
      ];
    }

    // Check ngày không hợp lệ
    if (item.type === "date") {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            let pattern = new RegExp(item.format);
            if (
              item.type === "date" &&
              value &&
              !moment(value, "DD.MM.YYYY").isValid() &&
              pattern.test(value)
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    return rules;
  };

  // const resetData = () => {
  //   if (dataFieldFile?.data[0]?.dataKeyMap?.length) {
  //     setKeyMaps(dataFieldFile?.data[0]?.dataKeyMap);
  //   } else {
  //     setKeyMaps([]);
  //   }
  //   form.resetFields();
  // };

  return (
    <Spin tip="Đang tải..." spinning={dataFieldFile.isFetching}>
      <div className="content-box">
        <div className="body-block-create-info-init" ref={wrapperDiv}>
          <SplitPane
            split={displayFlex ? "vertical" : "horizontal"}
            size={displayFlex ? width : height}
            minSize={"50%"}
            onChange={(size) => changeSize(size)}
            maxSize={"100%"}
          >
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
                  : {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
              }
            >
              <div className="header-block">
                <label
                  style={{
                    backgroundColor: "#fefbd8",
                    fontWeight: "bold",
                    fontSize: 10,
                  }}
                  className="search-title"
                >
                  {detailResponse?.data?.pathFile || ""}
                </label>
              </div>
              <div>
                <ZoomOut
                  onClick={() =>
                    setScale((prev: number) => {
                      prevScale.current = prev;
                      return scale === 1 ? 1 : scale - 1;
                    })
                  }
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <ZoomIn
                  onClick={() =>
                    setScale((prev: number) => {
                      prevScale.current = prev;
                      return scale + 1;
                    })
                  }
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Rotate
                  onClick={() => setRotate(rotate - 90)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Top
                  onClick={() => changeLayout(false, 1)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Left
                  onClick={() => changeLayout(true, 1)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
              </div>
              <div
                style={{
                  overflowY: "auto",
                  height: displayFlex
                    ? "calc(100vh - 160px)"
                    : "calc(100% - 55px)",
                }}
                id="block-view-pdf-image"
              >
                <TransformWrapper
                  maxScale={3}
                  initialScale={1}
                  disablePadding={true}
                  ref={transformComponentRef}
                  onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                    if (ref.state.scale >= 3) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev + 1;
                      });
                    } else if (ref.state.scale <= 1) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev - 1 || 1;
                      });
                    }
                  }}
                >
                  <TransformComponent>
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(
                        new Array(Math.round(numPages / 2)),
                        (el, index) => (
                          <div
                            style={{ display: "flex" }}
                            key={`grouppage${index + 1}`}
                          >
                            <Page
                              width={width}
                              rotate={rotate}
                              scale={scale}
                              canvasRef={canvas}
                              key={`page_${index * 2 + 1}`}
                              pageNumber={index * 2 + 1}
                              onRenderSuccess={onRenderSuccess}
                              onClick={onClickPage}
                            />
                            {index * 2 + 2 <= numPages ? (
                              <Page
                                width={width}
                                rotate={rotate}
                                scale={scale}
                                key={`page_${index * 2 + 2}`}
                                pageNumber={index * 2 + 2}
                                canvasRef={canvas1}
                                onRenderSuccess={onRenderSuccess}
                                onClick={onClickPage}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      )}
                    </Document>
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            {/* enter field value */}
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      boxSizing: "border-box",
                      position: "absolute",
                    }
                  : {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      boxSizing: "border-box",
                    }
              }
            >
              <Row gutter={16}>
                <Col span={8} offset={8}>
                  <label
                    style={{ backgroundColor: "#fefbd8" }}
                    className="search-title"
                  >
                    <p style={{ textAlign: "center" }}>
                      {dataFieldFile?.data?.[0].createBy}
                    </p>
                  </label>
                </Col>

                <Col span={8}>
                  <label
                    style={{ backgroundColor: "#fefbd8" }}
                    className="search-title"
                  >
                    <p style={{ textAlign: "center" }}>
                      {dataFieldFile?.data?.[1].createBy}
                    </p>
                  </label>
                </Col>

                <Col span={24}>
                  <div
                    style={{
                      overflowY: "auto",
                      overflowX: "hidden",
                      height: "calc(100vh - 180px)",
                      paddingRight: 10,
                    }}
                  >
                    <Row gutter={16} className="recheck-form">
                      <Col span={16}>
                        <Form
                          name="searchBox"
                          autoComplete="off"
                          form={form}
                          colon={false}
                          style={{ marginTop: 0 }}
                          layout="horizontal"
                          labelWrap
                          labelAlign="left"
                        >
                          <Row
                            gutter={16}
                            style={{ marginTop: 8, marginBottom: 8 }}
                          >
                            {keyMaps.length > 0 &&
                              keyMaps
                                .filter((x) => x.isDisplay)
                                .map((item: any, index) => {
                                  return (
                                    <Col span={24} key={item.dataKeyAscii}>
                                      {convertTypeDataToElement(
                                        item,
                                        index,
                                        true
                                      )}
                                    </Col>
                                  );
                                })}
                          </Row>
                        </Form>
                      </Col>
                      <Col span={8}>
                        <Form
                          name="searchBox"
                          autoComplete="off"
                          form={form2}
                          colon={false}
                          style={{ marginTop: 0 }}
                          layout="horizontal"
                          labelWrap
                          labelAlign="left"
                        >
                          <Row
                            gutter={16}
                            style={{ marginTop: 8, marginBottom: 8 }}
                          >
                            {keyMaps2.length > 0 &&
                              keyMaps2
                                .filter((x) => x.isDisplay)
                                .map((item: any, index) => {
                                  return (
                                    <Col span={24} key={item.dataKeyAscii}>
                                      {convertTypeDataToElement(
                                        item,
                                        index,
                                        false
                                      )}
                                    </Col>
                                  );
                                })}
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className="search-box-button">
                <Button
                  onClick={() => navigate("/data/recheck-list")}
                  htmlType="button"
                >
                  Quay lại
                </Button>
              </div>
            </div>
          </SplitPane>
        </div>
      </div>
    </Spin>
  );
};

export default DetailDataCompare;
