import { Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import RecheckCompaignList from "./components/RecheckCampaignList";
import { DataListCampaignTable } from "./redux/apiTypes";
import { useLazyGetListRecheckCampaignQuery } from "./redux/recheckCampaignApi";
import moment from "moment";

const RecheckCampaign = () => {
  const [form] = Form.useForm();

  const [filter, setFilter] = useState<{
    name?: string;
    description?: string;
    status?: number;
    page: number;
    size: number;
  }>({
    name: "",
    description: "",
    status: undefined,
    page: 0,
    size: 10,
  });

  const [dataSource, setDataSource] = useState<DataListCampaignTable[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [triggerListData, { data, isFetching }] =
    useLazyGetListRecheckCampaignQuery();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerListData]);

  useEffect(() => {
    if (data?.content) {
      setDataSource(
        data?.content?.map((elem: any) => {
          return {
            id: elem?.id,
            name: elem?.name,
            numberCase: elem?.numberCase,
            percentComplete: elem?.percentComplete,
            status: elem?.status,
            description: elem?.description,
            createDate: elem?.createdDate,
            campaignType: elem?.campaignType,
            totalTHHT: elem?.totalTHHT,
            totalTHHTComplete: elem?.totalTHHTComplete,
            totalTHHTError: elem?.totalTHHTError,
            createdDateStr: elem?.createdDate
              ? moment(elem?.createdDate)?.format("HH:mm DD/MM/yyyy")
              : "",
            campaignName: elem?.campaignName,
            groupUserIdsStr:
              elem?.listGroupUsers?.map((item: any) => item?.id) || [],
          };
        })
      );
    }
    setTotal(data?.totalElements || 0);
  }, [data]);

  const getData = () => {
    triggerListData({
      name: "",
      description: "",
      page: 0,
      size: 10,
    });
  };

  const onFinish = (values: {
    name: string;
    description: string;
    status: string;
  }) => {
    const { name, description, status } = values;
    const newFilter = {
      ...filter,
      page: 0,
      status: status ? parseInt(status) : undefined,
      name: name ? name : "",
      description: description ? description : "",
    };
    // console.log("newFilter", newFilter);
    triggerListData(newFilter);
    setFilter(newFilter);
  };

  const onChangeTable = (pageNum?: number, pageSize?: number) => {
    // console.log("pageNum", pageNum, "pageSize", pageSize);
    const newFilter = {
      ...filter,
      page: pageNum !== undefined ? pageNum - 1 : filter.page,
      size: pageSize !== undefined ? pageSize : filter.size,
    };
    triggerListData(newFilter);
    setFilter(newFilter);
  };

  const resetFormData = () => {
    form.resetFields();
    setFilter({
      name: "",
      description: "",
      page: 0,
      size: 10,
    });
  };
  return (
    <>
      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
        >
          <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
            <Col span={8} xs={24} md={12} lg={12}>
              <Form.Item
                style={{ marginBottom: 10 }}
                label="Tên chiến dịch"
                name="name"
              >
                <Input
                  placeholder="Nhập tên chiến dịch"
                  style={{ minWidth: "100%" }}
                  autoComplete="on"
                />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={12} lg={12}>
              <Form.Item
                style={{ marginBottom: 10 }}
                label="Trạng thái"
                name="status"
              >
                <Select allowClear placeholder="Chọn trạng thái" showArrow>
                  <Select.Option value={0}>Hoạt động</Select.Option>
                  <Select.Option value={1}>Không hoạt động</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="search-box-button">
            <Button htmlType="button" onClick={() => resetFormData()}>
              Xóa điều kiện
            </Button>
            <Button htmlType="submit" type="primary">
              Tìm kiếm
            </Button>
          </div>
        </Form>
      </div>
      <RecheckCompaignList
        total={total}
        isLoading={isFetching}
        filter={filter}
        dataSource={dataSource}
        onChangeTable={onChangeTable}
        refetch={getData}
      />
    </>
  );
};

export default RecheckCampaign;
