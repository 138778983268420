import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import AccessHistoryList from "./components/AccessHistoryList";
import { DatePicker } from "../../custom-components";
import { format } from "date-fns";
import { ReactComponent as ZoomOut } from '../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../assets/logos/zoom-in-line.svg';
const { RangePicker } = DatePicker;

export interface ISearchParamsAccessHistory {
    fromDate?: string;
    toDate?: string;
    username?: string;
    ip?: string;
    type?: number;
    timeAccess?: string[];
}

const AccessHistory = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});

    const onFinish = (values: ISearchParamsAccessHistory) => {
        const timeAccess = values.timeAccess;
        if (timeAccess && timeAccess.length === 2) {
            values.fromDate = format(new Date(timeAccess[0]), "yyyy-MM-dd");
            values.toDate = format(new Date(timeAccess[1]), "yyyy-MM-dd");
        } else {
            values.fromDate = undefined;
            values.toDate = undefined;
        }
        values.username = values.username || undefined;
        values.ip = values.ip || undefined;
        values.type = values.type ? (values.type === 1 ? 0 : 1) : undefined;
        setSearchParam(values);
    };
    const [isSearch, setIsSearch] = useState(false);
    return (
        <>
            <div className="content-box" style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>

                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                            <Col span={8} xs={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Tên người dùng"
                                    name="username"
                                >
                                    <Input
                                        allowClear
                                        placeholder="Nhập tên người dùng để tìm kiếm"
                                        style={{ minWidth: "100%" }}
                                        autoComplete="on"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="IP"
                                    name="ip"
                                >
                                    <Input
                                        allowClear
                                        placeholder="Nhập IP cần tìm kiếm"
                                        style={{ minWidth: "100%" }}
                                        autoComplete="on"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Loại truy cập"
                                    name="type"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại truy cập (all / login / logout ...)"
                                        showArrow
                                    >
                                        <Select.Option value={0}>All</Select.Option>
                                        <Select.Option value={1}>
                                            Login
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            Logout
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Thời gian truy cập"
                                    name="timeAccess"
                                >
                                    <RangePicker
                                        format="DD/MM/Y"
                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="search-box-button">
                            <Button
                                htmlType="button"
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Xóa điều kiện
                            </Button>
                            <Button htmlType="submit" type="primary">
                                Tìm kiếm
                            </Button>
                        </div>
                    </> : <></>}

                </Form>
            </div>
            <AccessHistoryList form={form} searchParam={searchParam} />
        </>
    );
};

export default AccessHistory;
