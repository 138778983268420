import { Breadcrumb, Button, Col, Form, Input, Row, Select, Space } from "antd";
import { ReactComponent as Home } from '../../../assets/logos/home.svg';
import React, { useEffect, useState } from "react";
import ReportList from "./components/ReportList";
import { useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetGroupUserCheckDataQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { TYPE_DATA } from "../../../assets/ts/constant";
import { DatePicker } from "../../../custom-components";
import { subMonths, startOfMonth, endOfMonth, startOfYear, subYears, endOfYear } from "date-fns";
import { useLocation } from "react-router-dom";
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
const { RangePicker } = DatePicker;

export const TYPE_ERROR: Record<string, number> = {
    "Không": 0,
    "Có": 999,
};
export const TYPE_PDF: Record<string, string> = {
    "A3": "A3",
    "A4": "A4",
};

const NangXuatKiemTraReport = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [searchParam, setSearchParam] = useState({});
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerGroupUserCheck, responseGroupUserCheck] = useLazyGetGroupUserCheckDataQuery();

    useEffect(() => {
        triggerUserCheck({});
        triggerProvince({});
        if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            setSearchParam(newSearchParam);
            form.setFieldsValue(newSearchParam)
        }
        // else {
        //     form.setFieldsValue({ dates: [new Date(), new Date()] });
        //     setSearchParam({ dates: [new Date(), new Date()] });
        // }
    }, [triggerUserCheck, state]);

    useEffect(() => {
        triggerGroupUserCheck({});
    }, [triggerGroupUserCheck])

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] === "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceId && changedValues.provinceId.length === 1) {
                districtTrigger(changedValues.provinceId[0]).unwrap()
                    .then((res: any) => setDistricts(res));
            } else {
                setDistricts([]);
            }
            form.setFieldsValue({
                districtId: undefined,
                communeId: undefined,
            })

        } else if (Object.keys(changedValues)[0] === "districtId") {
            setCommunes([]);
            if (changedValues.districtId && changedValues.districtId.length === 1) {
                communeTrigger(changedValues.districtId[0]).unwrap()
                    .then((res: any) => setCommunes(res));
            } else {
                setCommunes([]);
            }
            form.setFieldsValue({
                communeId: undefined
            })
        }
    }

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    const resetFormData = () => {
        form.resetFields();
    }

    const [isSearch, setIsSearch] = useState(false);

    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Báo cáo hiệu năng</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            {/* <div className='title-label' style={{ marginBottom: 20 }}>Báo cáo năng suất kiểm tra</div> */}

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChanged}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>

                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Ngày kiểm tra"
                                    name="dates"
                                >
                                    <RangePicker
                                        format="DD/MM/Y"
                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                        ranges={{
                                            'Hôm nay': [new Date(), new Date()],
                                            'Tháng này': [startOfMonth(new Date()), new Date()],
                                            'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                            '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                            '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                            'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                            'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            {/* <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Từ tài khoản kiểm tra"
                                    name="createByStart"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Từ tài khoản kiểm tra"
                                        options={(responseUserCheck?.data || []).filter(item => item?.roles?.[0]?.authoritieLst?.find((i: any) => i?.authoritieName === "Kiểm tra dữ liệu")
                                        ).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.username
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                        // mode="multiple"
                                        optionFilterProp='label'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Đến tài khoản kiểm tra"
                                    name="createByEnd"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Đến tài khoản kiểm tra"
                                        options={(responseUserCheck?.data || []).filter(item => item?.roles?.[0]?.authoritieLst?.find((i: any) => i?.authoritieName === "Kiểm tra dữ liệu")
                                        ).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.username
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                        // mode="multiple"
                                        optionFilterProp='label'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col> */}

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Nhóm người thực hiện"
                                    name="groupUserIds"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn nhóm người thực hiện"
                                        options={(responseGroupUserCheck?.data || []).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        mode="multiple"
                                        maxTagCount='responsive'
                                        optionFilterProp="label"
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Người kiểm tra"
                                    name="createBys"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Nhập người kiểm tra"
                                        options={(responseUserCheck?.data || []).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.username
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                        mode="multiple"
                                        optionFilterProp='label'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Loại hộ tịch"
                                    name="typeIds"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại hộ tịch"
                                        optionFilterProp='label'
                                        options={Object.keys(TYPE_DATA).map(item => ({
                                            label: `${TYPE_DATA[item]} (${item})`,
                                            value: item
                                        }))}
                                        showSearch
                                        showArrow
                                        mode="multiple"
                                        maxTagCount={"responsive"}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Kích thước sổ"
                                    name="typePdf"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn kích thước sổ"
                                        optionFilterProp='label'
                                        options={Object.keys(TYPE_PDF).map(item => ({
                                            label: `${TYPE_PDF[item]}`,
                                            value: item
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount={"responsive"}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Số trường sai sót"
                                name="totalFieldError"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn số trường sai sót"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_ERROR).map(item => ({
                                        label: `${item}`,
                                        value: TYPE_ERROR[item]
                                    }))}
                                    showSearch
                                    showArrow
                                    maxTagCount={"responsive"}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Số trường đã chỉnh sửa"
                                name="totalFieldEdit"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn số trường đã chỉnh sửa"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_ERROR).map(item => ({
                                        label: `${item}`,
                                        value: TYPE_ERROR[item]
                                    }))}
                                    showSearch
                                    showArrow
                                    maxTagCount={"responsive"}
                                >
                                </Select>
                            </Form.Item>
                        </Col> */}
                        </Row>
                        <div className='search-box-button'>
                            <Button htmlType='button' onClick={() => resetFormData()}>
                                Xóa điều kiện
                            </Button>
                            <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                        </div>
                    </> : <></>}
                </Form>
            </div>
            <ReportList form={form} searchParam={searchParam} />
        </>
    )
}

export default NangXuatKiemTraReport;
