import {
  CloseOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as Warning } from "../../../assets/logos/warning.svg";
import {
  useDeleteReportReasonMutation,
  useInsertReportReasonMutation,
  useLazyGetInitReportErrorQuery,
} from "../../data_init/redux/dataInitApi";
import { da } from "date-fns/locale";
import { useAppSelector } from "../../../redux/Hooks";

type ErrorProps = {
  type: number;
  widthConfig: number;
  visible: boolean;
  cancelText: string;
  okText?: string;
  note?: string;
  okSuccess?: string;
  classBtnOk: string;
  contents: string[];
  handleOk: (message?: string) => void;
  handleSuccess?: () => void;
  handleCancel: () => void;
  okBtnDanger?: boolean;
  isMessage?: boolean;
  isLoading?: any;
};

const ConfirmModal = ({
  type,
  widthConfig,
  visible,
  cancelText,
  okText,
  note,
  okSuccess,
  classBtnOk,
  contents,
  handleOk,
  handleSuccess,
  handleCancel,
  okBtnDanger,
  isMessage,
  isLoading,
}: ErrorProps) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const user = useAppSelector((state) => state.user);

  const [triggerGetData, { data: listData, isLoading: loading }] =
    useLazyGetInitReportErrorQuery();

  const [insertReportReason, { isLoading: insertLoading }] =
    useInsertReportReasonMutation();

  const [deleteReportReason, { isLoading: deleteLoading }] =
    useDeleteReportReasonMutation();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (isMessage) {
      triggerGetData(null);
    }
  }, [isMessage, triggerGetData]);

  const submit = () => {
    if (isMessage) {
      form.validateFields().then((res) => {
        if (open && user?.isAdmin) {
          insertReportReason({
            reason: res.title.trim(),
          })
            .unwrap()
            .then((data) => {
              triggerGetData(null);
              notification.success({ message: "Thêm mới lý do thành công!" });
            })
            .catch((e) => {
              notification.error({ message: "Thêm mới lý do thất bại!" });
            })
            .finally(() => {
              setOpen(false);
            });
        } else {
          handleOk(form?.getFieldsValue()?.message);
        }
      });
    } else {
      handleOk();
    }
  };

  const insertOption = () => {
    setOpen(true);
    form.resetFields();
  };

  const deleteOption = () => {
    if (user?.isAdmin) {
      deleteReportReason({
        reasons: form.getFieldValue("message"),
      })
        .unwrap()
        .then((data) => {
          triggerGetData(null);
          notification.success({ message: "Xóa lý do thành công!" });
        })
        .catch((res) => {
          notification.error({ message: res?.data?.message });
        })
        .finally(() => {
          form.resetFields();
          setOpen(false);
        });
    }
  };

  const showPromiseConfirm = () => {
    confirm({
      title: "XÁC NHẬN",
      icon: <ExclamationCircleFilled />,
      content: "Bạn có chắc chắn muốn xóa lý do này?",
      okText: "Xác nhận",
      cancelText: "Hủy bỏ",
      centered: true,
      onOk() {
        return deleteOption();
      },
    });
  };

  const messages = Form.useWatch("message", form);

  const canShowDelete = useMemo(() => {
    if (!messages?.length) {
      return false;
    }

    const cannotDelete = messages?.some((item: string) => {
      let reasonItem = listData.find((rItem: any) => rItem.reason === item);
      console.log(reasonItem);

      if (!reasonItem || !reasonItem?.canDelete) return true;

      return false;
    });

    return !cannotDelete;
  }, [listData, messages]);

  return (
    <>
      <Modal
        width={widthConfig || 424}
        title={null}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={[
          <React.Fragment key="footer">
            <Button
              htmlType="button"
              className="white-red-border-button"
              onClick={() => handleCancel()}
            >
              {cancelText}
            </Button>
            {okText ? (
              <Button
                loading={isLoading}
                danger={okBtnDanger}
                htmlType="button"
                className={classBtnOk}
                style={{ marginLeft: 20 }}
                onClick={() => submit()}
                type="primary"
              >
                {okText}
              </Button>
            ) : (
              <></>
            )}
            {okSuccess && handleSuccess ? (
              <Button
                loading={isLoading}
                style={{
                  marginLeft: 20,
                  backgroundColor: "#49cc90",
                  border: "#49cc90",
                }}
                htmlType="button"
                className={classBtnOk}
                onClick={() => handleSuccess()}
                type="primary"
              >
                {okSuccess}
              </Button>
            ) : (
              <></>
            )}
          </React.Fragment>,
        ]}
        className="error-1"
      >
        {type === 1 ? <Warning /> : <></>}
        <label>Xác nhận</label>
        {contents && contents.length ? (
          contents.map((item, index) => (
            <div style={{ textAlign: "center" }} key={index}>
              {item}
            </div>
          ))
        ) : (
          <></>
        )}
        {note ? (
          <div style={{ textAlign: "center", color: "#ff4d4f" }}>{note}</div>
        ) : (
          <></>
        )}
        {isMessage ? (
          <div
            style={{
              textAlign: "center",
              color: "#ff4d4f",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Form
              name="searchBox"
              autoComplete="off"
              form={form}
              labelWrap
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              labelAlign="left"
              requiredMark={false}
              colon={false}
            >
              <Form.Item
                label="Lý do"
                name="message"
                rules={[
                  { required: !open, message: "Trường dữ liệu bắt buộc." },
                ]}
              >
                <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Chọn lý do báo lỗi"
                  style={{ textAlign: "left" }}
                  options={listData?.map((item: any) => ({
                    label: item.reason,
                    value: item.reason,
                  }))}
                  loading={loading}
                  allowClear
                  optionFilterProp="label"
                  dropdownRender={(option) => (
                    <>
                      {option}
                      {user?.isAdmin && (
                        <>
                          <Divider style={{ margin: "8px 0" }} />
                          <div style={{ padding: "0 8px 4px", width: "100%" }}>
                            <Button
                              style={{ marginRight: "10px" }}
                              type="primary"
                              onClick={insertOption}
                              icon={<PlusOutlined />}
                            >
                              Thêm
                            </Button>
                            {form.getFieldsValue().message?.length > 0 &&
                              canShowDelete && (
                                <Button
                                  danger
                                  onClick={showPromiseConfirm}
                                  icon={<CloseOutlined />}
                                >
                                  Xóa
                                </Button>
                              )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  showSearch
                  showArrow
                ></Select>
              </Form.Item>

              {user?.isAdmin && (
                <Modal
                  title="Thêm lý do báo lỗi"
                  open={open}
                  onOk={submit}
                  okButtonProps={{ value: "Xác nhận" }}
                  confirmLoading={insertLoading}
                  onCancel={() => setOpen(!open)}
                  centered
                  okText="Xác nhận"
                  cancelText="Hủy bỏ"
                >
                  <Form.Item
                    label="Lý do"
                    name="title"
                    rules={[
                      { required: open, message: "Trường dữ liệu bắt buộc." },
                    ]}
                  >
                    <Input.TextArea
                      style={{ width: "100%" }}
                      allowClear
                      value={inputValue}
                      maxLength={100}
                      placeholder="Nhập tay tại đây"
                      onChange={(e) => setInputValue(e.target.value)}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Modal>
              )}
            </Form>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default ConfirmModal;
