import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from "date-fns";
import { ReactComponent as Close } from '../../../assets/logos/close-contained.svg';
import { DrawerProp } from '../assets/ts/types';
import DatePicker from '../../../custom-components/DatePicker';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLazyGetFieldConfigQuery } from '../../field_config/redux/fieldConfigApi';
import { TYPE_DATA } from '../../../assets/ts/constant';
import moment from 'moment';
const { RangePicker } = DatePicker;
// const Index = ({ visible, onAddSuccessful, currentUser, resetState }: DrawerProp) => {

const FormSearchFilter = ({ visible, resetState, onOk }: DrawerProp) => {
    const [userForm] = Form.useForm()
    const [DATATYPEID, setDATATYPEID] = useState<any>();
    const [dataType, setDataType] = useState<any[]>([]);
    
    const [triggerFieldConfig, responseDataInfo] = useLazyGetFieldConfigQuery();

    useEffect(()=>{
        triggerFieldConfig({page:0, size: 100})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if (responseDataInfo?.data?.content?.length) {
            let newDataType: any[] = [];
            responseDataInfo?.data?.content?.filter((item:any)=>{ return item?.year === "A4" && item.type!=="CN-cũ"})?.forEach((item, index) => {
                // const keyObject = item.type;
                newDataType.push({
                    // [keyObject]: {
                        id: item.id,
                        type: item.type,
                        fieldKeyMap: item.fieldKeyMap,
                    // },
                })
            })
            setDataType(newDataType);
            // console.log(newDataType?.filter((item:any)=> item.type === "KS")[0]?.fieldKeyMap?.filter((keyMapFilter:any)=> keyMapFilter.isSearch===true))
        }
        else{
            setDataType([]);
        }
    },[responseDataInfo])


    const onSearch = () => {
        userForm.submit()
    }

    const onFinish = (values: any) => {
        let dataInfoMap: any = {};
        Object.keys(values).map(function(key, index) {
            if(values[key] && key!== "dataType"){
                if(typeof values[key]!=="string"){
                    dataInfoMap[key]= JSON.stringify({value: { from: moment(values[key][0])?.startOf("day").unix(), to: moment(values[key][1])?.endOf("day").unix() }, is_date: true });
                }else{
                    dataInfoMap[key] = JSON.stringify({value: values[key], is_regex: false});
                }
            }
            return index;
        });
        if(Object.keys(dataInfoMap).length > 0){
            onOk({
                dataInfoMap,
                typeId: values?.dataType,
            });
        }else{
            onOk({typeId: values?.dataType,}); 
        }
        resetState();
    }
    // console.log('userForm', userForm);
    const handleChange = (value: string) => {
        setDATATYPEID(value)
        // console.log(`selected ${value}`);
    };

    return (
        <>
            <Drawer
                closable={false}
                className="custom-drawer"
                width={'30%'}
                title={<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                    <span>Bộ Lọc Nâng Cao</span>
                    <Close className="title-edit-icon" style={{ cursor: 'pointer', width: 40, height: 40 }} onClick={() => resetState()} />
                </div>}
                placement="right"
                visible={visible}
                onClose={() => resetState()}
                maskStyle={{ backgroundColor: 'transparent' }}
                footer={[
                    <Row align="middle" justify="end" className='row-drawer' style={{ zIndex: 10 }}>
                        <Button
                            htmlType='button'
                            className="drawer-button"
                            type="primary"
                            onClick={onSearch}
                        >
                            Tìm kiếm
                        </Button>
                    </Row>
                ]}

            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    labelAlign="left"
                    form={userForm}
                    onFinish={onFinish}
                    requiredMark={false}
                    name="user-form"
                    scrollToFirstError={{ behavior: 'smooth' }}
                >
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="Loại dữ liệu"
                                name="dataType"
                                rules={[
                                    { required: true, message: "Vui lòng chọn Loại dữ liệu" }
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder='Chọn Loại dữ liệu'
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={handleChange}
                                    options={dataType.map((item)=>{
                                        return {
                                            value: item.type,
                                            label: `${TYPE_DATA[item.type]} (${item.type})`
                                        }
                                    })}
                                    >
                                </Select>
                            </Form.Item>
                        </Col>
                        
                        { dataType?.filter((item:any)=> item.type === DATATYPEID)?.length ? 
                            (dataType?.filter((item:any)=> item.type === DATATYPEID)[0]?.fieldKeyMap
                                        ?.filter((keyMapFilter:any)=> keyMapFilter.isSearch===true)
                                            ?.map((keyMap:any)=>{
                                                if(keyMap.type==="date"){
                                                    return  <Col span={24}>
                                                                <Form.Item
                                                                    label={keyMap.dataKey}
                                                                    name={keyMap.dataKey}
                                                                    // rules={[
                                                                    //     { required: true, message: `Vui lòng chọn ${keyMap.dataKey}` }
                                                                    // ]}
                                                                >
                                                                    <RangePicker
                                                                        format="DD/MM/Y"
                                                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                                                        ranges={{
                                                                            'Hôm nay': [new Date(), new Date()],
                                                                            'Tháng này': [startOfMonth(new Date()), new Date()],
                                                                            'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                                                            '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                                                            '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                                                            'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                                                            'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                                                        }}
                                                                    />
                                                                </Form.Item >
                                                            </Col >
                                                } 
                                                if(keyMap.data){
                                                    return  <Col span={24}>
                                                                <Form.Item
                                                                    label={keyMap.dataKey}
                                                                    name={keyMap.dataKey}
                                                                    // rules={[
                                                                    //     { required: true, message: `Vui lòng chọn ${keyMap.dataKey}` }
                                                                    // ]}
                                                                >
                                                                    <Select
                                                                        allowClear
                                                                        placeholder={`Chọn ${keyMap.dataKey}`}
                                                                        showSearch
                                                                        optionFilterProp="label"
                                                                        options={keyMap.data.map((item:any)=>{
                                                                            return {
                                                                                value: item.id,
                                                                                label: item.name
                                                                            }
                                                                        })}
                                                                    >
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                }
                                                return <Col span={24}> 
                                                            <Form.Item
                                                                label={keyMap.dataKey}
                                                                name={keyMap.dataKey}
                                                                // rules={[
                                                                //     { required: true, whitespace: true, message: `Vui lòng nhập ${keyMap.dataKey}` }
                                                                // ]}
                                                            >
                                                                <Input
                                                                    maxLength={keyMap.maxLength}
                                                                    className="propNameInput"
                                                                    placeholder={keyMap.placeholder || `Nhập ${keyMap.dataKey}` }
                                                                    allowClear
                                                                    autoComplete="on"
                                                                />
                                                            </Form.Item >
                                                        </Col >
                                            })
                            )
                        :<></>}
                    </Row>
                </Form >
            </Drawer>
        </>
    )
}

export default FormSearchFilter
