import {
  Row,
  Menu,
  Table,
  Pagination,
  FormInstance,
  notification,
  Button,
  Col,
  Spin,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { ReactComponent as Edit } from "../../../assets/logos/pen-edit.svg";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetCountDataInfoInitQuery,
  useLazyGetDataInfoInitQuery,
} from "../redux/dataInitApi";
import moment from "moment";
import { DEFAULT_FIELD_CONFIG, TYPE_DATA } from "../../../assets/ts/constant";
import {
  useLazyGetCommuneAllQuery,
  useLazyGetCommuneQuery,
  useLazyGetDistrictAllQuery,
  useLazyGetDistrictQuery,
  useLazyGetProvinceQuery,
  useLazyGetUserCheckDataQuery,
} from "../../../redux/api/commonApi";
import { DataInfoInit, FieldConfig } from "../redux/apiTypes";
import CreateCampaign from "./CreateCampaign";
import { useAppSelector } from "../../../redux/Hooks";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
interface DataListTable {
  key: string;
  index: number;
  createBy?: string;
  type?: string;
  createdDate?: string;
  fileName: string;
  fileName158: string;
  address?: string;
  excelName?: string;
  rowNumber?: string;
  message?: string;
  groupUsers?: string;
  status?: number;
}

type Props = {
  form: FormInstance<any>;
  searchParam: any;
  stateOld: any;
  triggerCampaign: () => void;
};

export const INFO_INIT_STATUS = {
  WAITING: 0,
  UN_MATCHED: 1,
  MATCHED: 2,
  WAIT_RECHECK: 3,
  RECHECKED: 4,
  NOT_MATCHED_158: 8,
};

const StatusElement = ({ value }: { value: number }) => {
  const statusStyle = {
    padding: "4px",
    borderRadius: "4px",
  };

  const statusMapping = {
    [INFO_INIT_STATUS.WAITING]: {
      color: "yellow",
      text: "Chờ xử lý",
      bg: "#F6B306",
    },
    [INFO_INIT_STATUS.UN_MATCHED]: {
      color: "#E11D48",
      text: "Không khớp dữ liệu",
      bg: "#FFE4E6",
    },
    [INFO_INIT_STATUS.MATCHED]: {
      color: "green",
      text: "Khớp dữ liệu",
      bg: "#CCFBF1",
    },
    [INFO_INIT_STATUS.WAIT_RECHECK]: {
      color: "yellow",
      text: "Chờ đối chiếu",
      bg: "#F6B306",
    },
    [INFO_INIT_STATUS.RECHECKED]: {
      color: "green",
      text: "Đã đối chiếu",
      bg: "#CCFBF1",
    },
    [INFO_INIT_STATUS.NOT_MATCHED_158]: {
      color: "#E11D48",
      text: "Không tìm thấy bản ghi trùng khớp",
      bg: "#FFE4E6",
    },
  };
  const status = statusMapping[value] || { color: "gray", text: "Unknown" };

  return (
    <span
      style={{
        ...statusStyle,
        color: status.color,
        backgroundColor: status.bg,
      }}
    >
      {status.text}
    </span>
  );
};

const RegisterInfoList = ({
  form,
  searchParam,
  stateOld,
  triggerCampaign,
}: Props) => {
  const user = useAppSelector((state) => state.user);
  const pageSizeRef = useRef(10);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [provinceObj, setProvinceObj] = useState();
  const [districtObj, setDistrictObj] = useState();
  const [communeObj, setCommuneObj] = useState();
  const userState = useAppSelector((state) => state.user.user);
  const [tableData, setTableData] = useState<DataListTable[]>();
  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();
  const [communeTrigger, responseCommune] = useLazyGetAllCommuneNoAuthQuery();
  const [districtTrigger, responseDistrict] =
    useLazyGetAllDistrictNoAuthQuery();
  const [triggerDataInfoInit, responseDataInfoInit] =
    useLazyGetDataInfoInitQuery();
  const [triggerCountDataInfoInit, responseCountDataInfoInit] =
    useLazyGetCountDataInfoInitQuery();
  const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
  // const [triggerReportTotal, responseReportTotal] = useLazyGetCountDataInfoInitQuery();
  const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();

  // const canAddCampaign = checkPermission(PERMISSION_KEYS.CHIEN_DICH_KIEM_TRA.THEM_MOI)

  useEffect(() => {
    triggerProvince({});
    districtTrigger({});
    communeTrigger({});
    triggerUserCheck({});
  }, [triggerProvince, districtTrigger, communeTrigger, triggerUserCheck]);

  useEffect(() => {
    console.log("searchParam", searchParam);
    if (form.getFieldValue("provinceIdStr")) {
      getData(false);
    }
  }, [searchParam]);

  useEffect(() => {
    if (responseProvince) {
      setProvinceObj(
        responseProvince?.data?.reduce(
          (a: any, v: any) => ({ ...a, [v.id]: v.provinceName }),
          {}
        )
      );
    }
    if (responseDistrict) {
      setDistrictObj(
        responseDistrict?.data?.reduce(
          (a: any, v: any) => ({ ...a, [v.id]: v.districtName }),
          {}
        )
      );
    }
    if (responseCommune) {
      setCommuneObj(
        responseCommune?.data?.reduce(
          (a: any, v: any) => ({ ...a, [v.id]: v.communeName }),
          {}
        )
      );
    }
  }, [responseProvince, responseDistrict, responseCommune]);

  useEffect(() => {
    if (responseDataInfoInit?.data?.content?.length) {
      let newTableData: DataListTable[] = [];
      // if(userState === 'admin'){

      // }
      responseDataInfoInit.data.content.forEach((item, index) => {
        newTableData.push({
          key: item.id,
          index: index + 1,
          type: item?.filePdf?.[0]?.fileName?.split(".")?.[0]
            ? TYPE_DATA[item?.filePdf?.[0]?.fileName?.split(".")?.[0]]
            : "",
          createBy: item.createBy,
          message: item.message,
          rowNumber: item.rowNumber,
          excelName: item.excelName,
          createdDate: item.createdDate
            ? moment(item.createdDate).format("HH:mm DD/MM/YYYY")
            : "",
          // fileName: item?.filePdf?.[0]?.fileName || "",
          // fileName158: customFileFileName158(item, item?.filePdf?.[0]?.fileName?.split(".")?.[0] || ''),
          fileName: item?.filePdfName || "",
          fileName158: item?.filePdf158Name || "",
          address: customContent(item),
          status: item.status,
          groupUsers:
            item?.groupUsers?.map((subItem) => subItem?.name)?.join(", ") ||
            "-",
        });
      });
      // const newData = responseDataInfoInit?.data?.content.filter((item) => item?.modifiedBy === userState?.username)
      // console.log("abc",newData);

      setTableData(newTableData);
    } else {
      setTableData([]);
    }
  }, [responseDataInfoInit]);

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, record, index) =>
        (page - 1) * pageSizeRef.current + index + 1,
      width: 70,
    },
    {
      title: "File pdf",
      dataIndex: "fileName",
      key: "fileName",
      ellipsis: true,
      width: 200,
    },
    {
      title: "File pdf 158",
      dataIndex: "fileName158",
      key: "fileName158",
      ellipsis: true,
      width: 200,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Nhóm người dùng",
      dataIndex: "groupUsers",
      key: "groupUsers",
    },
    {
      title: "Người thực hiện",
      dataIndex: "createBy",
      key: "createBy",
    },
    // {
    //     title: "Tên file excel",
    //     dataIndex: "excelName",
    //     key: "excelName",
    // },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => <StatusElement value={value} />,
    },
    // {
    //     title: "Dòng",
    //     dataIndex: "rowNumber",
    //     key: "rowNumber",
    // },
    // {
    //     title: "Ghi chú",
    //     dataIndex: "message",
    //     key: "message",
    // },
    // {
    //     title: "Người tạo",
    //     dataIndex: "createBy",
    //     key: "createBy",
    // },
    {
      title: "Ngày nhập liệu",
      dataIndex: "createdDate",
      key: "createdDate",
    },
  ];

  const getData = (getCurrentPage: boolean) => {
    triggerDataInfoInit({
      searchParam: searchParam
        ? {
            ...searchParam,
            createDates: undefined,
            updateDates: undefined,
            typeId: searchParam.typeId ? searchParam.typeId : undefined,
            fileName: searchParam.fileName ? searchParam.fileName : undefined,
            isCheck: searchParam.isCheck
              ? searchParam.isCheck === 1
                ? true
                : false
              : undefined,
            fromCreateDate:
              searchParam?.createDates && searchParam.createDates[0]
                ? moment(searchParam.createDates[0]).format("YYYY-MM-DD")
                : undefined,
            toCreateDate:
              searchParam?.createDates && searchParam.createDates[1]
                ? moment(searchParam.createDates[1]).format("YYYY-MM-DD")
                : undefined,
            fromUpdateDate:
              searchParam?.updateDates && searchParam.updateDates[0]
                ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD")
                : undefined,
            toUpdateDate:
              searchParam?.updateDates && searchParam.updateDates[1]
                ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD")
                : undefined,
          }
        : "",
      page: stateOld?.page ? stateOld.page - 1 : getCurrentPage ? page - 1 : 0,
      size: stateOld?.size ? stateOld.size : pageSizeRef.current,
    });
    // triggerCountDataInfoInit({
    //     searchParam: searchParam ? {
    //         ...searchParam,
    //         createDates: undefined,
    //         updateDates: undefined,
    //         typeId: searchParam.typeId ? searchParam.typeId : undefined,
    //         fileName: searchParam.fileName ? searchParam.fileName : undefined,
    //         status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
    //         isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
    //         fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
    //         toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
    //         fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
    //         toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
    //     } : "",
    //     page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
    //     size: stateOld?.size ? stateOld.size : pageSizeRef.current
    // })

    // triggerReportTotal({ searchParam });

    if (stateOld?.page || stateOld?.size) {
      if (stateOld?.page) setPage(stateOld?.page);
      if (stateOld?.size) pageSizeRef.current = stateOld?.size;
    } else if (!getCurrentPage) {
      setPage(1);
    }
  };

  const customFileFileName158 = (dataInfoInit: any, type: string) => {
    return dataInfoInit?.dataKeyMap?.reduce(
      (a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }),
      {}
    )?.[DEFAULT_FIELD_CONFIG[type]?.tenfilepdf];
  };

  const customContent = (dataInfoInit: DataInfoInit) => {
    // let address = dataInfoInit?.dataKeyMap?.filter((item: any) => {
    //     if ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.tinhThanh ||
    //         item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.quanHuyen ||
    //         item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.phuongXa) && item.dataValue) return true;
    //     return false;
    // }) || [];
    // return address.map((item: FieldConfig) => {
    //     if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.tinhThanh && item.dataValue) return provinceObj?.[item.dataValue];
    //     if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.quanHuyen && item.dataValue) return districtObj?.[item.dataValue];
    //     if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.phuongXa && item.dataValue) return communeObj?.[item.dataValue];
    // }).join(", ");

    let address: string[] = [];
    if (dataInfoInit.communeId && communeObj?.[dataInfoInit.communeId]) {
      address.push(communeObj[dataInfoInit.communeId]);
    }

    if (dataInfoInit.districtId && districtObj?.[dataInfoInit.districtId]) {
      address.push(districtObj[dataInfoInit.districtId]);
    }

    if (dataInfoInit.provinceId && provinceObj?.[dataInfoInit.provinceId]) {
      address.push(provinceObj[dataInfoInit.provinceId]);
    }

    return address.join(", ");
  };

  const openDetailModal = (e: any, item: DataListTable) => {
    // if (item.status !== 2) {
    navigate(`/data/info_init_detail/${item.key}`, {
      replace: true,
      state: { searchParam, page, size: pageSizeRef.current },
    });
    // }
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    setPage(newPage);
    pageSizeRef.current = pageSize;
    // triggerReportTotal({ searchParam });

    triggerDataInfoInit({
      searchParam: searchParam
        ? {
            ...searchParam,
            createDates: undefined,
            updateDates: undefined,
            typeId: searchParam.typeId ? searchParam.typeId : undefined,
            fileName: searchParam.fileName ? searchParam.fileName : undefined,
            status: searchParam.status
              ? searchParam.status
                ? 1
                : 0
              : undefined,
            isCheck: searchParam.isCheck
              ? searchParam.isCheck === 1
                ? true
                : false
              : undefined,
            fromCreateDate:
              searchParam?.createDates && searchParam.createDates[0]
                ? moment(searchParam.createDates[0]).format("YYYY-MM-DD")
                : undefined,
            toCreateDate:
              searchParam?.createDates && searchParam.createDates[1]
                ? moment(searchParam.createDates[1]).format("YYYY-MM-DD")
                : undefined,
            fromUpdateDate:
              searchParam?.updateDates && searchParam.updateDates[0]
                ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD")
                : undefined,
            toUpdateDate:
              searchParam?.updateDates && searchParam.updateDates[1]
                ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD")
                : undefined,
          }
        : "",
      page: newPage - 1,
      size: pageSizeRef.current,
    });
    // triggerCountDataInfoInit({
    //     searchParam: searchParam ? {
    //         ...searchParam,
    //         createDates: undefined,
    //         updateDates: undefined,
    //         typeId: searchParam.typeId ? searchParam.typeId : undefined,
    //         fileName: searchParam.fileName ? searchParam.fileName : undefined,
    //         status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
    //         isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
    //         fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
    //         toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
    //         fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
    //         toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
    //     } : "",
    //     page: newPage - 1,
    //     size: pageSizeRef.current
    // })
    navigate(".", {
      replace: true,
      state: { searchParam, page: newPage, size: pageSize },
    });
  };

  const toggleModal = () => {
    setCreateCampaignVisible(!createCampaignVisible);
  };

  const createCampaignSuccess = () => {
    toggleModal();
    getData(false);
    triggerCampaign();
  };

  // const checkPermission = (path: string) => {
  //     if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
  //     return false;
  // }

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        {/* <Col span={6}>
                </Col> */}
        <div>
          Tổng số:{" "}
          <strong>{responseDataInfoInit?.data?.totalElements || 0}</strong> bản
          ghi
        </div>
        <div className="search-box-button">
          <Button htmlType="button" type="primary">
            Xuất excel
          </Button>
          {/* {(canAddCampaign) ? <Button
                        disabled={!responseDataInfoInit?.data?.totalElements}
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Tạo chiến dịch</Button> : <></>} */}
        </div>
      </Row>
      <Table<DataListTable>
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => openDetailModal(event, record), // click row
            onDoubleClick: (event) => openDetailModal(event, record), // double click row
          };
        }}
        loading={responseDataInfoInit?.isFetching}
        dataSource={tableData}
        columns={columns.filter((item) => {
          // Ẩn một số cột theo role user
          if (
            (item.key === "groupUsers" && user?.user?.role !== "ADMIN") ||
            (item.key === "createBy" && user?.user?.role === "MEMBER")
          ) {
            return false;
          }

          return true;
        })}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <Spin spinning={responseDataInfoInit?.isFetching} tip="Đang tải...">
        <Pagination
          total={responseDataInfoInit?.data?.totalElements || 0}
          defaultPageSize={pageSizeRef.current}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={page}
        />
      </Spin>

      {createCampaignVisible ? (
        <CreateCampaign
          searchParam={searchParam}
          visible={createCampaignVisible}
          toggleModal={toggleModal}
          createCampaignSuccess={createCampaignSuccess}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default RegisterInfoList;
