import { CheckOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import {
  Col,
  Dropdown,
  FormInstance,
  MenuProps,
  notification,
  Pagination,
  Row,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Manipulation } from "../../../assets/logos/action.svg";
import { STATUS_ENTRY_CAMPAIGN } from "../../../assets/ts/constant";
import {
  useLazyGetCampaignQuery,
  useUpdateCampaignStatusMutation,
} from "../redux/campaignApi";
import CreateCampaign from "./CreateDataEntryCampaign";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
interface DataListTable {
  key: string;
  index: number;
  createBy?: string;
  createdDate?: string;
  campaignName: string;
  number: string;
  campaignType: string;
  description: string;
  isDefault?: any;
  type: string;
  percentComplete: string;
  totalTHHTError?: string;
  listGroupUsers: string;
  status: number;
}

type Props = {
  form: FormInstance<any>;
  searchParam: any;
};

const CampaignList = ({ form, searchParam }: Props) => {
  const [triggerCampaign, responseDataInfo] = useLazyGetCampaignQuery();
  const [triggerStatusCampaign, { isLoading: isLoadingUpdate }] =
    useUpdateCampaignStatusMutation();
  const [tableData, setTableData] = useState<DataListTable[]>();
  const [page, setPage] = useState(1);
  const [currentCampaign, setCurrentCampaign] = useState<DataListTable>();
  const pageSizeRef = useRef(10);
  // const navigate = useNavigate();
  const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
  const [updateCampaignVisible, setUpdateCampaignVisible] =
    useState<boolean>(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  useEffect(() => {
    if (responseDataInfo?.data?.content?.length) {
      let newTableData: DataListTable[] = [];
      responseDataInfo.data.content.forEach((item, index) => {
        newTableData.push({
          key: item?.id,
          index: index + 1,
          createBy: item?.createBy,
          createdDate: item?.createdDate
            ? moment(item.createdDate).format("HH:mm DD/MM/YYYY")
            : "--",
          campaignName: item?.campaignName || "--",
          description: item?.description || "--",
          // number: item.number && item.number > 0 ? (item.number + "") : (item.percent + "%"),
          number: (item.totalTHHTComplete ?? 0) + "/" + (item.totalTHHT ?? 0),
          campaignType:
            item.number && item.number > 0
              ? "Phân công tuyệt đối"
              : "Phân công tương đối",
          type: item.type ? "Dữ liệu nhập liệu" : "Dữ liệu excel",
          isDefault: item.isDefault ? (
            <CheckOutlined style={{ color: "#49cc90" }} />
          ) : (
            <></>
          ),
          percentComplete:
            item.percentComplete == null ? "0%" : item.percentComplete + "%",
          totalTHHTError: item.totalTHHTError ?? "0",
          listGroupUsers:
            item?.listGroupUsers?.length > 0
              ? item?.listGroupUsers?.join(", ")
              : "--",
          status: item.status || 0,
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
    }
  }, [responseDataInfo]);

  const onClickItem = (item: DataListTable) => {
    setCurrentCampaign(item);
    setUpdateCampaignVisible(true);
  };

  const resetState = () => {
    setUpdateCampaignVisible(false);
    setCurrentCampaign(undefined);
  };

  const handleActivate = () => {
    if (currentCampaign?.key) {
      triggerStatusCampaign({
        campaignId: currentCampaign?.key,
        status:
          currentCampaign?.status === STATUS_ENTRY_CAMPAIGN.DEACTIVE
            ? STATUS_ENTRY_CAMPAIGN.ACTIVE
            : STATUS_ENTRY_CAMPAIGN.DEACTIVE,
      })
        .unwrap()
        .then(() => {
          notification.success({
            message: `${
              currentCampaign?.status === STATUS_ENTRY_CAMPAIGN.ACTIVE
                ? "Khóa"
                : "Kích hoạt"
            } chiến dịch thành công!`,
          });
          resetState();
          getData();
        })
        .catch(() => {
          notification.error({ message: "Có lỗi xảy ra!" });
        });
    }
  };

  const getContent = (
    value: number,
    record: DataListTable
  ): MenuProps["items"] => {
    return [
      {
        key: "lock",
        label: (
          <>
            {record.status === STATUS_ENTRY_CAMPAIGN.ACTIVE ? (
              <>
                <LockOutlined style={{ marginRight: "4px" }} />
                Khóa
              </>
            ) : (
              <>
                <UnlockOutlined style={{ marginRight: "4px" }} />
                Kích hoạt
              </>
            )}
          </>
        ),
        onClick: (info) => {
          info.domEvent.stopPropagation();
          onClickItem(record);
        },
      },
    ];
  };

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, record, index) =>
        (page - 1) * pageSizeRef.current + index + 1,
      width: 70,
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value, record) => {
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: getContent(value, record) }}
          >
            <Manipulation
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
        );
      },
    },
    // {
    //     title: "Mặc định",
    //     dataIndex: "isDefault",
    //     key: "isDefault",
    //     align: "center",
    // },
    // {
    //     title: "Nguồn dữ liệu",
    //     dataIndex: "type",
    //     key: "type",
    // },
    {
      title: "Tên chiến dịch",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (value, record, index) => (
        <span
          style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }}
          onClick={(e) => viewDetailCampaign(record)}
        >
          {record.campaignName}
        </span>
      ),
    },
    {
      title: "Số lượng THHT",
      dataIndex: "number",
      key: "number",
    },
    // {
    //     title: "Loại chiến dịch",
    //     dataIndex: "campaignType",
    //     key: "campaignType",
    // },
    {
      title: "Phần trăm hoàn thành",
      dataIndex: "percentComplete",
      key: "percentComplete",
    },
    {
      title: "Số THHT lỗi",
      dataIndex: "totalTHHTError",
      key: "totalTHHTError",
    },
    {
      title: "Nhóm người dùng",
      dataIndex: "listGroupUsers",
      key: "listGroupUsers",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value, record, index) => (
        <>
          {value === STATUS_ENTRY_CAMPAIGN.ACTIVE
            ? "Hoạt động"
            : "Không hoạt động"}
        </>
      ),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Người tạo",
    //   dataIndex: "createBy",
    //   key: "createBy",
    // },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
    },
  ];

  const getData = () => {
    triggerCampaign({
      searchParam,
      page: 0,
      size: pageSizeRef.current,
    });
    setPage(1);
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    setPage(newPage);
    pageSizeRef.current = pageSize;
    triggerCampaign({
      searchParam: searchParam
        ? {
            ...searchParam,
            isCheck: searchParam.isCheck
              ? searchParam.isCheck === 1
                ? true
                : false
              : undefined,
          }
        : "",
      page: newPage - 1,
      size: pageSizeRef.current,
    });
  };

  const toggleModal = () => {
    if (createCampaignVisible) {
      setCurrentCampaign(undefined);
    }
    setCreateCampaignVisible(!createCampaignVisible);
  };

  const createCampaignSuccess = () => {
    toggleModal();
    getData();
  };

  // const viewCampaigns = (e: any, record: DataListTable) => {
  //     e.stopPropagation();
  //     navigate(`/data/list`, { replace: true, state: { dataCampaignId: record.key } });
  // }

  const viewDetailCampaign = (current: DataListTable) => {
    console.log(current);
    setCurrentCampaign(current);
    toggleModal();
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <div>
          Tổng số: <strong>{responseDataInfo?.data?.totalElements ?? 0}</strong>{" "}
          bản ghi
        </div>
        <Col span={6}></Col>
        {/* <div className='search-box-button'>
                    <Button
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Tạo chiến dịch</Button>
                </div> */}
      </Row>
      <Table<DataListTable>
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => viewDetailCampaign(record), // click row
          };
        }}
        loading={responseDataInfo?.isFetching}
        dataSource={tableData}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <Pagination
        total={responseDataInfo?.data?.totalElements || 0}
        defaultPageSize={pageSizeRef.current}
        defaultCurrent={1}
        locale={{ items_per_page: " dòng" }}
        pageSizeOptions={[10, 20, 50, 100]}
        onChange={changePage}
        showSizeChanger
        current={page}
      />
      {createCampaignVisible ? (
        <CreateCampaign
          currentCampaign={currentCampaign}
          visible={createCampaignVisible}
          toggleModal={toggleModal}
          createCampaignSuccess={createCampaignSuccess}
        />
      ) : (
        <></>
      )}

      {updateCampaignVisible && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Đóng"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={
            currentCampaign?.status === STATUS_ENTRY_CAMPAIGN.ACTIVE
              ? "warning"
              : "success"
          }
          centered
          title={`${
            currentCampaign?.status === STATUS_ENTRY_CAMPAIGN.ACTIVE
              ? "Khóa"
              : "Kích hoạt"
          } chiến dịch`}
          content={`Bạn có chắc chắn muốn ${
            currentCampaign?.status === STATUS_ENTRY_CAMPAIGN.ACTIVE
              ? "khóa"
              : "kích hoạt"
          } chiến dịch này hay không?`}
          visible={updateCampaignVisible}
          onOk={handleActivate}
          loadingBtnOk={isLoadingUpdate}
        />
      )}
    </div>
  );
};

export default CampaignList;
