import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';
import moment from 'moment';
import { CommonListQuery, } from '../../../../redux/api/apiTypes';

export const cacTHHTErrorReportApi = createApi({
    reducerPath: 'cacTHHTErrorReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/reports",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getReport: builder.query<any, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/report-cac-truong-hop-loi?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}&sort=createdDate,desc`,
                method: 'GET',
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getReportTotal: builder.query<number, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/report-cac-truong-hop-loi-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}&sort=createdDate,desc`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        exportData: builder.mutation<any, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/performance/export`,
                method: 'POST',
                params: {
                    sort_type: 0
                },
                body: {
                    accounts: body?.searchParam?.userIds,
                    book_types: body?.searchParam?.typeId,
                    provinces: body?.searchParam?.provinceId,
                    communes: body?.searchParam?.communeId,
                    districts: body?.searchParam?.districtId,
                    from_date: body?.searchParam?.dates && body.searchParam.dates[0] ? moment(body.searchParam.dates[0]).format("YYYY-MM-DD") : undefined,
                    to_date: body?.searchParam?.dates && body.searchParam.dates[1] ? moment(body.searchParam.dates[1]).format("YYYY-MM-DD") : undefined
                },
                responseHandler: async (response) => {
                    let filename = `${format(new Date(), "dd-MM-yyyy")}_Báo cáo hiệu năng.xlsx`;
                    let blob = await response.blob();
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    link.click();
                },
                cache: "no-cache",
            }),
        }),
    })
});

export const {
    useLazyGetReportQuery,
    useLazyGetReportTotalQuery,
    useExportDataMutation,
} = cacTHHTErrorReportApi;

export default cacTHHTErrorReportApi;
