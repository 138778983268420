import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../modules/login/Index';
import LayoutApp from '../layouts/Layout';
import Home from '../modules/home/Index';
import PrivateRoute from './PrivateRoute';
import ChangePassword from '../modules/account/change-password/Index';
import DataList from '../modules/data/index';
import PerformReport from '../modules/dashboard/perform-report/Index';
import Campaign from '../modules/campaign/Index';
import DetailDataInfo from '../modules/data/components/DetailDataInfo';
import ImportData from '../modules/data/components/ImportData';
import CreateDataInfoV2 from '../modules/data_init/components/CreateDataInfoVer2';
import CreateDataInfoV3 from '../modules/data_init/components/CreateDataInfoResize';
import CreateDataInfo from '../modules/data_init/components/CreateDataInfo';
import CreateDataInfoLack from '../modules/data_init/components/CreateDataInfoLack';
import CheckDataInfo from '../modules/data/components/CheckDataInfo';
import CheckData from '../modules/check-data/components/CheckDataInfo';
import FieldReport from '../modules/dashboard/field-report/Index';
import EmplReport from '../modules/dashboard/employee-report/Index';
// import CheckedReport from '../modules/info-init-report/checked-report';
import FilePdf from '../modules/file-pdf/Index';
import DataInit from '../modules/data_init/index';
import FieldConfig from '../modules/field_config/Index';
import DetailDataInfoInit from '../modules/data_init/components/DetailDataInfoInit';
import DetailDataInfoInitLack from '../modules/data_init/components/DetailDataInfoInitLack';
import InfoInitImportReport from '../modules/info-init-report/import-report/Index';
import InfoInitPerformReport from '../modules/info-init-report/perform-report/Index';
import InfoInitCheckedReport from '../modules/info-init-report/checked-report/Index';
import DetailFilePdf from '../modules/file-pdf/components/DetailFilePdf';
import DataInitHistory from '../modules/data_init_history';
import Province from '../modules/category/province/Index';
import Commune from '../modules/category/commune/Index';
import District from '../modules/category/district/Index';
import DetailDataInfoInitHistory from '../modules/data_init_history/components/DetailDataInfoInitHistory';
import User from '../modules/user/Index';
import Permissions from '../modules/permissions/Index';
import BoxData from '../modules/box-Data/Index';
import DataEntryCampaign from '../modules/data_entry_campaign/Index';
import CreateUser from '../modules/user/components/CreateUser';
import DataSync from '../modules/data-sync/components';
import { PERMISSION_KEYS } from '../modules/permissions/ts/Contants';
import { checkPermission } from '../modules/common/assets/CommonFunctions';
import RegisterSync from '../modules/data-sync-list';
import TienDoKiemTraReport from "../modules/dashboard/tien-do-kiem-tra/Index";
import NangXuatNhapLieuReport from '../modules/dashboard/nang-xuat-nhap-lieu/Index';
import UploadDuLieuReport from '../modules/dashboard/upload-du-lieu/Index';
import NangXuatKiemTraReport from '../modules/dashboard/nang-xuat-kiem-tra/Index';
import TienDoNhapLieuReport from '../modules/dashboard/tien-do-nhap-lieu/Index';
import CacTruongHopLoiReport from '../modules/dashboard/cac-truong-hop-loi/Index';
import NhanSuBaoLoiReport from '../modules/dashboard/theo-doi-nhan-su-bao-loi/Index';
import ThoiGianThaoTacNhanSuReport from '../modules/dashboard/theo-doi-thoi-gian-thao-tac-nhan-su/Index';
import GroupUser from "../modules/group-user/Index";
import HistoryAccess from "../modules/access-history/Index";
import DuLieuKhaiSinhData from '../modules/du_lieu_ho_tich/du_lieu_khai_sinh/Index';
import DuLieuKhaiTuData from '../modules/du_lieu_ho_tich/du_lieu_khai_tu/Index';
import DuLieuKetHonData from '../modules/du_lieu_ho_tich/du_lieu_ket_hon/Index';
import DuLieuHonNhanData from '../modules/du_lieu_ho_tich/du_lieu_hon_nhan/Index';
import DuLieuCMCData from '../modules/du_lieu_ho_tich/du_lieu_CMC/Index';
import DuLieuGiamHoData from '../modules/du_lieu_ho_tich/du_lieu_giam_ho/Index';
import DuLieuCDGHData from '../modules/du_lieu_ho_tich/du_lieu_CDGH/Index';
import DuLieuLyHonData from '../modules/du_lieu_ho_tich/du_lieu_ly_hon/Index';
import DuLieuCaiChinhData from '../modules/du_lieu_ho_tich/du_lieu_cai_chinh/Index';
import DuLieuConNuoiData from '../modules/du_lieu_ho_tich/du_lieu_con_nuoi/Index';
import CompareData from '../modules/compare-data/Index';
import DetailDataCompare from '../modules/compare-data/components/DetailDataCompare';

import RecheckCampaign from '../modules/recheck-campaign/Index';
import DetailDataRecheckFile from '../modules/compare-data/components/DetailDataRecheckFile';
import DetailCheckData from '../modules/data_init_history/components/DetailCheckData';
// import UploadDuLieuPDFReport from '../modules/dashboard/upload-du-lieu-pdf/Index';

const IndexRoutes = () => {
    // const user = useAppSelector((state) => state.user);
    // const checkPermission = (path: string) => {
    //     if (user.user?.username !== "admin" || (user?.user?.authorities || []).indexOf("ROLE_SYSTEM_DATAINFO") !== -1) return true;
    //     return false;
    // }

    // const checkRoleCheck = () => {
    //     if (user.user?.username === "admin" || (user.user?.username || "").toLowerCase().includes("check")) return true;
    //     return false;
    // }

    return (
        <>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path="/" element={<PrivateRoute><LayoutApp /></PrivateRoute>}>
                    <Route path='' element={<PrivateRoute><Home /></PrivateRoute>} />
                    {/* account */}
                    {/* <Route path='account/detail' element={<PrivateRoute><AccountDetail /></PrivateRoute>} /> */}
                    <Route path='account/change-password' element={<PrivateRoute><ChangePassword /></PrivateRoute>} />

                    {/* Chiến dịch kiểm tra*/}
                    {/* <Route path='data/campaign' element={<PrivateRoute hasPermissions={checkPermission("data/campaign") && user.user.username === "admin"}><Campaign /></PrivateRoute>} /> */}
                    <Route path='data/campaign' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.CHIEN_DICH_KIEM_TRA.DANH_SACH)}><Campaign /></PrivateRoute>} />

                    {/* Chiến dịch nhập liệu*/}
                    {/* <Route path='data/data-entry-campaign' element={<PrivateRoute hasPermissions={checkPermission("data/data-entry-campaign") && user.user.username === "admin"}><DataEntryCampaign /></PrivateRoute>} /> */}
                    <Route path='data/data-entry-campaign' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.CHIEN_DICH_NHAP_LIEU.DANH_SACH)}><DataEntryCampaign /></PrivateRoute>} />

                    {/* Cấu hình trường thông tin */}
                    <Route path='data/field' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.THAM_SO_HE_THONG.DANH_SACH)}><FieldConfig /></PrivateRoute>} />

                    {/* Danh sách file pdf */}
                    <Route path='data/file' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.DANH_SACH)}><FilePdf /></PrivateRoute>} />
                    {/* <Route path='data/file/detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.DANH_SACH)}><DetailFilePdf/></PrivateRoute>} /> */}

                    {/* Data */}
                    <Route path='data/list' element={<PrivateRoute hasPermissions={checkPermission("data/list")}><DataList /></PrivateRoute>} />
                    <Route path='data/detail/:id' element={<PrivateRoute hasPermissions={checkPermission("data/detail")}><DetailDataInfo /></PrivateRoute>} />
                    <Route path='data/check' element={<PrivateRoute><CheckDataInfo /></PrivateRoute>} />
                    {/* kiem tra du lieu */}
                    <Route path='data/check-data' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KIEM_TRA_DU_LIEU.DANH_SACH)}><CheckData/></PrivateRoute>} />
                    <Route path='data/import' element={<PrivateRoute><ImportData /></PrivateRoute>} />
                    {/* Đồng bộ dữ liệu */}
                    {/* <Route path='data/sync-data/:id' element={<PrivateRoute  hasPermissions={checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.ADD)}><DataSync /></PrivateRoute>} /> */}
                    {/* <Route path='data/sync-data' element={<PrivateRoute  hasPermissions={checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.ADD)}><DataSync/></PrivateRoute>} /> */}

                    {/* Nhập liệu dữ liệu */}
                    {/* <Route path='info_init/createLack' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.ADD_BO_SUNG)}><CreateDataInfoLack/></PrivateRoute>} /> */}
                    <Route path='info_init/create' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.THEM_MOI)}><CreateDataInfo /></PrivateRoute>} />
                    <Route path='info_init/createv2' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.THEM_MOI)}><CreateDataInfoV2 /></PrivateRoute>} />
                    <Route path='info_init/createv3' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.THEM_MOI)}><CreateDataInfoV3 /></PrivateRoute>} />


                    {/* Đối chiếu dữ liệu */}
                    <Route path='data/recheck' 
                        element={
                        <PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DOI_CHIEU_DU_LIEU.VIEW)}>
                            <DetailDataRecheckFile />
                        </PrivateRoute>
                        }
                    />
                    
                    <Route path='data/recheck-list' 
                        element={
                        <PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_DOI_CHIEU.VIEW)}>
                            <CompareData/>
                        </PrivateRoute>
                        }
                    />

                    <Route path='data/recheck/detail/:id' 
                        element={
                        <PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_DOI_CHIEU.VIEW)}>
                            <DetailDataCompare/>
                        </PrivateRoute>
                        } 
                    />
                    {/* Danh sách chiến dịch đối chiếu */}
                    <Route path='data/recheck-campaign' 
                        element={
                        <PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.CHIEN_DICH_DOI_CHIEU.VIEW)}>
                            <RecheckCampaign/>
                        </PrivateRoute>
                        }
                    />

                    {/* Kho du lieu nhap lie */}
                    <Route path='data/info_init' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH)}><DataInit /></PrivateRoute>} />
                    <Route path='data/info_init_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH)}><DetailDataInfoInit/></PrivateRoute>} />
                    <Route path='data/lack/info_init_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH)}><DetailDataInfoInitLack /></PrivateRoute>} />

                    {/* Lịch sử dữ liệu nhập liệu / kho du lieu kiem tra */}
                    <Route path='data/info_init_history' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_KIEM_TRA.DANH_SACH)}><DataInitHistory /></PrivateRoute>} />
                    <Route path='data/info_init_history_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_KIEM_TRA.DANH_SACH)}><DetailCheckData/></PrivateRoute>} />
                    {/* <Route path='data/info_init_history_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_KIEM_TRA.DANH_SACH)}><DetailDataInfoInitHistory/></PrivateRoute>} /> */}



                    {/* Báo cáo dữ liệu import */}
                    <Route path='report/perform' element={<PrivateRoute hasPermissions={checkPermission("report/perform")}><PerformReport /></PrivateRoute>} />
                    <Route path='report/field' element={<PrivateRoute hasPermissions={checkPermission("report/field")}><FieldReport /></PrivateRoute>} />
                    <Route path='/report/empl' element={<PrivateRoute hasPermissions={checkPermission("/report/empl")}><EmplReport /></PrivateRoute>} />
                    {/* <Route path='/report/tien-do' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><TienDoKiemTraReport /></PrivateRoute>} /> */}
                    <Route path='/report/nang-suat-nhap-lieu' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><NangXuatNhapLieuReport /></PrivateRoute>} />
                    <Route path='/report/upload' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><UploadDuLieuReport /></PrivateRoute>} />
                    {/* <Route path='/report/upload-Excel' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><UploadDuLieuReport /></PrivateRoute>} /> */}
                    {/* <Route path='/report/upload-PDF' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><UploadDuLieuPDFReport /></PrivateRoute>} /> */}
                    <Route path='/report/nang-suat-kiem-tra' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><NangXuatKiemTraReport /></PrivateRoute>} />
                    <Route path='/report/tien-do-nhap-lieu' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><TienDoNhapLieuReport /></PrivateRoute>} />
                    {/* <Route path='/report/cac-truong-hop-loi' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><CacTruongHopLoiReport /></PrivateRoute>} /> */}
                    {/* <Route path='/report/nhan-su-bao-loi' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><NhanSuBaoLoiReport/></PrivateRoute>} /> */}
                    <Route path='/report/thoi-gian-thao-tac-nhan-su' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><ThoiGianThaoTacNhanSuReport /></PrivateRoute>} />

                    {/* Báo cáo dữ liệu nhập liệu */}
                    <Route path='report-data-info-init/import' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_NHAP_LIEU)}><InfoInitImportReport /></PrivateRoute>} />
                    <Route path='report-data-info-init/perform' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_HIEU_NANG_CHINH_SUA)}><InfoInitPerformReport /></PrivateRoute>} />
                    <Route path='report-data-info-init/checked' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><InfoInitCheckedReport /></PrivateRoute>} />
                    {/* <Route path='report-data-info-init/field' element={<PrivateRoute hasPermissions={checkPermission("report-data-info-init/create")}><PerformReport /></PrivateRoute>} />
                    <Route path='report-data-info-init/empl' element={<PrivateRoute hasPermissions={checkPermission("report-data-info-init/create")}><PerformReport /></PrivateRoute>} /> */}


                    {/* Quản lý danh mục */}
                    <Route path='category/province' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_MUC.TINH_THANH.DANH_SACH)}><Province /></PrivateRoute>} />
                    <Route path='category/district' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.DANH_SACH)}><District /></PrivateRoute>} />
                    <Route path='category/commune' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.DANH_SACH)}><Commune /></PrivateRoute>} />


                    {/* Quản trị hệ thống */}
                    <Route path='setting/user' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH)}><User /></PrivateRoute>} />
                    <Route path='setting/user/add' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.THEM_MOI)}><CreateUser /></PrivateRoute>} />
                    <Route path='setting/user/detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH)}><CreateUser /></PrivateRoute>} />

                    <Route path='setting/group-user' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_NGUOI_DUNG.DANH_SACH)}><GroupUser /></PrivateRoute>} />
                    <Route path='/setting/role' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.DANH_SACH)}><Permissions /></PrivateRoute>} />
                    <Route path='/setting/access-history' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.LICH_SU_TRUY_CAP)}><HistoryAccess /></PrivateRoute>} />
                    {/* <Route path='/setting/role/add' element={<PrivateRoute ><AddRole /></PrivateRoute>} /> */}

                    {/* Kho dữ liệu */}
                    <Route path='data/box-data' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU.DANH_SACH)}><BoxData /></PrivateRoute>} />
                    <Route path='data/box-data/detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU.DANH_SACH)}><DetailFilePdf/></PrivateRoute>} />

                    {/*Danh sách đồng bộ dữ liệu*/}
                    <Route path='data/list-sync-data' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.DANH_SACH)}><RegisterSync /></PrivateRoute>} />

                    {/*kho dữ liệu hộ tịch*/}
                    <Route path='/data/du-lieu-khai-sinh' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuKhaiSinhData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-khai-tu' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuKhaiTuData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-ket-hon' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuKetHonData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-hon-nhan' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuHonNhanData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-CMC' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuCMCData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-giam-ho' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuGiamHoData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-CDGH' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuCDGHData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-ly-hon' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuLyHonData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-cai-chinh' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuCaiChinhData /></PrivateRoute>} />
                    <Route path='/data/du-lieu-con-nuoi' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH)}><DuLieuConNuoiData /></PrivateRoute>} />

                    <Route path='*' element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </>
    );
}

export default IndexRoutes;
