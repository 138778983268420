/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, notification, Row, Select, Spin, Collapse, Table, Upload } from "antd";
import {
    useInsertDataInfoInitMutation,
    useLazyGetFieldConfigQuery,
    useLazyGetFilePdfByIdQuery,
    useUpdateStatusFilePdfMutation,
    useLazyGetDataInfoInitByFilePdfQuery,
    useReplaceFileScanMutation
} from "../../data_init/redux/dataInitApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState, useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
// import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
// import ConfirmModal from "../../common/components/ConfirmModal";
// import useResizeObserver from '@react-hook/resize-observer';
// import { FullscreenOutlined, UploadOutlined } from "@ant-design/icons";
import { DEFAULT_FIELD_CONFIG, QN_UBND, TYPE_FILE_PDF } from "../../../assets/ts/constant";
import {
    useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery,
    useLazyGetDistrictQuery, useLazyGetProvinceQuery
} from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
// import { helpers } from "../../../assets/js/helper";
import { FieldConfig } from "../../data_init/redux/apiTypes";
// import { useAppSelector } from "../../../redux/Hooks";
import { ColumnsType } from "antd/es/table";
// import ListDataInfoInitModal from "../../data_init/components/ListDataInfoInitModal";
// import moment from "moment";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PDFPageProxy } from 'react-pdf';
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
// import { DataHearderContext } from "../../../layouts/context/dataHearderProvider";
import SplitPane from "react-split-pane";
import { DataInfoContext } from "../../../layouts/context/DataInfoProvider";
// import { checkPermission } from "../../common/assets/CommonFunctions";
// import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
// import ScrollContainer from 'react-indiana-drag-scroll';

// const { Panel } = Collapse;
// const useWidth = (target: any) => {
//     const [width, setWidth] = useState(300);

//     useLayoutEffect(() => {
//         setWidth(target.current.getBoundingClientRect().width)
//     }, [target]);

//     useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
//     return width;
// };

const DetailFilePdf = () => {
    const [form] = Form.useForm();
    const param = useParams();
    // const navigate = useNavigate();
    // const userState = useAppSelector(state => state.user.user);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [year, setYear] = useState<string>();
    const [displayFlex, setDisplayFlex] = useState(true);
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    // const [insertData, { isLoading }] = useInsertDataInfoInitMutation();
    // const [updateStatusPdf] = useUpdateStatusFilePdfMutation();
    const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();
    // const [confirmVisible, setConfirmVisible] = useState(false);
    // const [confirmSuccessVisible, setConfirmSuccessVisible] = useState(false);
    // const [openFileVisible, setOpenFileVisible] = useState(false);
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [provinceObject, setProvinceObject] = useState();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districtObject, setDistrictObject] = useState();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [communeObject, setCommuneObject] = useState();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetAllCommuneNoAuthQuery();
    // const [districtAllTrigger, responseDistrictAll] = useLazyGetAllDistrictNoAuthQuery();
    const [pdfFileName, setPdfFileName] = useState('');
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const [triggerGetDataInfoInitByFilePdf, dataInfoInitByFilePdfResponse] = useLazyGetDataInfoInitByFilePdfQuery();
    // const [listDataInfoInitVisible, setListDataInfoInitVisible] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const wrapperDiv = useRef<HTMLInputElement>(null);
    // const [replaceFileScan] = useReplaceFileScanMutation();
    // const [loadingFile, setLoadingFile] = useState<boolean>(false);
    const [reloadPdf, setReloadPdf] = useState<boolean>(false)
    // const width = useWidth(wrapperDiv);

    // const { state, dispatchA } = useContext(DataHearderContext);

    const file = useMemo(() => {
        let uri = '';
        if (detailResponse?.data?.id) {
            uri = `/api/v1/file/download-pdf/${detailResponse?.data?.id}`;
        } else if (detailResponse?.data?.excelName && pdfFileName) {
            let excelNames = detailResponse?.data?.excelName?.split("_");
            let excelNames1 = detailResponse?.data?.excelName?.split(".");
            let pathName = `${excelNames?.[1]}/${excelNames?.[0]}/${detailResponse?.data?.typeId}/${excelNames1?.[2]}/${excelNames1?.[3]}/${pdfFileName}`
            uri = `/api/v1/file/download-pdf?dataInfoId=${detailResponse?.data?.id}&pathFile=${pathName}`;
        }
        if (uri) {
            return {
                url: process.env.REACT_APP_API_URL + uri,
                httpHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        }
        //     return {
        //         url: process.env.REACT_APP_API_URL + `/api/v1/file/download-pdf/${detailResponse?.data?.id}`,
        //         httpHeaders: {
        //             Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
        //             "Content-Type": "application/json;charset=UTF-8",
        //         },
        //     }
        // }, [detailResponse?.data?.id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailResponse?.data?.excelName, detailResponse?.data?.id, detailResponse?.data?.typeId, pdfFileName, reloadPdf]);

    // const [initialPos, setInitialPos] = useState(0);
    // const [initialSize, setInitialSize] = useState(0);

    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const canvas1 = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    useEffect(() => {
        if (clientY === 0 || !canvas.current || !isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
        if (canvas1.current) {
            const context1 = canvas1.current.getContext('2d');
            const width1 = canvas1.current.width;
            const rect1 = canvas1.current.getBoundingClientRect();
            context1.save();
            context1.beginPath();
            context1.moveTo(0, clientY);
            context1.lineTo(width1, clientY);
            context1.strokeStyle = 'red';
            context1.stroke();
            context1.restore();
        }
    }, [clientY, isRendered]);
    useEffect(() => {
        if (prevScale.current === scale) {
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
            setIsRendered(false);
        }
    }, [scale])
    const onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if (!isDrag) {
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top) * scaleY);
        }
    }

    useEffect(() => {
        triggerFilePdfId(param?.id);
    }, [triggerFilePdfId, param?.id]);

    // const initial = (e: any) => {
    //     let resizable = document.getElementById('block-view-pdf');
    //     setInitialPos(e.clientX);
    //     setInitialSize(resizable?.offsetWidth || 0);
    // }


    useEffect(() => {
        const slider = document.getElementById("block-view-pdf-image");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
                setIsDrag(false);
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                setIsDrag(true);
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
                setIsDrag(true);
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            // setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
            // setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);

            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 1.4|| undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 1.4 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    // const resize = (e: any) => {
    //     let resizable = document.getElementById('block-view-pdf');
    //     if (resizable) {
    //         resizable.style.width = `${initialSize + e.clientX - initialPos}px`;
    //     }
    // }

    // useEffect(() => {
    //     let typeFile = detailResponse?.data?.fileName?.split(".")?.[0];
    //     if (typeFile && !detailResponse.isFetching) {
    //         triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) });
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [triggerGetFieldConfig, detailResponse?.data?.fileName]);

    // useEffect(() => {
    //     triggerProvince({}).unwrap().then(res => {
    //         setProvinceObject(res?.reduce((a: any, v: any) => ({ ...a, [DEFAULT_FIELD_CONFIG.tinhThanh]: v.id }), {}));
    //     });
    //     communeAllTrigger({}).unwrap().then(res => {
    //         setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
    //     });
    //     districtAllTrigger({}).unwrap().then(res => {
    //         setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
    //     });
    // }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

    // useEffect(() => {
    //     if (fieldConfigResponse?.data?.fieldKeyMap?.length &&
    //         detailResponse?.data?.id &&
    //         provinceObject &&
    //         districtObject &&
    //         responseCommuneAll?.data
    //     ) {
    //         setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
    //         triggerGetDataInfoInitByFilePdf(detailResponse?.data?.id);
    //     } else {
    //         setKeyMaps([]);
    //     }
    // }, [fieldConfigResponse?.data?.type, fieldConfigResponse?.data?.year, detailResponse?.data?.id, provinceObject, districtObject, responseCommuneAll?.data]);
    // useEffect(() => {
    //     if (dataInfoInitByFilePdfResponse?.data?.length && columns?.length) {
    //         setTableData(dataInfoInitByFilePdfResponse?.data?.map(item => {
    //             return {
    //                 key: item.id,
    //                 ...item.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataKeyAscii === "quyenSo" ? <Link to={`/data/info_init_detail/${item.id}`}>{v.dataValue || ""}</Link> : v.dataValue }), {})
    //             }
    //         }));
    //     } else {
    //         setTableData([]);
    //     }
    // }, [dataInfoInitByFilePdfResponse, columns]);

    // const convertYearToTypePdf = (typeFile: string) => {
    //     // KS.1994.01.--.001.PDF
    //     let yearStr = detailResponse?.data?.fileName?.split(".")?.[1];
    //     let yearNum = yearStr ? parseInt(yearStr) : 0;
    //     // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
    //     // Chuyển từ năm sang file loại file: A3, A4
    //     if (typeFile === "KH" && yearNum === 2007 && detailResponse?.data?.communeCode === "xatinhantay") {
    //         return TYPE_FILE_PDF.A3;
    //     }
    //     if (yearNum > 2006) {
    //         if (typeFile === "KH" &&
    //             detailResponse?.data?.communeCode === "sotuphap" &&
    //             detailResponse?.data?.districtCode === "thanhphoquangngai") {
    //             if (yearNum < 2011) {
    //                 setYear(TYPE_FILE_PDF.A3);
    //                 return TYPE_FILE_PDF.A3;
    //             }
    //         }
    //         setYear(TYPE_FILE_PDF.A4);
    //         return TYPE_FILE_PDF.A4;
    //     }
    //     if (yearNum > 1977) {
    //         setYear(TYPE_FILE_PDF.A3);
    //         return TYPE_FILE_PDF.A3;
    //     }
    //     switch (typeFile) {
    //         case "KS":
    //             setYear(TYPE_FILE_PDF.A4);
    //             return TYPE_FILE_PDF.A4
    //         default:
    //             setYear(TYPE_FILE_PDF.A4);
    //             return TYPE_FILE_PDF.A4;
    //     }
    // }

    // const onFinish = (values: any) => {
    //     form.validateFields().then(res => {
    //         toggleConfirmModal();
    //     }).catch(err => {
    //         notification.error({
    //             message: err?.errorFields?.[0].name?.[0] &&
    //                 err?.errorFields?.[0].errors?.[0] ?
    //                 `${err?.errorFields?.[0].name?.[0]}: ${err?.errorFields?.[0].errors?.[0]}!`
    //                 : "Có lỗi nhập liệu"
    //         });
    //     })
    // }

    // const onSubmitCheckData = (isCheck?: Boolean) => {
    //     let keyMapsObj = keyMaps.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})
    //     if (fieldConfigResponse?.data?.type &&
    //         keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh] &&
    //         keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen] &&
    //         keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa]) {
    //         insertData({
    //             keyMaps: [keyMaps],
    //             file_pdf_id: detailResponse?.data?.id,
    //             isCheck: isCheck || false,
    //             typeId: fieldConfigResponse?.data?.type,
    //             communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
    //             districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
    //             provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
    //         }).unwrap()
    //             .then((res: any) => {
    //                 let oldData = form.getFieldsValue();
    //                 notification.success({ message: "Nhập liệu thành công" });
    //                 resetData();
    //                 setConfirmVisible(false);
    //                 // TODO: Không thấy call api chỗ này
    //                 triggerFilePdfId(detailResponse?.data?.id);
    //                 setInitData(fieldConfigResponse?.data?.fieldKeyMap, oldData);
    //                 if (detailResponse?.data?.id) {
    //                     triggerGetDataInfoInitByFilePdf(detailResponse?.data?.id);
    //                 }
    //             }).catch((err: any) => {
    //                 notification.error({ message: err.data?.message || "Cập nhật thất bại" });
    //             })
    //     } else {
    //         notification.error({ message: "Có lỗi nhập liệu!" });
    //     }
    // }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    // const toggleConfirmModal = () => {
    //     setConfirmVisible(!confirmVisible);
    // }

    // const toggleConfirmSuccessModal = () => {
    //     setConfirmSuccessVisible(!confirmSuccessVisible);
    // }

    // const toggleOpenFileModal = () => {
    //     setOpenFileVisible(!openFileVisible);
    // }

    // const toggleListDataInfoInitModal = () => {
    //     setListDataInfoInitVisible(!listDataInfoInitVisible);
    // }

    // const setInitData = (dataKeyMaps: any, oldData?: any) => {
    //     // TODO: Thêm trường dữ liệu mặc định trong cấu hình trường dữ liệu
    //     if (dataKeyMaps?.length) {
    //         setKeyMaps(dataKeyMaps.map((item: any, index: number) => {
    //             let newItem = setDataItem(item, oldData);
    //             if (newItem) {
    //                 return newItem;
    //             } else if (item.defaultValue) {
    //                 form.setFieldsValue({
    //                     [item.dataKeyAscii]: item.defaultValue
    //                 })
    //                 return {
    //                     ...item,
    //                     dataValue: item.defaultValue
    //                 }
    //             }
    //             return item;
    //         }))
    //     }
    //     setColumns([
    //         ...(fieldConfigResponse?.data?.fieldKeyMap || [])
    //             .filter((x: any) =>
    //                 // x.isDefault &&
    //                 x.isEdit &&
    //                 x.dataKeyAscii !== "tinhThanh" &&
    //                 x.dataKeyAscii !== "quanHuyen" &&
    //                 x.dataKeyAscii !== "phuongXa"
    //             )
    //             .map((item: any, index: number) => {
    //                 return {
    //                     title: item.dataKey,
    //                     dataIndex: item.dataKeyAscii,
    //                     key: item.dataKeyAscii,
    //                     align: "center",
    //                     width: 250,
    //                 }
    //             })
    //     ]);
    // }

    // Set dữ liệu ban đầu
    // const setDataItem = (item: FieldConfig, oldData?: any) => {
    //     if (item.dataKeyAscii) {
    //         switch (item.dataKeyAscii) {
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
    //                 districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh]).unwrap().then(res => {
    //                     setDistricts(res);
    //                 });
    //                 if (item.dataKeyAscii) {
    //                     form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] });
    //                 }
    //                 return {
    //                     ...item,
    //                     dataValue: oldData?.[item.dataKeyAscii] || provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] || "",
    //                 }
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
    //                 if (detailResponse?.data?.districtCode) {
    //                     communeTrigger(districtObject?.[detailResponse?.data?.districtCode]).unwrap().then(res => {
    //                         setCommunes(res);
    //                     });
    //                     if (item.dataKeyAscii) {
    //                         form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || districtObject?.[detailResponse?.data?.districtCode] });
    //                     }
    //                     return {
    //                         ...item,
    //                         dataValue: oldData?.[item.dataKeyAscii] || districtObject?.[detailResponse?.data?.districtCode] || "",
    //                     }
    //                 }
    //                 return null;
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
    //                 if (detailResponse?.data?.communeCode && detailResponse?.data?.districtCode) {
    //                     let newCommuneObject = responseCommuneAll?.data?.filter((item) => detailResponse?.data?.districtCode && item.districtId === districtObject?.[detailResponse?.data?.districtCode])?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {});
    //                     setCommuneObject(newCommuneObject);
    //                     if (item.dataKeyAscii) {
    //                         form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || newCommuneObject?.[detailResponse?.data?.communeCode] });
    //                     }
    //                     return {
    //                         ...item,
    //                         dataValue: oldData?.[item.dataKeyAscii] || newCommuneObject?.[detailResponse?.data?.communeCode] || "",
    //                     }
    //                 }
    //                 return null;
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo:
    //                 let pdfFileNames = detailResponse?.data?.fileName?.split('.');
    //                 let quyenSo = `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}`;
    //                 if (item.dataKeyAscii) {
    //                     form.setFieldsValue({ [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || quyenSo });
    //                 }
    //                 return {
    //                     ...item,
    //                     dataValue: oldData?.[item.dataKeyAscii] || `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}` || "",
    //                 }
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf:
    //                 let pdfFileNames1 = detailResponse?.data?.fileName?.split('.');
    //                 let type = detailResponse?.data?.fileName?.split(".")?.[0];
    //                 let tenFilePdf = `${type}.${pdfFileNames1?.[1] || '--'}.${pdfFileNames1?.[2] || '--'}.--..pdf`;
    //                 form.setFieldsValue({
    //                     [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]: tenFilePdf,
    //                 })
    //                 setPdfFileName(tenFilePdf);
    //                 return {
    //                     ...item,
    //                     dataValue: tenFilePdf,
    //                 }
    //             case DEFAULT_FIELD_CONFIG.noiDangKy:
    //             case DEFAULT_FIELD_CONFIG.noiCap:
    //                 if (detailResponse?.data?.districtCode && detailResponse?.data?.communeCode && item?.data?.length) {
    //                     let key = `${DEFAULT_FIELD_CONFIG.tinhThanh}-${detailResponse?.data?.districtCode}-${detailResponse?.data?.communeCode}`;
    //                     form.setFieldsValue({
    //                         [item.dataKeyAscii]: QN_UBND[key],
    //                     });
    //                     return {
    //                         ...item,
    //                         dataValue: QN_UBND[key],
    //                     }
    //                 }
    //         }
    //     }
    //     return null
    // }


    // const changeData = async (value: any, index: number, item: FieldConfig) => {
    //     // Nếu không cho sửa thì không được sửa
    //     if (!item.isEdit) return;
    //     if (item?.dataKeyAscii) {
    //         form.setFieldsValue({ [item.dataKeyAscii]: value });
    //     }
    //     switch (item.dataKeyAscii) {
    //         case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
    //             form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined })
    //             setDistricts([]);
    //             setCommunes([]);
    //             districtTrigger(value).unwrap()
    //                 .then((res: any) => setDistricts(res));
    //             break;
    //         case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
    //             form.setFieldsValue({ phuongXa: undefined })
    //             setCommunes([]);
    //             communeTrigger(value).unwrap()
    //                 .then(res => setCommunes(res));
    //             break;
    //     }
    //     // KS.2007.01.2007-07-11.082.pdf
    //     let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ||
    //         item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ||
    //         item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy;
    //     let tenFilePdf = "";
    //     if (changeFilePdf) {
    //         let type = detailResponse?.data?.fileName?.split(".")?.[0];
    //         let so = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ? value : form?.getFieldsValue()?.so;
    //         let quyenSo = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ? value : form?.getFieldsValue()?.quyenSo) || "").split("/");
    //         let ngayDangKy = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "").split(".");
    //         // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
    //         let temp = '';
    //         if (ngayDangKy?.length === 3) {
    //             temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}`;
    //         } else if (ngayDangKy?.length === 2) {
    //             temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
    //         } else {
    //             temp = `${ngayDangKy?.[0] || ""}--`;
    //         }
    //         tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${temp}.${so || ""}.pdf`;
    //         form.setFieldsValue({
    //             [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]: tenFilePdf,
    //         })
    //         setPdfFileName(tenFilePdf);
    //     }
    //     let loaiDangKy: any;
    //     let ngayDangKy1 = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "");

    //     // Loại đăng ký KS
    //     let changeLoaiDangKy = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ||
    //         item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nksNgaySinh;
    //     let nksNgaySinh = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nksNgaySinh ? value : form?.getFieldsValue()?.nksNgaySinh) || "");

    //     // Loại đăng ký KT
    //     let changeLoaiDangKyKT = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ||
    //         item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nktNgayChet;
    //     let nktNgayChet = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nktNgayChet ? value : form?.getFieldsValue()?.nktNgayChet) || "");


    //     if (changeLoaiDangKy && fieldConfigResponse?.data?.type === "KS" && ngayDangKy1 && nksNgaySinh) {
    //         loaiDangKy = autoFillLoaiDangKy(ngayDangKy1, nksNgaySinh);
    //     }

    //     if (changeLoaiDangKyKT && fieldConfigResponse?.data?.type === "KT" && ngayDangKy1 && nktNgayChet) {
    //         loaiDangKy = autoFillLoaiDangKyKT(ngayDangKy1, nktNgayChet);
    //     }

    //     setKeyMaps((keyMaps || []).map((x: any, i: number) => {
    //         if (x.dataKeyAscii === item.dataKeyAscii) return {
    //             ...x,
    //             dataValue: value || "",
    //         }
    //         if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf && changeFilePdf) return {
    //             ...x,
    //             dataValue: tenFilePdf
    //         }
    //         if ((x.dataKeyAscii || "").toLowerCase().includes((item.dataKeyAscii || "").toLowerCase()) &&
    //             (x.dataKeyAscii || "").toLowerCase().includes("bangchu")) {
    //             let txt = helpers.dateToWord(value || '');
    //             form.setFieldsValue({ [x.dataKeyAscii]: txt });
    //             return {
    //                 ...x,
    //                 dataValue: txt
    //             }
    //         }
    //         // Tự động điền trường loại đăng ký cho KS
    //         if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.loaiDangKy &&
    //             (changeLoaiDangKy || changeLoaiDangKyKT)) {
    //             form.setFieldsValue({ [x.dataKeyAscii]: loaiDangKy ? loaiDangKy : undefined });
    //             return {
    //                 ...x,
    //                 dataValue: loaiDangKy ? loaiDangKy : undefined
    //             }
    //         }
    //         return x;
    //     }));
    // }


    // const autoFillLoaiDangKy = (ngayDangKy: string, ngaySinh: string) => {
    //     let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
    //     let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
    //     let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
    //     if (timeCompare < 0) return undefined;
    //     if (timeCompare <= 60) return "1";
    //     if (timeCompare / 365 > 10) return "2";
    //     if (timeCompare > 60) return "4";
    // }

    // const autoFillLoaiDangKyKT = (ngayDangKy: string, ngaySinh: string) => {
    //     let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
    //     let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
    //     let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
    //     if (timeCompare < 0) return undefined;
    //     if (timeCompare <= 15) return "1";
    //     if (timeCompare / 365 > 10) return "2";
    //     if (timeCompare > 15) return "4";
    // }

    // Chuyển trường dữ liệu thành item
    // const convertTypeDataToElement = (item: FieldConfig, index: number) => {
    //     if (item.data && item.data.length) {
    //         return <Form.Item
    //             name={item.dataKeyAscii}
    //             style={{ marginBottom: 10 }}
    //             label={<div >
    //                 <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{index + 1}.{item.dataKey || ""}</div>
    //             </div>}
    //             rules={createRules(item)}
    //         >
    //             <Select
    //                 disabled={!item.isEdit}
    //                 allowClear
    //                 placeholder={item.placeholder || "--Lựa chọn--"}
    //                 optionFilterProp='label'
    //                 options={(item.data || []).map((x: any) => ({
    //                     label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
    //                     value: x.id + ''
    //                 }))}
    //                 showSearch
    //                 showArrow
    //                 onChange={(value) => changeData(value, index, item)} value={item.dataValue}
    //             >
    //             </Select>
    //         </Form.Item>
    //     }
    //     if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
    //         switch (item.dataKeyAscii) {
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
    //                 return <Form.Item
    //                     name={item.dataKeyAscii || ""}
    //                     style={{ marginBottom: 10 }}
    //                     label={<div >
    //                         <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{index + 1}.{item.dataKey || ""}</div>
    //                     </div>}
    //                     rules={[
    //                         { required: true, message: "Trường dữ liệu bắt buộc" },
    //                     ]}
    //                 >
    //                     <Select
    //                         disabled={!item.isEdit}
    //                         allowClear
    //                         placeholder={item.placeholder || "--Lựa chọn--"}
    //                         optionFilterProp='label'
    //                         options={(responseProvince?.data || []).map((item) => ({
    //                             label: item.provinceName,
    //                             value: item.id
    //                         }))}
    //                         showSearch
    //                         showArrow
    //                         onChange={(value) => changeData(value, index, item)} value={item.dataValue}
    //                     >
    //                     </Select>
    //                 </Form.Item>
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
    //                 return <Form.Item
    //                     name={item.dataKeyAscii || ""}
    //                     style={{ marginBottom: 10 }}
    //                     label={<div>
    //                         <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{index + 1}.{item.dataKey || ""}</div>
    //                     </div>}
    //                     rules={[
    //                         { required: true, message: "Trường dữ liệu bắt buộc" },
    //                     ]}
    //                 >
    //                     <Select
    //                         disabled={!item.isEdit}
    //                         allowClear
    //                         placeholder={item.placeholder || "--Lựa chọn--"}
    //                         optionFilterProp='label'
    //                         options={(districts || []).map((item) => ({
    //                             label: item.districtName,
    //                             value: item.id
    //                         }))}
    //                         showSearch
    //                         showArrow
    //                         onChange={(value) => changeData(value, index, item)} value={item.dataValue}
    //                     >
    //                     </Select>
    //                 </Form.Item>
    //             case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
    //                 return <Form.Item
    //                     name={item.dataKeyAscii || ""}
    //                     style={{ marginBottom: 10 }}
    //                     label={<div >
    //                         <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{index + 1}.{item.dataKey || ""}</div>
    //                     </div>}
    //                     rules={[
    //                         { required: true, message: "Trường dữ liệu bắt buộc" },
    //                     ]}
    //                 >
    //                     <Select
    //                         disabled={!item.isEdit}
    //                         allowClear
    //                         placeholder={item.placeholder || "--Lựa chọn--"}
    //                         optionFilterProp='label'
    //                         options={(communes || []).map((item) => ({
    //                             label: item.communeName,
    //                             value: item.id
    //                         }))}
    //                         showSearch
    //                         showArrow
    //                         onChange={(value) => changeData(value, index, item)} value={item.dataValue}
    //                     >
    //                     </Select>
    //                 </Form.Item>
    //         }
    //     }
    //     return <Form.Item
    //         name={item.dataKeyAscii}
    //         style={{ marginBottom: 10 }}
    //         label={<div >
    //             <div style={{ fontWeight: 700, wordBreak: "break-word" }}>{index + 1}.{item.dataKey || ""}</div>
    //         </div>}
    //         rules={createRules(item)}
    //     ><Input.TextArea
    //             placeholder={item.placeholder || ""}
    //             disabled={!item.isEdit}
    //             onFocus={(e) => e.currentTarget.select()}
    //             // onChange={(e) => changeData(e.target.value, index, item)}
    //             // value={item.dataValue}
    //             onBlur={(e) => updateData(e.target.value, index, item)}
    //             autoComplete="on"
    //         /></Form.Item>;
    // }

    // Format lại dữ liệu sau khi nhập liệu
    // const updateData = (value: any, index: number, item: FieldConfig) => {
    //     if (item.type === "date") {
    //         value = helpers.customDate(value);
    //         changeData(value, index, item);
    //     } else if (item.type === "time") {
    //         value = helpers.customTime(value, item.format);
    //         changeData(value, index, item);
    //     } else if (item.format?.toLowerCase().includes("capitalcase")) {
    //         value = helpers.toTitleCase(value);
    //         changeData(value, index, item);
    //     }
    //     changeData((value || "").trim(), index, item);
    //     form.validateFields([item.dataKeyAscii]);
    // }

    // Tạo rules validate cho trường dữ liệu
    // const createRules = (item: any) => {
    //     let rules: Object[] = [];
    //     if (item.isDefault) {
    //         rules = [...rules, { required: true, message: "Trường dữ liệu bắt buộc" }];
    //     }
    //     if (item.format && item.format !== "capitalcase") {
    //         rules = [...rules, { pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng" }];
    //     }
    //     return rules;
    // }

    const resetData = () => {
        if (fieldConfigResponse?.data?.fieldKeyMap?.length) {
            setKeyMaps(fieldConfigResponse?.data?.fieldKeyMap);
        } else {
            setKeyMaps([]);
        }
        form.resetFields();
    }

    // const onSubmitSuccess = () => {
    //     updateStatusPdf({ id: detailResponse?.data?.id, isCheck: true, status: ((!detailResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") }).unwrap().then((res: any) => {

    //         // console.log('aaaaa')

    //         resetData();
    //         setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
    //         triggerFilePdfId(param?.id);
    //         toggleConfirmSuccessModal();
    //         notification.success({ message: ((!detailResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ? "Báo lỗi thành công" : "Cập nhật file thành công", })
    //     })
    // }

    // const onSubmitOpenFileSuccess = () => {
    //     updateStatusPdf({ id: detailResponse?.data?.id, isCheck: false, status: 0 }).unwrap().then((res: any) => {
    //         resetData();
    //         setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
    //         triggerFilePdfId(param?.id);
    //         toggleOpenFileModal();
    //         notification.success({ message: "Đã mở lại file để nhập liệu thành công" })
    //     })
    // }

    const [height, setHeight] = useState<any>();
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();
    // const changeSize = (size: number) => {
    //     if (displayFlex) {
    //         setWidth(size);
    //         setClientY(clientY * (size / width));
    //     } else {
    //         setHeight(size);
    //         setClientY(clientY * (size / height));
    //     }
    //     setIsRendered(false);
    // }

    const { updateTableData, isShowTableData } = useContext(DataInfoContext);

    useEffect(() => {
        // updateDataResponse(dataResponse);
        updateTableData(tableData?.length);
    });

    // const beforeUpload = (files: File[]) => {
    //     let accepted = true;
    //     let fileList = [...files];
    //     fileList = fileList.slice(-1);
    //     Array.from(fileList).forEach(file => {
    //         if (file.type !== "application/pdf") {
    //             accepted = false;
    //             notification.error({ message: `Định dạng file không hợp kệ` });
    //         }
    //     })
    //     return accepted || Upload.LIST_IGNORE;
    // }

    
    // const customUpload = (options: any) => {
    //     setLoadingFile(true);
    //     options.onProgress({ percent: 0 })
    //     let formData = new FormData();
    //     formData.append('newFile', options.file);
    //     if ((options?.file?.name || "").toLowerCase() !== (detailResponse?.data?.fileName || "").toLowerCase()) {
    //         notification.error({ message: "Tên file pdf không trùng khớp với tên file pdf đã nhập!" });
    //         setLoadingFile(false);
    //     } else {
    //         replaceFileScan({
    //             filePdfId: detailResponse?.data?.id,
    //             newFile: formData
    //         }).unwrap()
    //             .then((res: any) => {
    //                 options.onProgress({ percent: 100 });
    //                 options.onSuccess(res, options.file);
    //                 setLoadingFile(false);
    //                 notification.success({ message: "Thay thế file pdf thành công!" });
    //                 setReloadPdf(!reloadPdf)
    //             }).catch((err: any) => {
    //                 options.onError();
    //                 setLoadingFile(false);
    //                 if (err?.data?.message) {
    //                     notification.error({
    //                         message: err?.data?.message
    //                     })
    //                 }
    //             })
    //     }

    // }

    const file2 = useMemo(() => {
        if(detailResponse?.data?.pathFile) {
            return {
                url: process.env.REACT_APP_API_FILE_URL + "/" + detailResponse?.data?.pathFile, 
                httpHeaders: {
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                    "Content-Type": "application/json;charset=UTF-8",
              }}
        }
    },[detailResponse?.data?.pathFile])

    return (
        <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
            <div className="content-box" >
                <div>
                    {isShowTableData ? <>
                        <Table<any>
                            dataSource={tableData}
                            columns={columns}
                            tableLayout="auto"
                            pagination={false}
                            scroll={{ x: "max-content", y: 500 }}
                        />
                    </> : <></>}
                </div>

                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    {/* <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} 
                        size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    > */}
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <label style={{ backgroundColor: '#fefbd8', fontWeight: 'bold', fontSize: 10 }} className='search-title'>
                                    {detailResponse?.data?.pathFile || ''}
                                </label>
                            </div>
                            <div>
                                <ZoomOut
                                    onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale === 1 ? 1 : scale - 1 })}
                                    style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} />
                                <ZoomIn
                                    onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale + 1 })}
                                    style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} />
                                <Rotate
                                    onClick={() => setRotate(rotate - 90)}
                                    style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} />
                                <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} />
                                {/* <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} /> */}
                                {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} /> */}
                                <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} />
                                <Link
                                    style={{ marginLeft: 10 }}
                                    to={{
                                        pathname: `/data/info_init`,
                                        search: `${detailResponse?.currentData?.fileName}&${detailResponse?.currentData?.districtCode}&${detailResponse?.currentData?.communeCode}`
                                    }}
                                >
                                    {`(Đã nhập ${detailResponse?.currentData?.numberCheck || detailResponse?.data?.numberCheck} THHT)`}
                                </Link>
                            </div>
                            <div
                                style={{
                                    overflowY: "auto",
                                    height: displayFlex ? "calc(100vh - 160px)" : "calc(100% - 55px)",
                                }}
                                id="block-view-pdf-image"
                            >
                                <TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev + 1 });
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev - 1 || 1 });
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file2}
                                            onLoadSuccess={onDocumentLoadSuccess}>
                                            {Array.from(new Array(Math.round(numPages / 2)), (el, index) => (
                                                <div style={{ display: "flex" }} key={`grouppage${index + 1}`}>
                                                    <Page
                                                        width={width}
                                                        // height={height}
                                                        rotate={rotate}
                                                        scale={scale}
                                                        canvasRef={canvas}
                                                        key={`page_${index * 2 + 1}`}
                                                        pageNumber={index * 2 + 1}
                                                        onRenderSuccess={onRenderSuccess}
                                                        onClick={onClickPage}
                                                    />
                                                    {
                                                        (index * 2 + 2) <= numPages ? <Page
                                                            width={width}
                                                            // height={height}
                                                            rotate={rotate}
                                                            scale={scale}
                                                            key={`page_${index * 2 + 2}`}
                                                            pageNumber={index * 2 + 2}
                                                            canvasRef={canvas1}
                                                            onRenderSuccess={onRenderSuccess}
                                                            onClick={onClickPage}
                                                        /> : <></>
                                                    }

                                                </div>
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </div>
                        {/* {displayFlex ? <div
                        id='Draggable'
                        draggable='true'
                        onDragStart={initial}
                        onDrag={resize}
                    ></div> : <></>} */}
                        {/* <div
                            style={displayFlex ? {
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                position: "absolute"
                                // resize: "horizontal",
                                // overflow: "auto"
                            } : {
                                // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                // position: "absolute"
                            }}
                        >
                            <Form
                                name="searchBox"
                                autoComplete="off"
                                form={form}
                                colon={false}
                                style={{ marginTop: 0 }}
                                layout="vertical"
                                labelWrap
                                labelAlign="left"
                            >
                                <label style={{ backgroundColor: '#fefbd8' }} className='search-title'>Dữ liệu THHT: {pdfFileName}</label>
                                {checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.SUA) ? 
                                    <Upload
                                        showUploadList={false}
                                        customRequest={(options) => customUpload(options)}
                                        accept=".pdf"
                                        beforeUpload={(file, fileList) => beforeUpload(fileList)}
                                        style={{ marginBottom: 0 }}
                                        multiple={true}
                                        listType="text"
                                    >
                                        <Button
                                            title="Thay thế file pdf khác"
                                            type="primary"
                                            loading={loadingFile}
                                            style={{ marginBottom: 15, marginLeft: 10 }}
                                        ><UploadOutlined /> </Button>
                                    </Upload> : <></>
                                }
                                <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                        {keyMaps.length && keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                            return (<Col span={8} key={item.dataKeyAscii}>
                                                {convertTypeDataToElement(item, index)}
                                            </Col>)
                                        })}
                                    </Row>
                                </div>
                            </Form>
                            {detailResponse?.data?.id ? <div className='search-box-button' style={{ marginTop: 15 }}>
                                <Button onClick={() => { resetData(); setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined) }} htmlType='button'>
                                    Xóa ô nhập liệu
                                </Button>
                                <Button type='primary' onClick={onFinish}>Lưu dữ liệu</Button>
                                <Button
                                    type='primary'
                                    style={((!detailResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4" || !detailResponse?.data?.status) ?
                                        { backgroundColor: "#ff4d4f", border: "#ff4d4f" } :
                                        { backgroundColor: "#49cc90", border: "#49cc90" }}
                                    onClick={toggleConfirmSuccessModal}>
                                    {((!detailResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4" || !detailResponse?.data?.status) ?
                                        "Báo lỗi" : `Nhập đủ (${detailResponse?.currentData?.numberCheck || detailResponse?.data?.numberCheck})`}
                                </Button>
                                {userState.username === "admin" || userState.username === "trungnt" ? <Button
                                    type='primary'
                                    style={{ backgroundColor: "#49cc90", border: "#49cc90" }}
                                    onClick={toggleOpenFileModal}>
                                    Mở lại file
                                </Button> : <></>}
                            </div> : <></>}
                        </div> */}
                    {/* </SplitPane> */}
                </div>

            </div >
            {/* okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"} */}
            {/* {
                confirmVisible ?
                    <ConfirmModal
                        cancelText="Huỷ bỏ"
                        okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"}
                        note={year === "A4" ? undefined : "*Chú ý: Lưu (Nhập THHT khác) chỉ áp dụng cho ảnh có nhiều trường hợp hộ tịch"}
                        okSuccess="Lưu (Chuyển file pdf khác)"
                        classBtnOk="ant-button-dangerous"
                        contents={["Bạn có chắc chắn muốn lưu thông tin?"]}
                        handleCancel={toggleConfirmModal}
                        handleOk={() => onSubmitCheckData()}
                        handleSuccess={() => onSubmitCheckData(true)}
                        type={1}
                        visible={confirmVisible}
                        widthConfig={570}
                        okBtnDanger={true} />
                    : <></>
            } */}
            {/* {confirmSuccessVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={((!detailResponse?.data?.numberCheck && !detailResponse?.currentData?.numberCheck) || year === "A4") ?
                        ["Bạn có chắc chắn muốn báo cáo lỗi!"] :
                        ["Bạn có chắc chắn hoàn thành nhập liệu file này và chuyển sang file khác?",
                            "(Dữ liệu hiện tại đang nhập sẽ không được lưu)"]}
                    handleCancel={toggleConfirmSuccessModal}
                    handleOk={onSubmitSuccess}
                    type={1}
                    visible={confirmSuccessVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>
            } */}
            {/* {openFileVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn mở lại file để nhập liệu!"]}
                    handleCancel={toggleOpenFileModal}
                    handleOk={onSubmitOpenFileSuccess}
                    type={1}
                    visible={openFileVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>
            } */}
            {/* {listDataInfoInitVisible ?
                <ListDataInfoInitModal
                    visible={listDataInfoInitVisible}
                    toggleModal={toggleListDataInfoInitModal}
                    columns={columns}
                    tableData={tableData}
                />
                : <></>} */}
        </Spin >
    )
}

export default DetailFilePdf;
