import { CheckCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { PDFPageProxy } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import SplitPane from "react-split-pane";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { helpers } from "../../../assets/js/helper";
import { ReactComponent as Left } from "../../../assets/logos/layout-left-line.svg";
import { ReactComponent as Top } from "../../../assets/logos/layout-top-line.svg";
import { ReactComponent as Rotate } from "../../../assets/logos/rotate.svg";
import { ReactComponent as ZoomIn } from "../../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../../assets/logos/zoom-out-line.svg";
import {
  DEFAULT_FIELD_CONFIG,
  MAX_LENGTH,
  QN_UBND,
} from "../../../assets/ts/constant";
import { Commune, District } from "../../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
} from "../../../redux/api/commonApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
import { FieldConfig } from "../../data_init/redux/apiTypes";
import { useLazyGetFilePdfByIdQuery } from "../../data_init/redux/dataInitApi";
import {
  useLazyGetRandomDataPdfRecheckQuery,
  useUpdateFieldKeyMapValueMutation,
} from "../../recheck-campaign/redux/recheckCampaignApi";
import "../assets/css/index.css";

const DetailDataRecheckFile = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [orderNumber, setOrderNumber] = useState(1);
  const [displayFlex, setDisplayFlex] = useState(true);
  const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
  const [keyMaps2, setKeyMaps2] = useState<FieldConfig[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();

  const [triggerFileRecheck, dataFieldFile] =
    useLazyGetRandomDataPdfRecheckQuery();

  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();

  const [triggerUpdateDataKeyMapValue, { isLoading: isLoadingUpdate }] =
    useUpdateFieldKeyMapValueMutation();

  const [provinceObject, setProvinceObject] = useState();
  const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
  const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
  const [districtObject, setDistrictObject] = useState();
  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [communeObject, setCommuneObject] = useState();
  const [communeAllTrigger, responseCommuneAll] =
    useLazyGetAllCommuneNoAuthQuery();
  const [districtAllTrigger, responseDistrictAll] =
    useLazyGetAllDistrictNoAuthQuery();
  const [pdfFileName, setPdfFileName] = useState("");
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [successFields, setSuccessFields] = useState<string[]>([]);

  const wrapperDiv = useRef<HTMLInputElement>(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const canvas = useRef<any>();
  const canvas1 = useRef<any>();
  const [clientY, setClientY] = useState<number>(0);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const prevScale = useRef<number>(1);

  const ngayDangKyValue = Form.useWatch("ngayDangKy", form);

  useEffect(() => {
    if (clientY === 0 || !canvas.current || !isRendered) {
      return;
    }
    const context = canvas.current.getContext("2d");
    const { width } = canvas.current;
    const rect = canvas.current.getBoundingClientRect();
    context.save();
    context.beginPath();
    context.moveTo(0, clientY);
    context.lineTo(width, clientY);
    context.strokeStyle = "red";
    context.stroke();
    context.restore();
    if (canvas1.current) {
      const context1 = canvas1.current.getContext("2d");
      const width1 = canvas1.current.width;
      const rect1 = canvas1.current.getBoundingClientRect();
      context1.save();
      context1.beginPath();
      context1.moveTo(0, clientY);
      context1.lineTo(width1, clientY);
      context1.strokeStyle = "red";
      context1.stroke();
      context1.restore();
    }
  }, [clientY, isRendered]);

  useEffect(() => {
    if (prevScale.current === scale) {
      return;
    } else {
      transformComponentRef.current.resetTransform(0);
      transformComponentRef.current.centerView(1, 0);
      setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
      setIsRendered(false);
    }
  }, [scale]);

  const onRenderSuccess = (page: PDFPageProxy) => {
    setIsRendered(true);
  };

  const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
    if (!isDrag) {
      const rect = canvas.current.getBoundingClientRect();
      const scaleY = canvas.current.height / rect.height;
      setClientY((event.clientY - rect.top) * scaleY);
    }
  };

  useEffect(() => {
    const slider = document.getElementById("block-view-pdf-image");
    let isDown = false;
    let startX: any;
    let startY: any;
    let scrollLeft: any;
    let scrollTop: any;
    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        startY = e.pageY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
        setIsDrag(false);
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        setIsDrag(true);
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageY - slider.offsetTop;
        const walk = (x - startY) * 3; //scroll-fast
        slider.scrollTop = scrollTop - walk;
        setIsDrag(true);
      });
    }
  }, []);

  useEffect(() => {
    if (displayFlex) {
      setWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
      setInitWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

  const changeLayout = (newDisplayFlex: boolean, order: number) => {
    setDisplayFlex(newDisplayFlex);
    setOrderNumber(order);
    if (!newDisplayFlex) {
      setWidth(initWidth * 2);
    }
  };

  const [height, setHeight] = useState<any>();
  const [width, setWidth] = useState<any>();
  const [initWidth, setInitWidth] = useState<any>();

  const changeSize = (size: number) => {
    if (displayFlex) {
      setWidth(size);
      setClientY(clientY * (size / width));
    } else {
      setHeight(size);
      setClientY(clientY * (size / height));
    }
    setIsRendered(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    triggerProvince({})
      .unwrap()
      .then((res) => {
        setProvinceObject(
          res?.reduce(
            (a: any, v: any) => ({
              ...a,
              [DEFAULT_FIELD_CONFIG.tinhThanh]: v.id,
            }),
            {}
          )
        );
      });
    communeAllTrigger({})
      .unwrap()
      .then((res) => {
        setCommuneObject(
          res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {})
        );
      });
    districtAllTrigger({})
      .unwrap()
      .then((res) => {
        setDistrictObject(
          res?.reduce(
            (a: any, v: any) => ({ ...a, [v.districtCode]: v.id }),
            {}
          )
        );
      });
  }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

  useEffect(() => {
    triggerFileRecheck({});
  }, [triggerFileRecheck]);

  useEffect(() => {
    if (dataFieldFile?.data) {
      triggerFilePdfId(dataFieldFile?.data[0]?.filePDFId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFieldFile, triggerFilePdfId]);

  useEffect(() => {
    if (
      dataFieldFile?.data?.[0]?.dataKeyMap &&
      provinceObject &&
      districtObject &&
      responseCommuneAll?.data &&
      detailResponse?.data
    ) {
      setInitData(
        dataFieldFile?.data[0]?.dataKeyMap,
        dataFieldFile?.data[1]?.dataKeyMap
      );
    } else {
      setKeyMaps([]);
      setKeyMaps2([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    provinceObject,
    districtObject,
    responseCommuneAll?.data,
    dataFieldFile?.data,
    detailResponse?.data,
  ]);

  const file = useMemo(() => {
    if (detailResponse?.data?.pathFile) {
      return {
        url:
          process.env.REACT_APP_API_FILE_URL +
          "/" +
          detailResponse?.data?.pathFile,
        httpHeaders: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
    }
  }, [detailResponse?.data?.pathFile]);

  const handleSaveData = () => {
    const newValues = [keyMaps, keyMaps2];
    const newDataFieldFile = [...dataFieldFile.data];
    const newPayload = newDataFieldFile.map((item, index) => {
      return {
        ...item,
        dataKeyMap: newValues[index],
      };
    });
    triggerUpdateDataKeyMapValue({
      id: dataFieldFile?.data?.[0].campaignInputMapId,
      payload: newPayload,
    })
      .unwrap()
      .then((res) => {
        if (res) {
          notification.success({ message: "Lưu dữ liệu thành công!" });
          setKeyMaps([]);
          setKeyMaps2([]);
          setOpenConfirmModal(false);
          triggerFileRecheck({});
        } else {
          notification.error({ message: "Lưu dữ liệu thất bại" });
        }
      })
      .catch(() => {
        notification.error({ message: "Có lỗi xảy ra!" });
      });
  };

  const handleSubmitBothForm = () => {
    Promise.all([form.validateFields(), form2.validateFields()])
      .then(([form1Values, form2Values]) => {
        const areValuesEqual = Object.keys(form1Values).every((key) => {
          // Compare values with the same key
          if (
            form1Values[key] &&
            form2Values[key] &&
            form1Values[key] !== form2Values[key]
          ) {
            const msg = `Giá trị của trường '${key}' không trùng khớp!`;
            notification.error({ message: msg });
            return false; // Stop if a mismatch is found
          }
          return true;
        });
        if (!areValuesEqual) return;
        setOpenConfirmModal(true);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const setInitData = (dataKeyMaps: any, dataKeyMaps2: any) => {
    if (dataKeyMaps?.length) {
      setKeyMaps(
        dataKeyMaps.map((item: any) => {
          let newItem = setDataItem(item, form);
          if (newItem) {
            return newItem;
          } else if (item.defaultValue || item.dataValue) {
            // Set active giấy tờ khác khi loại giấy tờ khác 8
            if (
              item.dataKeyAscii?.includes("LoaiGiayToTuyThan") &&
              (item.defaultValue || item.dataValue) !== "8"
            ) {
              const fieldName = getPrefix(item.dataKeyAscii) + "GiayToKhac";
              setListActive((prev) => [...prev, fieldName]);
            }

            form.setFieldsValue({
              [item.dataKeyAscii]: item.defaultValue || item.dataValue,
            });
            return {
              ...item,
              dataValue: item.defaultValue || item.dataValue,
            };
          }
          return item;
        })
      );
    }
    if (dataKeyMaps2?.length) {
      setKeyMaps2(
        dataKeyMaps2.map((item: any) => {
          let newItem = setDataItem(item, form2);
          if (newItem) {
            return newItem;
          } else if (item.defaultValue || item.dataValue) {
            form2.setFieldsValue({
              [item.dataKeyAscii]: item.defaultValue || item.dataValue,
            });
            return {
              ...item,
              dataValue: item.defaultValue || item.dataValue,
            };
          }
          return item;
        })
      );
    }
  };

  function getPrefix(str: string, postFix?: string) {
    if (!str) return "";
    const prefixLength = str.indexOf(postFix || "LoaiGiayToTuyThan");
    if (prefixLength !== -1) {
      return str.substring(0, prefixLength);
    } else {
      return "";
    }
  }

  const disabledField = useCallback(
    (keyData: string, currentForm: FormInstance<any>) => {
      let disabled: boolean = false;

      // Disable giấy tờ khác tùy vào loại giấy tờ tùy thân
      if (keyData?.includes("GiayToKhac")) {
        const fieldName =
          getPrefix(keyData, "GiayToKhac") + "LoaiGiayToTuyThan";
        const fieldValue = currentForm.getFieldValue(fieldName);
        if (fieldValue && fieldValue !== "8") {
          return true;
        }
      }
      return disabled;
    },
    []
  );

  // Set dữ liệu ban đầu
  const setDataItem = (item: FieldConfig, form: any) => {
    const typeFile = dataFieldFile?.data?.[0]?.typeId;
    if (item.dataKeyAscii) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
          districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh])
            .unwrap()
            .then((res) => setDistricts(res));
          form.setFieldsValue({
            [item.dataKeyAscii]:
              provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh],
          });
          return {
            ...item,
            dataValue: provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] || "",
          };
        case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
          communeTrigger(item.dataValue)
            .unwrap()
            .then((res) => {
              setCommunes(res);
            });
          if (item.dataKeyAscii) {
            form.setFieldsValue({
              [item.dataKeyAscii]: item.dataValue,
            });
          }
          return {
            ...item,
            dataValue: item.dataValue || "",
          };
        case DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa:
          if (
            detailResponse?.data?.communeCode &&
            detailResponse?.data?.districtCode
          ) {
            let newCommuneObject = responseCommuneAll?.data
              ?.filter(
                (item) =>
                  detailResponse?.data?.districtCode &&
                  item.districtId ===
                    districtObject?.[detailResponse?.data?.districtCode]
              )
              ?.reduce(
                (a: any, v: any) => ({ ...a, [v.communeCode]: v.id }),
                {}
              );
            setCommuneObject(newCommuneObject);
            form.setFieldsValue({
              [item.dataKeyAscii]:
                item.dataValue ||
                newCommuneObject?.[detailResponse?.data?.communeCode],
            });

            return {
              ...item,
              dataValue:
                item.dataValue ||
                newCommuneObject?.[detailResponse?.data?.communeCode] ||
                "",
            };
          }
          return null;
        case DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo:
          if (item.dataKeyAscii) {
            form.setFieldsValue({
              [item.dataKeyAscii]: item.dataValue,
            });
          }
          return {
            ...item,
            dataValue: item.dataValue,
          };
        case DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf:
          let pdfFileNames1 = detailResponse?.data?.fileName?.split(".");
          let type = detailResponse?.data?.fileName?.split(".")?.[0];
          let tenFilePdf = `${type}.${pdfFileNames1?.[1] || "--"}.${
            pdfFileNames1?.[2] || "--"
          }.--..pdf`;
          form.setFieldsValue({
            [DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]: tenFilePdf,
          });
          setPdfFileName(tenFilePdf);
          return {
            ...item,
            dataValue: tenFilePdf,
          };
        case DEFAULT_FIELD_CONFIG.noiDangKy:
        case DEFAULT_FIELD_CONFIG.noiCap:
          if (
            detailResponse?.data?.districtCode &&
            detailResponse?.data?.communeCode &&
            item?.data?.length
          ) {
            let key = `${DEFAULT_FIELD_CONFIG.tinhThanh}-${detailResponse?.data?.districtCode}-${detailResponse?.data?.communeCode}`;
            form.setFieldsValue({
              [item.dataKeyAscii]: item.dataValue || QN_UBND[key],
            });
            return {
              ...item,
              dataValue: item.dataValue || QN_UBND[key],
            };
          }
      }
    }
    return null;
  };

  const changeData = async (
    value: any,
    index: number,
    item: FieldConfig,
    currentForm: any
  ) => {
    // Nếu không cho sửa thì không được sửa
    if (!item.isEdit) return;
    const typeFile = dataFieldFile?.data?.[0]?.typeId;
    if (item?.dataKeyAscii) {
      currentForm.setFieldsValue({ [item.dataKeyAscii]: value });
    }
    switch (item.dataKeyAscii) {
      case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
        currentForm.setFieldsValue({
          quanHuyen: undefined,
          phuongXa: undefined,
        });
        setDistricts([]);
        setCommunes([]);
        districtTrigger(value)
          .unwrap()
          .then((res) => setDistricts(res));
        break;
      case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
        currentForm.setFieldsValue({ phuongXa: undefined });
        setCommunes([]);
        communeTrigger(value)
          .unwrap()
          .then((res) => {
            setCommunes(res);
          });
        break;
    }
    // KS.2007.01.2007-07-11.082.pdf
    let changeFilePdf =
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.so ||
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo ||
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.ngayDangKy;
    let tenFilePdf = "";
    if (changeFilePdf) {
      let type = detailResponse?.data?.fileName?.split(".")?.[0];
      let so =
        item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.so
          ? value
          : currentForm?.getFieldsValue()?.so;
      let quyenSo = (
        (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo
          ? value
          : currentForm?.getFieldsValue()?.quyenSo) || ""
      ).split("/");
      let ngayDangKy = (
        (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.ngayDangKy
          ? value
          : currentForm?.getFieldsValue()?.ngayDangKy) || ""
      ).split(".");
      // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
      let temp = "";
      if (ngayDangKy?.length === 3) {
        temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${
          ngayDangKy?.[0] || ""
        }`;
      } else if (ngayDangKy?.length === 2) {
        temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
      } else {
        temp = `${ngayDangKy?.[0] || ""}--`;
      }
      tenFilePdf = `${type}.${quyenSo?.[1] || "--"}.${
        quyenSo?.[0] || "--"
      }.${temp}.${so || ""}.pdf`;
      form.setFieldsValue({
        [DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]: tenFilePdf,
      });
      form2.setFieldsValue({
        [DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]: tenFilePdf,
      });
      setPdfFileName(tenFilePdf);
    }
    let loaiDangKy: any;
    let ngayDangKy1 =
      (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.ngayDangKy
        ? value
        : currentForm?.getFieldsValue()?.ngayDangKy) || "";

    // Loại đăng ký KS
    let changeLoaiDangKy =
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.ngayDangKy ||
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.nksNgaySinh;
    let nksNgaySinh =
      (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.nksNgaySinh
        ? value
        : currentForm?.getFieldsValue()?.nksNgaySinh) || "";

    // Loại đăng ký KT
    let changeLoaiDangKyKT =
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.ngayDangKy ||
      item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.nktNgayChet;
    let nktNgayChet =
      (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.nktNgayChet
        ? value
        : currentForm?.getFieldsValue()?.nktNgayChet) || "";

    if (changeLoaiDangKy && typeFile === "KS" && ngayDangKy1 && nksNgaySinh) {
      loaiDangKy = autoFillLoaiDangKy(ngayDangKy1, nksNgaySinh);
    }

    if (changeLoaiDangKyKT && typeFile === "KT" && ngayDangKy1 && nktNgayChet) {
      loaiDangKy = autoFillLoaiDangKyKT(ngayDangKy1, nktNgayChet);
    }

    if (
      item.dataKeyAscii?.includes("LoaiGiayToTuyThan") &&
      currentForm.getFieldValue(item.dataKeyAscii)
    ) {
      // Reset trường số giấy tờ tùy thân khi thay đổi loại
      let soGiayToTuyThanName =
        getPrefix(item.dataKeyAscii) + "SoGiayToTuyThan";
      currentForm.setFieldValue(soGiayToTuyThanName, "");

      // Set active giấy tờ khác khi loại giấy tờ khác 8
      // Và clear field giấy tờ khác
      if (currentForm.getFieldValue(item.dataKeyAscii) !== "8") {
        const fieldName = getPrefix(item.dataKeyAscii) + "GiayToKhac";
        currentForm.setFieldValue(fieldName, "");
        setListActive((prev) => [...prev, fieldName]);
      }
    }

    // Check khi ngày đăng ký thay đổi
    if (item.dataKeyAscii === "ngayDangKy") {
      currentForm.validateFields([
        "nksNgaySinh",
        "nktNgayChet",
        "chongNgaySinh",
        "voNgaySinh",
        "nxnNgaySinh",
        "ncNgaySinh",
      ]);

      form2.validateFields([
        "nksNgaySinh",
        "nktNgayChet",
        "chongNgaySinh",
        "voNgaySinh",
        "nxnNgaySinh",
        "ncNgaySinh",
      ]);
    }

    if (currentForm.__INTERNAL__.name === "first-form") {
      setKeyMaps(
        (keyMaps || []).map((x: FieldConfig) => {
          if (x.dataKeyAscii === item.dataKeyAscii)
            return {
              ...x,
              isRecheckEdited: value && x.dataValue !== value,
              dataValue: value || "",
            };
          if (
            x.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf &&
            changeFilePdf
          ) {
            return {
              ...x,
              isRecheckEdited: x.dataValue !== tenFilePdf,
              dataValue: tenFilePdf,
            };
          }

          if (
            (x.dataKeyAscii || "")
              .toLowerCase()
              .includes((item.dataKeyAscii || "").toLowerCase()) &&
            (x.dataKeyAscii || "").toLowerCase().includes("bangchu")
          ) {
            let txt = helpers.dateToWord(value || "");
            currentForm.setFieldsValue({ [x.dataKeyAscii]: txt });
            return {
              ...x,
              isRecheckEdited: x.dataValue !== txt,
              dataValue: txt,
            };
          }
          // Tự động điền trường loại đăng ký cho KS
          if (
            x.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.loaiDangKy &&
            (changeLoaiDangKy || changeLoaiDangKyKT)
          ) {
            currentForm.setFieldsValue({
              [x.dataKeyAscii]: loaiDangKy ? loaiDangKy : undefined,
            });
            return {
              ...x,
              isRecheckEdited: x.dataValue !== loaiDangKy,
              dataValue: loaiDangKy ? loaiDangKy : undefined,
            };
          }
          return x;
        })
      );
    } else {
      setKeyMaps2(
        (keyMaps2 || []).map((x: FieldConfig) => {
          if (x.dataKeyAscii === item.dataKeyAscii)
            return {
              ...x,
              isRecheckEdited: x.dataValue !== value,
              dataValue: value || "",
            };
          if (
            x.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf &&
            changeFilePdf
          ) {
            return {
              ...x,
              isRecheckEdited: x.dataValue !== tenFilePdf,
              dataValue: tenFilePdf,
            };
          }
          if (
            (x.dataKeyAscii || "")
              .toLowerCase()
              .includes((item.dataKeyAscii || "").toLowerCase()) &&
            (x.dataKeyAscii || "").toLowerCase().includes("bangchu")
          ) {
            let txt = helpers.dateToWord(value || "");
            currentForm.setFieldsValue({ [x.dataKeyAscii]: txt });
            return {
              ...x,
              isRecheckEdited: x.dataValue !== txt,
              dataValue: txt,
            };
          }
          // Tự động điền trường loại đăng ký cho KS
          if (
            x.dataKeyAscii === DEFAULT_FIELD_CONFIG[typeFile]?.loaiDangKy &&
            (changeLoaiDangKy || changeLoaiDangKyKT)
          ) {
            currentForm.setFieldsValue({
              [x.dataKeyAscii]: loaiDangKy ? loaiDangKy : undefined,
            });
            return {
              ...x,
              isRecheckEdited: x.dataValue !== loaiDangKy,
              dataValue: loaiDangKy ? loaiDangKy : undefined,
            };
          }
          return x;
        })
      );
    }
  };

  const autoFillLoaiDangKy = (ngayDangKy: string, ngaySinh: string) => {
    let tempMoment = moment("01.01.2016", "DD.MM.YYYY").toDate().getTime();
    let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
    let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
    let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
    // if (ngayDangKyDate < tempMoment) return "5";
    // if (ngayDangKyDate >= tempMoment && ngayDangKyDate <= moment().valueOf())
    //   return "2";
    if (timeCompare < 0 || ngayDangKyDate >= tempMoment) return undefined;
    if (timeCompare <= 60) return "1";
    if (timeCompare / 365 > 10) return "2";
    if (timeCompare > 60) return "4";
  };

  const autoFillLoaiDangKyKT = (ngayDangKy: string, ngayChet: string) => {
    let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
    let ngayChetDate = moment(ngayChet, "DD.MM.YYYY").toDate().getTime();
    let timeCompare = (ngayDangKyDate - ngayChetDate) / (1000 * 60 * 60 * 24);
    if (timeCompare < 0) return undefined;
    if (timeCompare <= 15) return "1";
    if (timeCompare / 365 > 10) return "2";
    if (timeCompare > 15) return "4";
  };

  const [listActive, setListActive] = useState<String[]>([]);

  const isDisabledClearButton = useMemo(() => {
    const isOk = keyMaps.every((item) => !item.isRecheckEdited);
    const isOk2 = keyMaps2.every((item) => !item.isRecheckEdited);
    return isOk && isOk2;
  }, [keyMaps, keyMaps2]);

  const nksLoaiKhaiSinh = Form.useWatch("nksLoaiKhaiSinh", form);
  const nksLoaiKhaiSinh2 = Form.useWatch("nksLoaiKhaiSinh", form2);

  const checkActiveSave = () => {
    let check = true;
    let arr: string[] = [];
    const typeFile = dataFieldFile?.data?.[0]?.typeId;
    if ((nksLoaiKhaiSinh || nksLoaiKhaiSinh2) && typeFile === "KS") {
      if (nksLoaiKhaiSinh === "2" || nksLoaiKhaiSinh2 === "2") {
        arr = [
          "meHoTen",
          "meNgaySinh",
          "meDanToc",
          "meQuocTich",
          "meQuocTichKhac",
          "meNoiCuTru",
          "meLoaiGiayToTuyThan",
          "meSoGiayToTuyThan",
        ];
      } else if (nksLoaiKhaiSinh === "3" || nksLoaiKhaiSinh2 === "3") {
        arr = [
          "chaHoTen",
          "chaNgaySinh",
          "chaDanToc",
          "chaQuocTich",
          "chaQuocTichKhac",
          "chaNoiCuTru",
          "chaLoaiGiayToTuyThan",
          "chaSoGiayToTuyThan",
        ];
      } else if (nksLoaiKhaiSinh === "4" || nksLoaiKhaiSinh2 === "4") {
        arr = [
          "meHoTen",
          "meNgaySinh",
          "meDanToc",
          "meQuocTich",
          "meQuocTichKhac",
          "meNoiCuTru",
          "meLoaiGiayToTuyThan",
          "meSoGiayToTuyThan",
          "chaHoTen",
          "chaNgaySinh",
          "chaDanToc",
          "chaQuocTich",
          "chaQuocTichKhac",
          "chaNoiCuTru",
          "chaLoaiGiayToTuyThan",
          "chaSoGiayToTuyThan",
        ];
      }
    }
    keyMaps
      .filter((x) => x.isEdit && x.isDisplay && !arr.includes(x.dataKeyAscii))
      .forEach((item) => {
        if (
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh &&
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen &&
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa &&
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo &&
          listActive.indexOf(item.dataKeyAscii) === -1
        )
          check = false;
      });

    keyMaps2
      .filter((x) => x.isEdit && x.isDisplay && !arr.includes(x.dataKeyAscii))
      .forEach((item) => {
        if (
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh &&
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen &&
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa &&
          item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[typeFile]?.quyenSo &&
          listActive.indexOf(item.dataKeyAscii) === -1
        )
          check = false;
      });
    return check;
  };

  const onBlurItem = (item: any) => {
    if (listActive.indexOf(item.dataKeyAscii) === -1) {
      setListActive([...listActive, item.dataKeyAscii]);
    }
    const value = form.getFieldValue(item.dataKeyAscii);
    const value2 = form2.getFieldValue(item.dataKeyAscii);
    console.log("value", value, value2);
    if (!value && !value2) {
      form.setFields([{ name: item.dataKeyAscii, errors: undefined }]);
      form2.setFields([{ name: item.dataKeyAscii, errors: undefined }]);
      setErrorFields((prev) => prev.filter((i) => i === item.dataKeyAscii));

      if (!successFields.includes(item.dataKeyAscii)) {
        setSuccessFields((prev) => [...prev, item.dataKeyAscii]);
      }
      return;
    }
    if (value === value2) {
      form.setFields([{ name: item.dataKeyAscii, errors: undefined }]);
      form2.setFields([{ name: item.dataKeyAscii, errors: undefined }]);
      setErrorFields((prev) => prev.filter((i) => i !== item.dataKeyAscii));

      if (!successFields.includes(item.dataKeyAscii)) {
        setSuccessFields((prev) => [...prev, item.dataKeyAscii]);
      }
    } else {
      form.setFields([
        {
          name: item.dataKeyAscii,
          errors: ["Trường dữ liệu không trùng khớp"],
        },
      ]);
      form2.setFields([
        {
          name: item.dataKeyAscii,
          errors: ["Trường dữ liệu không trùng khớp"],
        },
      ]);

      if (!errorFields.includes(item.dataKeyAscii)) {
        setErrorFields((prev) => [...prev, item.dataKeyAscii]);
      }
      setSuccessFields((prev) => prev.filter((i) => i !== item.dataKeyAscii));
    }
  };

  const getMaxLengthPaper = (key: string) => {
    if (key?.includes("SoGiayToTuyThan")) {
      let paperFieldName =
        getPrefix(key, "SoGiayToTuyThan") + "LoaiGiayToTuyThan";
      let type = form.getFieldValue(paperFieldName);

      switch (type) {
        case "1":
          return 9;
        case "2":
        case "6":
          return 8;
        case "3":
        case "8":
        case "10":
        case "11":
          return 0;
        case "4":
        case "5":
        case "9":
          return 12;
        case "7":
          return 6;
        default:
          return 0;
      }
    }

    return 0;
  };

  // Chuyển trường dữ liệu thành item
  const convertTypeDataToElement = (
    item: FieldConfig,
    index: number,
    tabIndex: number,
    form: any,
    showLabel: boolean
  ) => {
    const typeFile = dataFieldFile?.data?.[0]?.typeId;
    if (item.data && item.data.length) {
      return (
        <Form.Item
          name={item.dataKeyAscii}
          style={{ marginBottom: 10 }}
          className={!showLabel ? "no-label" : ""}
          labelCol={{ span: showLabel ? 12 : 0 }}
          label={
            showLabel ? (
              <div style={{ display: "flex" }}>
                {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                  <CheckCircleOutlined
                    title="Đã duyệt qua"
                    color="success"
                    style={{
                      margin: 5,
                      color: showLabel ? "#52c41a" : "transparent",
                    }}
                  />
                ) : (
                  <></>
                )}
                <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                  {index + 1}.{item.dataKey || ""}
                </div>
              </div>
            ) : (
              <></>
            )
          }
          rules={createRules(item)}
        >
          <Select
            style={{
              border: errorFields?.includes(item.dataKeyAscii)
                ? "1px solid red"
                : successFields?.includes(item.dataKeyAscii)
                ? "1px solid #1890ff"
                : "",
            }}
            // className={item.isRecheckEdited ? "custom-select-ant" : ""}
            disabled={!item.isEdit}
            allowClear
            placeholder={item.placeholder || "--Lựa chọn--"}
            optionFilterProp="label"
            options={(item.data || []).map((x: any) => ({
              label: `${x.id}${x.name ? " - " + x.name : ""}`,
              value: x.id + "",
            }))}
            showSearch
            showArrow
            onChange={(value) => changeData(value, index, item, form)}
            // value={item.dataValue}
            onBlur={() => onBlurItem(item)}
            tabIndex={tabIndex}
          ></Select>
        </Form.Item>
      );
    }
    if (item.isDefault && typeFile && (!item.data || !item.data.length)) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              className={!showLabel ? "no-label" : ""}
              labelCol={{ span: showLabel ? 12 : 0 }}
              label={
                showLabel ? (
                  <div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                      <CheckCircleOutlined
                        title="Đã duyệt qua"
                        color="success"
                        style={{
                          margin: 5,
                          color: showLabel ? "#52c41a" : "transparent",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                      {index + 1}.{item.dataKey || ""}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                style={{
                  border: errorFields?.includes(item.dataKeyAscii)
                    ? "1px solid red"
                    : successFields?.includes(item.dataKeyAscii)
                    ? "1px solid #1890ff"
                    : "",
                }}
                // className={item.isRecheckEdited ? "custom-select-ant" : ""}
                // disabled={!item.isEdit}
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(responseProvince?.data || []).map((item) => ({
                  label: item.provinceName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                onChange={(value) => changeData(value, index, item, form)}
                // value={item.dataValue}
                onBlur={() => onBlurItem(item)}
                tabIndex={tabIndex}
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              className={!showLabel ? "no-label" : ""}
              labelCol={{ span: showLabel ? 12 : 0 }}
              label={
                showLabel ? (
                  <div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                      <CheckCircleOutlined
                        title="Đã duyệt qua"
                        color="success"
                        style={{
                          margin: 5,
                          color: showLabel ? "#52c41a" : "transparent",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                      {index + 1}.{item.dataKey || ""}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                style={{
                  border: errorFields?.includes(item.dataKeyAscii)
                    ? "1px solid red"
                    : successFields?.includes(item.dataKeyAscii)
                    ? "1px solid #1890ff"
                    : "",
                }}
                // className={item.isRecheckEdited ? "custom-select-ant" : ""}
                // disabled={!item.isEdit}
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(districts || []).map((item) => ({
                  label: item.districtName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                onChange={(value) => changeData(value, index, item, form)}
                // value={item.dataValue}
                onBlur={() => onBlurItem(item)}
                tabIndex={tabIndex}
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[typeFile]?.phuongXa:
          return (
            <Form.Item
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              className={!showLabel ? "no-label" : ""}
              labelCol={{ span: showLabel ? 12 : 0 }}
              label={
                showLabel ? (
                  <div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                      <CheckCircleOutlined
                        title="Đã duyệt qua"
                        color="success"
                        style={{
                          margin: 5,
                          color: showLabel ? "#52c41a" : "transparent",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                      {index + 1}.{item.dataKey || ""}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                style={{
                  border: errorFields?.includes(item.dataKeyAscii)
                    ? "1px solid red"
                    : successFields?.includes(item.dataKeyAscii)
                    ? "1px solid #1890ff"
                    : "",
                }}
                // className={item.isRecheckEdited ? "custom-select-ant" : ""}
                // disabled={!item.isEdit}
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(communes || []).map((item) => ({
                  label: item.communeName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                onChange={(value) => changeData(value, index, item, form)}
                // value={item.dataValue}
                onBlur={() => onBlurItem(item)}
                tabIndex={tabIndex}
              ></Select>
            </Form.Item>
          );
      }
    }
    return (
      <Form.Item
        name={item.dataKeyAscii}
        style={{ marginBottom: 10 }}
        className={!showLabel ? "no-label" : ""}
        labelCol={{ span: showLabel ? 12 : 0 }}
        label={
          showLabel ? (
            <div style={{ display: "flex" }}>
              {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                <CheckCircleOutlined
                  title="Đã duyệt qua"
                  color="success"
                  style={{
                    margin: 5,
                    color: showLabel ? "#52c41a" : "transparent",
                  }}
                />
              ) : (
                <></>
              )}
              <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                {index + 1}.{item.dataKey || ""}
              </div>
            </div>
          ) : (
            <></>
          )
        }
        rules={createRules(item)}
      >
        <Input.TextArea
          style={{
            border: errorFields?.includes(item.dataKeyAscii)
              ? "1px solid red"
              : successFields?.includes(item.dataKeyAscii)
              ? "1px solid #1890ff"
              : "",
          }}
          // className={item.isRecheckEdited ? "custom-area-ant" : ""}
          placeholder={item.placeholder || ""}
          disabled={disabledField(item.dataKeyAscii, form) || !item.isEdit}
          onFocus={(e) => e.currentTarget.select()}
          // onChange={(e) => changeData(e.target.value, index, item)}
          // value={item.dataValue}
          onBlur={(e) => updateData(e.target.value, index, item, form)}
          autoComplete="on"
          tabIndex={tabIndex}
          maxLength={
            MAX_LENGTH[item.dataKeyAscii] ||
            getMaxLengthPaper(item.dataKeyAscii) ||
            item.maxLength ||
            50
          }
        />
      </Form.Item>
    );
  };

  // Format lại dữ liệu sau khi nhập liệu
  const updateData = (
    value: any,
    index: number,
    item: FieldConfig,
    form: any
  ) => {
    if (item.dataKeyAscii === "so" || item.dataKeyAscii === "trangSo") {
      value = helpers.formatNumber(helpers.toTitleCaseFirstChar(value));
    } else {
      value = helpers.toTitleCaseFirstChar(value);
    }
    if (item.type === "date") {
      value = helpers.customDate(value);
      changeData(value, index, item, form);
    } else if (item.type === "time") {
      value = helpers.customTime(value, item.format);
      changeData(value, index, item, form);
    } else if (item.format?.toLowerCase().includes("capitalcase")) {
      value = helpers.toTitleCase(value);
      changeData(value, index, item, form);
    }
    changeData((value || "").trim(), index, item, form);
    // form.validateFields([item.dataKeyAscii]);
    onBlurItem(item);
  };

  // Tạo rules validate cho trường dữ liệu
  const createRules = (item: any) => {
    let rules: Object[] = [];
    const typeFile = dataFieldFile?.data?.[0]?.typeId;

    if (item.isDefault) {
      rules = [
        ...rules,
        { required: true, message: "Trường dữ liệu bắt buộc" },
      ];
    }
    if (item.format && item.format !== "capitalcase") {
      rules = [
        ...rules,
        {
          pattern: new RegExp(item.format),
          message: "Dữ liệu không đúng định dạng",
        },
      ];
    }

    // Check ngày không hợp lệ
    if (item.type === "date") {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            let pattern = new RegExp(item.format);
            if (
              item.type === "date" &&
              value &&
              !moment(value, "DD.MM.YYYY").isValid() &&
              pattern.test(value)
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "nxnNgaySinh" &&
      ngayDangKyValue &&
      typeFile === "HN"
    ) {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (value && helpers.isAfterDate(value, ngayDangKyValue, true)) {
              return Promise.reject(
                new Error(
                  "Ngày sinh của người xác nhân không lớn hơn ngày đăng ký"
                )
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "nktNgayChet" &&
      ngayDangKyValue &&
      typeFile === "KT"
    ) {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (value && helpers.isAfterDate(value, ngayDangKyValue, true)) {
              return Promise.reject(
                new Error(
                  "Ngày chết của người khai tử không lớn hơn ngày đăng ký"
                )
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "nksNgaySinh" &&
      ngayDangKyValue &&
      typeFile === "KS"
    ) {
      const meNgaySinh = form.getFieldValue("meNgaySinh");
      const chaNgaySinh = form.getFieldValue("chaNgaySinh");
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (
              value &&
              ngayDangKyValue &&
              helpers.isAfterDate(value, ngayDangKyValue, true)
            ) {
              console.log("1");
              return Promise.reject(
                new Error(
                  "Ngày sinh của người khai sinh không lớn hơn ngày đăng ký"
                )
              );
            }

            if (
              value &&
              meNgaySinh &&
              !helpers.isAfterDate(value, meNgaySinh, false)
            ) {
              console.log("2", "value", value, "meNgaySinh", meNgaySinh);
              return Promise.reject(
                new Error(
                  "Ngày sinh của người khai sinh không nhỏ hơn ngày sinh cha/ngày sinh mẹ"
                )
              );
            }

            if (
              value &&
              chaNgaySinh &&
              !helpers.isAfterDate(value, chaNgaySinh, false)
            ) {
              console.log("2", "value", value, "chaNgaySinh", chaNgaySinh);
              return Promise.reject(
                new Error(
                  "Ngày sinh của người khai sinh không nhỏ hơn ngày sinh cha/ngày sinh mẹ"
                )
              );
            }

            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "ncNgaySinh" &&
      ngayDangKyValue &&
      typeFile === "CMC"
    ) {
      const cmNgaySinh = form.getFieldValue("cmNgaySinh");
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (value && helpers.isAfterDate(value, ngayDangKyValue, true)) {
              return Promise.reject(
                new Error("Ngày sinh của người con không lớn hơn ngày đăng ký")
              );
            }
            if (value && !helpers.isAfterDate(value, cmNgaySinh, false)) {
              return Promise.reject(
                new Error(
                  "Ngày sinh của người con không nhỏ hơn ngày sinh cha/ngày sinh mẹ"
                )
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      (item.dataKey === "chongNgaySinh" || item.dataKey === "voNgaySinh") &&
      typeFile === "KH"
    ) {
      function isAtLeast18YearsOld(date: string, date2: string) {
        const registerDate = moment(date2, "DD.MM.YYYY");
        const birthDate = moment(date, "DD.MM.YYYY");
        const age = registerDate.diff(birthDate, "years");
        return age >= 18;
      }
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (
              value &&
              !isAtLeast18YearsOld(value, form.getFieldValue("ngayDangKy"))
            ) {
              return Promise.reject(
                new Error("Vợ/Chồng chưa đủ độ tuổi kết hôn (Chưa đủ 18 tuổi)")
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    return rules;
  };

  const resetData = () => {
    if (
      dataFieldFile?.data[0]?.dataKeyMap?.length &&
      dataFieldFile?.data[1]?.dataKeyMap?.length
    ) {
      setKeyMaps(dataFieldFile?.data[0]?.dataKeyMap);
      setKeyMaps2(dataFieldFile?.data[1]?.dataKeyMap);
    } else {
      setKeyMaps([]);
      setKeyMaps2([]);
    }
    form.resetFields();
    form2.resetFields();
  };

  return (
    <Spin tip="Đang tải..." spinning={dataFieldFile.isFetching}>
      <div className="content-box">
        <div className="body-block-create-info-init" ref={wrapperDiv}>
          <SplitPane
            split={displayFlex ? "vertical" : "horizontal"}
            size={displayFlex ? width : height}
            minSize={"50%"}
            onChange={(size) => changeSize(size)}
            maxSize={"100%"}
          >
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
                  : {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
              }
            >
              <div className="header-block">
                <label
                  style={{
                    backgroundColor: "#fefbd8",
                    fontWeight: "bold",
                    fontSize: 10,
                  }}
                  className="search-title"
                >
                  {detailResponse?.data?.pathFile || ""}
                </label>
              </div>
              <div>
                <ZoomOut
                  onClick={() =>
                    setScale((prev: number) => {
                      prevScale.current = prev;
                      return scale === 1 ? 1 : scale - 1;
                    })
                  }
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <ZoomIn
                  onClick={() =>
                    setScale((prev: number) => {
                      prevScale.current = prev;
                      return scale + 1;
                    })
                  }
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Rotate
                  onClick={() => setRotate(rotate - 90)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Top
                  onClick={() => changeLayout(false, 1)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
                <Left
                  onClick={() => changeLayout(true, 1)}
                  style={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    marginRight: 20,
                    marginBottom: -5,
                  }}
                />
              </div>
              <div
                style={{
                  overflowY: "auto",
                  height: displayFlex
                    ? "calc(100vh - 160px)"
                    : "calc(100% - 55px)",
                }}
                id="block-view-pdf-image"
              >
                <TransformWrapper
                  maxScale={3}
                  initialScale={1}
                  disablePadding={true}
                  ref={transformComponentRef}
                  onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                    if (ref.state.scale >= 3) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev + 1;
                      });
                    } else if (ref.state.scale <= 1) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev - 1 || 1;
                      });
                    }
                  }}
                >
                  <TransformComponent>
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(
                        new Array(Math.round(numPages / 2)),
                        (el, index) => (
                          <div
                            style={{ display: "flex" }}
                            key={`grouppage${index + 1}`}
                          >
                            <Page
                              width={width}
                              rotate={rotate}
                              scale={scale}
                              canvasRef={canvas}
                              key={`page_${index * 2 + 1}`}
                              pageNumber={index * 2 + 1}
                              onRenderSuccess={onRenderSuccess}
                              onClick={onClickPage}
                            />
                            {index * 2 + 2 <= numPages ? (
                              <Page
                                width={width}
                                rotate={rotate}
                                scale={scale}
                                key={`page_${index * 2 + 2}`}
                                pageNumber={index * 2 + 2}
                                canvasRef={canvas1}
                                onRenderSuccess={onRenderSuccess}
                                onClick={onClickPage}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      )}
                    </Document>
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            {/* enter field value */}
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                      position: "absolute",
                    }
                  : {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      height: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                    }
              }
            >
              <Row gutter={16}>
                <Col span={8} offset={8}>
                  <label
                    style={{ backgroundColor: "#fefbd8" }}
                    className="search-title"
                  >
                    <p style={{ textAlign: "center" }}>
                      {dataFieldFile?.data?.[0].createBy}
                    </p>
                  </label>
                </Col>

                <Col span={8}>
                  <label
                    style={{ backgroundColor: "#fefbd8" }}
                    className="search-title"
                  >
                    <p style={{ textAlign: "center" }}>
                      {dataFieldFile?.data?.[1].createBy}
                    </p>
                  </label>
                </Col>

                <Col span={24}>
                  <div
                    style={{
                      overflowY: "auto",
                      overflowX: "hidden",
                      height: "calc(100vh - 180px)",
                      paddingRight: 10,
                    }}
                  >
                    <Row gutter={16} className="recheck-form">
                      <Col span={16}>
                        <Form
                          name="first-form"
                          autoComplete="off"
                          form={form}
                          colon={false}
                          style={{ marginTop: 0 }}
                          layout="horizontal"
                          labelWrap
                          labelAlign="left"
                        >
                          <Row gutter={16} style={{ marginTop: 8 }}>
                            {keyMaps.length > 0 &&
                              keyMaps
                                .filter((x) => x.isDisplay)
                                .map((item: any, index) => {
                                  return (
                                    <Col span={24} key={item.dataKeyAscii}>
                                      {convertTypeDataToElement(
                                        item,
                                        index,
                                        index * 2,
                                        form,
                                        true
                                      )}
                                    </Col>
                                  );
                                })}
                          </Row>
                        </Form>
                      </Col>

                      <Col span={8}>
                        <Form
                          name="second-form"
                          autoComplete="off"
                          form={form2}
                          colon={false}
                          style={{ marginTop: 0 }}
                          layout="horizontal"
                          labelWrap
                          labelAlign="left"
                        >
                          <Row gutter={16} style={{ marginTop: 8 }}>
                            {keyMaps2.length > 0 &&
                              keyMaps2
                                .filter((x) => x.isDisplay)
                                .map((item: any, index) => {
                                  return (
                                    <Col span={24} key={item.dataKeyAscii}>
                                      {convertTypeDataToElement(
                                        item,
                                        index,
                                        index * 2 + 1,
                                        form2,
                                        false
                                      )}
                                    </Col>
                                  );
                                })}
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              {detailResponse?.data?.id ? (
                <div className="search-box-button" style={{ paddingTop: 10 }}>
                  <Button
                    onClick={() => {
                      resetData();
                      setInitData(
                        dataFieldFile?.data[0]?.dataKeyMap,
                        dataFieldFile?.data[1]?.dataKeyMap
                      );
                    }}
                    disabled={isDisabledClearButton}
                    htmlType="button"
                  >
                    Xóa dữ liệu
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      !checkActiveSave() ||
                      form
                        .getFieldsError()
                        ?.some((item) => item.errors?.length) ||
                      form2
                        .getFieldsError()
                        ?.some((item) => item.errors?.length)
                    }
                    onClick={handleSubmitBothForm}
                  >
                    Xác nhận
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </SplitPane>
        </div>
      </div>
      <ConfirmModalCustom
        cancelText="Đóng"
        okText="Đồng ý"
        showCancel={true}
        onCancel={() => setOpenConfirmModal(false)}
        showOk={true}
        type={"warning"}
        centered
        title={"XÁC NHẬN"}
        content={`Bạn muốn cập nhật dữ liệu không?`}
        visible={openConfirmModal}
        onOk={handleSaveData}
        loadingBtnOk={isLoadingUpdate}
      />
    </Spin>
  );
};

export default DetailDataRecheckFile;
