import {
  Button,
  Col,
  Row,
  Table,
  Pagination,
  FormInstance,
  Spin,
  Tag,
  Dropdown,
  Menu,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as Manipulation } from "../../../../assets/logos/action.svg";
import { ReactComponent as RemoveDetail } from "../../../../assets/logos/delete.svg";
import { ReactComponent as Lock } from "../../../../assets/logos/lock-dots.svg";
import { ReactComponent as Unlock } from "../../../../assets/logos/unlock.svg";
import {
  useDeleteProvinceMutation,
  useLazyGetPageProvinceQuery,
  useUnlockProvinceMutation,
  useLockProvinceMutation,
} from "../redux/provinceApi";
import { ProvinceParams, ProvinceResponse } from "../redux/apiTypes";
import AddProvinceModal from "./AddProvinceModal";
import ConfirmModalCustom from "../../../common/components/ConfirmModalCustom";
import { format } from "date-fns";
import {
  checkPermission,
  exportFile,
} from "../../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../../permissions/ts/Contants";
import { DownloadOutlined } from "@ant-design/icons";

export interface DataListTable {
  key: string;
  provinceCode: string;
  provinceName: string;
  status: number;
  date: string;
  user: string;
  // isUsed: number,
}

type Props = {
  form: FormInstance<any>;
  searchParam: any;
};

const ProvinceList = ({ form, searchParam }: Props) => {
  const [tableData, setTableData] = useState<DataListTable[]>([]);
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
  const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const pageSizeRef = useRef(10);
  const [currentProvince, setCurrentProvince] = useState<DataListTable>();
  const [addProvince, setAddProvince] = useState(false);
  const [deleteProvinceVisible, setDeleteProvinceVisible] = useState(false);
  const [activateProvinceVisible, setActivateProvinceVisible] = useState(false);

  const [provinceTrigger, provinceResponse] = useLazyGetPageProvinceQuery();
  const [deleteProvince] = useDeleteProvinceMutation();
  const [activateProvince] = useUnlockProvinceMutation();
  const [lockProvince] = useLockProvinceMutation();

  const canDelete = checkPermission(PERMISSION_KEYS.DANH_MUC.TINH_THANH.XOA);
  const canAddNew = checkPermission(
    PERMISSION_KEYS.DANH_MUC.TINH_THANH.THEM_MOI
  );
  const canEdit = checkPermission(PERMISSION_KEYS.DANH_MUC.TINH_THANH.SUA);

  useEffect(() => {
    provinceTrigger({
      page: 0,
      size: pageSizeRef.current,
      ...searchParam,
    });
    setPage(1);
  }, [provinceTrigger, searchParam]);

  useEffect(() => {
    if (provinceResponse?.data?.content?.length) {
      let newTableData: DataListTable[] = [];
      // totalPages.current = provinceResponse?.data?.totalPages;
      provinceResponse.data.content.forEach((item: ProvinceResponse) => {
        newTableData.push({
          key: item.id,
          status: item.status,
          provinceCode: item.provinceCode,
          provinceName: item.provinceName,
          date: item.modifiedDateStr,
          user: item.modifiedBy,
          // isUsed: item.isUsed,
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
      // totalPages.current = 1;
    }
  }, [provinceResponse]);

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, item, index) =>
        (page - 1) * pageSizeRef.current + index + 1,
    },
    {
      title: "Thao Tác",
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <Dropdown placement="bottomLeft" overlay={dropdownOverlay(record)}>
            <Manipulation onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        );
      },
    },
    {
      title: "Mã Tỉnh/Thành phố",
      dataIndex: "provinceCode",
      key: "provinceCode",
      align: "left",
    },
    {
      title: "Tỉnh/Thành phố",
      dataIndex: "provinceName",
      key: "provinceName",
      align: "left",
    },
    {
      title: "Trạng Thái",
      key: "status",
      dataIndex: "status",
      render: (tag) => (
        <Tag className={`tag-status${!tag ? "_active" : "_err"}`}>
          {!tag ? "Hoạt động" : "Không hoạt động"}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "Ngày cập nhật",
      key: "date",
      dataIndex: "date",
      align: "left",
    },
    {
      title: "Người cập nhật",
      key: "user",
      dataIndex: "user",
      align: "left",
    },
  ];

  const dropdownOverlay = (record: any) => {
    // const menuItems: ItemType[] = [];
    // menuItems.push({
    //     label: "Xóa",
    //     key: "remove-detail",
    //     icon: <RemoveDetail />,
    //     // onClick: () => deleteProvinceToggle(record),
    // })
    // record.status
    //     ? menuItems.push({
    //         label: "Kích hoạt",
    //         key: "activate",
    //         icon: <Unlock />,
    //         // onClick: () => activateProvinceToggle(record),
    //     })
    //     : menuItems.push({
    //         label: "Khóa",
    //         key: "lock",
    //         icon: <Lock />,
    //         // onClick: () => activateProvinceToggle(record),
    //     })
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          // items={menuItems}
          className="menu-actions"
          style={{ padding: "8px", width: "140px" }}
        >
          {canDelete ? (
            <Menu.Item
              key="remove-detail"
              onClick={() => deleteProvinceToggle(record)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <RemoveDetail style={{ height: 24, width: 24 }} />
                Xóa
              </div>
            </Menu.Item>
          ) : (
            <></>
          )}

          {record.status ? (
            <Menu.Item
              key="unlock"
              onClick={() => activateProvinceToggle(record)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Unlock style={{ height: 24, width: 24 }} />
                Kích hoạt
              </div>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="lock"
              onClick={() => activateProvinceToggle(record)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Lock style={{ height: 24, width: 24 }} />
                Khóa
              </div>
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    setPage(newPage);
    pageSizeRef.current = pageSize;
    provinceTrigger({
      ...searchParam,
      page: newPage - 1,
      size: pageSize,
    });
  };

  // Thêm mới
  const toggleAddProvince = (record?: DataListTable) => {
    setAddProvince(!addProvince);
    if (record) setCurrentProvince(record);
  };
  const onAddSuccessful = () => {
    toggleAddProvince();
    setCurrentProvince(undefined);
    setPage(1);
    provinceTrigger({ size: pageSizeRef.current, page: 0 });
  };

  // Xóa
  const deleteProvinceToggle = (record: DataListTable) => {
    setDeleteProvinceVisible(!deleteProvinceVisible);
    setCurrentProvince(record);
  };
  const onDelete = () => {
    if (currentProvince) {
      if (!loadingBtnSubmit) {
        setLoadingBtnSubmit(true);
        deleteProvince([currentProvince.key])
          .unwrap()
          .then((res) => {
            notification.success({
              message: "Xóa Tỉnh/Thành phố thành công",
            });
            setDeleteProvinceVisible(false);
            setCurrentProvince(undefined);
            setPage(1);
            form.resetFields();
            setLoadingBtnSubmit(false);
            provinceTrigger({ size: pageSizeRef.current, page: 0 });
          })
          .catch((err) => {
            notification.error({
              message:
                err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
            });
            setLoadingBtnSubmit(false);
          });
      }
    }
  };

  // Khóa, kích hoạt
  const activateProvinceToggle = (record: DataListTable) => {
    setActivateProvinceVisible(!activateProvinceVisible);
    setCurrentProvince(record);
  };
  const onActivate = () => {
    if (currentProvince) {
      if (currentProvince.status === 0) {
        if (!loadingBtnSubmit) {
          setLoadingBtnSubmit(true);
          lockProvince([currentProvince.key])
            .unwrap()
            .then((res) => {
              notification.success({
                message: "Khóa Tỉnh/Thành phố thành công.",
              });
              setActivateProvinceVisible(false);
              setCurrentProvince(undefined);
              setPage(1);
              form.resetFields();
              setLoadingBtnSubmit(false);
              provinceTrigger({ size: pageSizeRef.current, page: 0 });
            })
            .catch((err) => {
              notification.error({
                message:
                  err.data?.message ||
                  "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
              });
              setLoadingBtnSubmit(false);
            });
        }
      }

      if (currentProvince.status === 1) {
        if (!loadingBtnSubmit) {
          setLoadingBtnSubmit(true);
          activateProvince([currentProvince.key])
            .unwrap()
            .then((res) => {
              notification.success({
                message: "Kích hoạt Tỉnh/Thành phố thành công.",
              });
              setActivateProvinceVisible(false);
              setCurrentProvince(undefined);
              setPage(1);
              form.resetFields();
              setLoadingBtnSubmit(false);
              provinceTrigger({ size: pageSizeRef.current, page: 0 });
            })
            .catch((err) => {
              notification.error({
                message:
                  err.data?.message ||
                  "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
              });
              setLoadingBtnSubmit(false);
            });
        }
      }
    }
  };

  // export excel
  const onExportExcelClick = () => {
    let url =
      process.env.REACT_APP_API_URL + "/api/v1/common/province/export/excel";
    let submitValues: ProvinceParams = { ...searchParam };
    url += `?search=${encodeURIComponent(
      JSON.stringify(submitValues)
    )}&pageNumber=0&pageSize=20000&sortBy=DESC&sortByProperty=modifiedDate`;
    let filename = `${format(
      new Date(),
      "dd-MM-yyyy"
    )}_Danh sách tỉnh thành.xlsx`;
    setLoadingExcel(true);
    exportFile(url, filename)
      .then(() => {
        setLoadingExcel(false);
      })
      .catch((err) => {
        setLoadingExcel(false);
        notification.error({
          message:
            err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
        });
      });
  };

  const resetState = () => {
    setCurrentProvince(undefined);
    setDeleteProvinceVisible(false);
    setActivateProvinceVisible(false);
    setAddProvince(false);
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <Col span={6}>
          <div>
            Tổng số:{" "}
            <strong>{provinceResponse?.data?.totalElements ?? 0}</strong> bản
            ghi
          </div>
        </Col>
        <div className="search-box-button">
          <Button
            htmlType="button"
            style={{ marginRight: 15 }}
            className="secondary-button"
            onClick={onExportExcelClick}
            loading={loadingExcel}
            icon={<DownloadOutlined />}
          >
            Xuất excel
          </Button>
          {canAddNew ? (
            <Button
              htmlType="button"
              onClick={() => toggleAddProvince()}
              className="secondary-button"
              type="primary"
            >
              Thêm mới
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Row>
      <Table<DataListTable>
        loading={provinceResponse?.isFetching}
        dataSource={tableData}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        onRow={(record) => {
          return {
            onClick: (event) => toggleAddProvince(record),
          };
        }}
      />

      <Spin spinning={provinceResponse?.isFetching} tip="Đang tải...">
        <Pagination
          total={provinceResponse?.data?.totalElements || 0}
          defaultPageSize={pageSizeRef.current}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={page}
        />
      </Spin>

      {/* form thêm mới, sửa Tỉnh/Thành phố */}
      {addProvince ? (
        <AddProvinceModal
          visible={addProvince}
          onCancel={resetState}
          currentProvince={currentProvince}
          onAddSuccessful={onAddSuccessful}
          canEdit={canEdit}
        />
      ) : (
        <></>
      )}

      {/* modal xóa Tỉnh/Thành phố */}
      {deleteProvinceVisible ? (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Đóng"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={"error"}
          danger={true}
          title="Xóa Tỉnh/Thành phố"
          content="Bạn có chắc chắn muốn xóa Tỉnh/Thành phố này hay không?"
          visible={deleteProvinceVisible}
          onOk={onDelete}
          loadingBtnOk={loadingBtnSubmit}
        />
      ) : (
        <></>
      )}

      {/* modal khóa, kích hoạt Tỉnh/Thành phố*/}
      {activateProvinceVisible && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Đóng"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={currentProvince?.status === 0 ? "warning" : "success"}
          title={
            currentProvince?.status === 0
              ? "Khóa Tỉnh/Thành phố"
              : "Kích hoạt Tỉnh/Thành phố"
          }
          content={
            currentProvince?.status === 0
              ? "Bạn có chắc chắn muốn khóa Tỉnh/Thành phố này hay không?"
              : "Bạn có chắc chắn muốn kích hoạt Tỉnh/Thành phố này hay không?"
          }
          visible={activateProvinceVisible}
          onOk={onActivate}
          loadingBtnOk={loadingBtnSubmit}
        />
      )}
    </div>
  );
};

export default ProvinceList;
