import moment from "moment";
import { NUMBER_WORD, NUMBER_WORD_UNIT } from "../ts/constant";

export const helpers = {
  formatDate,
  formatDateHour,
  formatDateHourMinuteSecond,
  validateUserName,
  validatePhoneFormat,
  validateEmailFormat,
  like,
  standardlizePhoneNumber,
  compareList,
  compareListByName,
  currencyFormat,
  deleteSign,
  isDirtyForm,
  generateCountdownString,
  formatNumber,
  // custom data
  customTime,
  customDate,
  toTitleCase,
  toTitleCase1,
  dateToWord,
  toTitleCaseFirstChar,
  isAfterDate,
};

function isAfterDate(date1, date2, allowEqual = false) {
  const momentDate1 = moment(date1, "DD.MM.YYYY");
  const momentDate2 = moment(date2, "DD.MM.YYYY");
  if (allowEqual) {
    return momentDate1.isSameOrAfter(momentDate2);
  } else {
    return momentDate1.isAfter(momentDate2);
  }
}

function formatNumber(input) {
  if (!input) return "";
  const number = parseInt(input, 10);
  if (isNaN(number)) return input;
  return number.toString().padStart(3, "0");
}

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

function formatDateHour(time) {
  return moment(time).format("HH[h] DD/MM/YYYY");
}

function formatDateHourMinuteSecond(time) {
  return moment(time).format("DD/MM/YYYY HH:mm:ss");
}

function validateUserName(value) {
  value = value.trim().replace(/\s+/g, "");
  let re = /[^a-z0-9_]/;
  return re.test(value);
}

function validatePhoneFormat(value) {
  if (!value) return true;
  value = value.trim().replace(/\s+/g, "");
  let regex = /^\+?(0|84)?\d{9,12}$/;
  return regex.test(value);
}

function validateEmailFormat(value, callbackFnWhenFalse) {
  let re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(value.toLowerCase());
}

function like(sentence, phrase) {
  if (!sentence || !sentence.trim()) return false;
  if (!phrase || !phrase.trim()) return true;
  sentence = sentence.trim().toLowerCase();
  phrase = phrase.trim().toLowerCase();
  let words = phrase.split(/\s+/);
  let likeRegex = words.join(".*");
  return new RegExp(likeRegex).test(sentence);
}

function standardlizePhoneNumber(value) {
  value = value.trim().replace(/\s+/g, "");
  if (value.length === 9) return "0" + value;
  if (value.startsWith("0")) return "0" + value.substring(1);
  if (value.startsWith("84")) return "0" + value.substring(2);
  if (!value.startsWith("+84")) return "0" + value;
  return value;
}

function compareList(item1, item2) {
  if (!item1.id) return 1;
  if (!item2.id) return -1;
  if (item1.order !== item2.order)
    return (item1.order ? item1.order : item1.order === 0 ? 0 : 10000) >
      (item2.order ? item2.order : item2.order === 0 ? 0 : 10000)
      ? 1
      : -1;
  return (item1.name || "").localeCompare(item2.name || "");
}

function compareListByName(item1, item2, varName) {
  if (varName)
    return (item1[varName] || "").localeCompare(item2[varName] || "");
  return (item1.name || "").localeCompare(item2.name || "");
}

function currencyFormat(num, check, limit) {
  if (!num && num !== 0) return null;
  if (check && (num * 100) % 100 !== 0) {
    return parseFloat(num.toFixed(limit)).toString();
  } else {
    return num
      .toFixed(0)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
}

function deleteSign(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
}

function isDirtyForm(values) {
  let formDirty = false;
  Object.keys(values).forEach((item) => {
    if (
      values[item] !== null &&
      values[item] !== undefined &&
      (Object.keys(values[item])?.length || values[item])
    ) {
      formDirty = true;
    }
  });

  return formDirty;
}

function generateCountdownString(countdown) {
  let countdownArray = [];
  if (countdown.day) {
    countdownArray.push(`${countdown.day} ngày`);
  }
  if (countdown.hour) {
    countdownArray.push(`${countdown.hour} giờ`);
  }
  if (countdown.minute) {
    countdownArray.push(`${countdown.minute} phút`);
  }
  countdownArray.push(`${countdown.second || 0} giây`);

  return countdownArray.join(" ");
}

// Custom date format
function customDate(newText) {
  newText = newText.trim();
  if (newText.length === 4) return newText;
  let joinRegex = /([\/|-|\.|\s])/;
  let arrJoin = newText.search(joinRegex);
  if (arrJoin > -1) {
    if (newText.length < 8) {
      let pattern = /^(\d{1,2})([\/|-|\.|\s])(\d{4})$/;
      let arrayDate = newText.match(pattern);
      if (arrayDate)
        return `${
          arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
        }.${arrayDate?.[3]}`;
    }
    let pattern = /^(\d{1,2})([\/|-|\.|\s])(\d{1,2})([\/|-|\.|\s])(\d{4})$/;
    let arrayDate = newText.match(pattern);
    if (arrayDate)
      return `${
        arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
      }.${
        arrayDate?.[3].length === 1 ? "0" + arrayDate?.[3] : arrayDate?.[3]
      }.${arrayDate?.[5]}`;
  } else {
    if (
      newText.length === 5 ||
      (newText.length === 6 &&
        parseInt(newText.match(/^(\d{1,2})(\d{4})$/)[1]) < 13)
    ) {
      let pattern = /^(\d{1,2})(\d{4})$/;
      let arrayDate = newText.match(pattern);
      if (arrayDate)
        return `${
          arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
        }.${arrayDate?.[2]}`;
    }
    if (
      newText.length === 7 &&
      parseInt(newText.charAt(1) + newText.charAt(2)) < 13 &&
      parseInt(newText.charAt(0))
    )
      newText = `0${newText}`;
    let pattern = /^(\d{1,2})(\d{1,2})(\d{4})$/;
    let arrayDate = newText.match(pattern);
    if (arrayDate)
      return `${
        arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
      }.${
        arrayDate?.[2].length === 1 ? "0" + arrayDate?.[2] : arrayDate?.[2]
      }.${arrayDate?.[3]}`;
  }
  return "";
}

// Custom time format
function customTime(newText, format) {
  // ^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$
  newText = newText.trim();
  let joinRegex = /([\:|\s])/;
  let arrJoin = newText.search(joinRegex);
  let regexArr = format.split(":");
  if (!newText) return undefined;
  if (
    newText.length === 1 ||
    (newText.length === 2 && newText.charAt(0) === "0")
  ) {
    if (regexArr.length === 2)
      return newText.length === 2 ? `${newText}:00` : `0${newText}:00`;
    return `0${newText}:00:00`;
  }
  if (arrJoin > -1) {
    if (newText.length < 5 || regexArr.length === 2) {
      let pattern = /^(\d{1,2})([\:|\s])(\d{1,2})$/;
      let arrayDate = newText.match(pattern);
      if (arrayDate)
        return `${
          arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
        }:${
          arrayDate?.[3].length === 1 ? "0" + arrayDate?.[3] : arrayDate?.[3]
        }`;
    }
    let pattern = /^(\d{1,2})([\:|\s])(\d{1,2})([\:|\s])(\d{1,2})$/;
    let arrayDate = newText.match(pattern);
    if (arrayDate)
      return `${
        arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
      }:${
        arrayDate?.[3].length === 1 ? "0" + arrayDate?.[3] : arrayDate?.[3]
      }:${arrayDate?.[5]}`;
  } else {
    if (parseInt(newText.charAt(0) + newText.charAt(1)) < 24) {
      if (parseInt(newText.charAt(2)) < 6) {
        if (regexArr.length === 2) {
          newText =
            newText +
            ["0", "0", "0", "0"]
              .filter((x, index) => index < 4 - newText.length)
              .join("");
        } else {
          newText =
            newText +
            ["0", "0", "0", "0", "0", "0"]
              .filter((x, index) => index < 6 - newText.length)
              .join("");
        }
      } else {
        if (regexArr.length === 2) {
          newText =
            newText.substr(0, 2) +
            "0" +
            newText.substr(2) +
            ["0", "0", "0", "0"]
              .filter((x, index) => index < 3 - newText.length)
              .join("");
        } else {
          newText =
            newText.substr(0, 2) +
            newText.charAt(1) +
            "0" +
            newText.substr(2) +
            ["0", "0", "0", "0", "0", "0"]
              .filter((x, index) => index < 5 - newText.length)
              .join("");
        }
      }
    } else {
      if (parseInt(newText.charAt(1)) < 6) {
        if (regexArr.length === 2) {
          newText =
            "0" +
            newText +
            ["0", "0", "0", "0"]
              .filter((x, index) => index < 3 - newText.length)
              .join("");
        } else {
          newText =
            "0" +
            newText +
            ["0", "0", "0", "0", "0", "0"]
              .filter((x, index) => index < 5 - newText.length)
              .join("");
        }
      } else {
        if (regexArr.length === 2) {
          newText =
            "0" +
            newText.charAt(0) +
            "0" +
            newText.substr(1) +
            ["0", "0", "0", "0"]
              .filter((x, index) => index < 2 - newText.length)
              .join("");
        } else {
          newText =
            "0" +
            newText.charAt(0) +
            "0" +
            newText.substr(1) +
            ["0", "0", "0", "0", "0", "0"]
              .filter((x, index) => index < 4 - newText.length)
              .join("");
        }
      }
    }
    if (newText.length < 5 || regexArr.length === 2) {
      let pattern = /^(\d{1,2})(\d{1,2})$/;
      let arrayDate = newText.match(pattern);
      if (arrayDate)
        return `${
          arrayDate?.[1].length === 1 ? "0" + arrayDate?.[1] : arrayDate?.[1]
        }:${
          arrayDate?.[2].length === 1 ? "0" + arrayDate?.[2] : arrayDate?.[2]
        }`;
    }
    let pattern = /^(\d{1,2})(\d{1,2})(\d{1,2})$/;
    let arrayDate = newText.match(pattern);
    if (arrayDate)
      return `${
        arrayDate?.[1].length === 1
          ? "0" + arrayDate?.[1]
          : arrayDate?.[1] || "00"
      }:${
        arrayDate?.[2].length === 1
          ? "0" + arrayDate?.[2]
          : arrayDate?.[2] || "00"
      }:${
        arrayDate?.[3].length === 1
          ? "0" + arrayDate?.[3]
          : arrayDate?.[3] || "00"
      }`;
  }
  return "";
}

// Custome capitalise text
function toTitleCase1(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

// Custome capitalise text
function toTitleCase(str) {
  return str
    .split(" ")
    .filter((x) => x)
    .map((txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    .join(" ");
}

// Custome capitalise text
function toTitleCaseFirstChar(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.substr(1);
}

function dateToWord(date) {
  date = date.split(".");
  if (date.length === 1) date = ["", "", date[0]];
  if (date.length === 2) date = ["", date[0], date[1]];
  let dateWord = "";
  let day0 = date?.[0]
    ? parseInt(date[0]?.charAt(0)) && date[0] !== "10"
      ? parseInt(date[0].charAt(0)) > 1
        ? `Ngày ${NUMBER_WORD[parseInt(date[0].charAt(0))]} mươi`
        : `Ngày mười`
      : "Ngày"
    : "";
  let day1 =
    date?.[0] && parseInt(date[0]?.charAt(1))
      ? parseInt(date[0].charAt(0)) === 0
        ? `mùng ${NUMBER_WORD[parseInt(date[0].charAt(1))]}`
        : parseInt(date[0].charAt(0)) > 1 || parseInt(date[0].charAt(1)) === 5
        ? `${NUMBER_WORD_UNIT[parseInt(date[0].charAt(1))]}`
        : `${NUMBER_WORD[parseInt(date[0].charAt(1))]}`
      : "";
  if (date[0] === "10") day1 = "mùng mười";
  let month =
    date?.[1] && parseInt(date[1])
      ? `tháng ${NUMBER_WORD[parseInt(date[1])]}`
      : "";
  let year0 =
    date?.[2] && date[2].length === 4
      ? `năm ${NUMBER_WORD[parseInt(date[2].charAt(0))]} nghìn`
      : "";
  let checkYear1000 = parseInt(date[2]) % 1000;
  let checkYear100 = parseInt(date[2]) % 100;
  let checkYear10 = parseInt(date[2]) % 10;
  let year1 =
    date?.[2] && date[2].length === 4 && checkYear1000
      ? `${NUMBER_WORD[parseInt(date[2].charAt(1))]} trăm`
      : "";
  let year2 =
    date?.[2] && date[2].length === 4 && checkYear100
      ? `${
          date[2].charAt(2) === "0"
            ? "linh"
            : date[2].charAt(2) === "1"
            ? "mười"
            : NUMBER_WORD[parseInt(date[2].charAt(2))] + " mươi"
        }`
      : "";
  let year3 =
    date?.[2] && date[2].length === 4 && checkYear10
      ? `${
          date[2].charAt(2) === "0" ||
          (date[2].charAt(2) === "1" && date[2].charAt(3) === "1")
            ? NUMBER_WORD[parseInt(date[2].charAt(3))]
            : NUMBER_WORD_UNIT[parseInt(date[2].charAt(3))]
        }`
      : "";
  dateWord = [
    day0 || "",
    day1 || "",
    month || "",
    year0 || "",
    year1 || "",
    year2 || "",
    year3 || "",
  ]
    .filter((x) => x)
    .join(" ");
  return dateWord.charAt(0).toUpperCase() + dateWord.substr(1).toLowerCase();
}
