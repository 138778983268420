import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign, CommonListQuery, } from '../../../redux/api/apiTypes';
import { CampaginParams, CampaignResponse } from './apiTypes';

export const campaignApi = createApi({
    reducerPath: 'campaignApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getCampaign: builder.query<CampaignResponse, { searchParam?: CampaginParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/common/data-campaign/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: CampaignResponse }, meta, arg) => {
                return response.data;
            },
        }),
        // getCampaignTotal: builder.query<number, { searchParam?: CampaginParams } & Partial<CommonListQuery>>({
        //     query: (body) => ({
        //         url: `/common/data-campaign-count/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
        //         method: 'GET',
        //     }),
        //     transformResponse: (response: { data: number }, meta, arg) => {
        //         return response.data;
        //     },
        // }),
        getCampaignById: builder.query<Campaign, string>({
            query: (campaignId) => ({
                url: `/common/data-campaign/${campaignId}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: Campaign }, meta, arg) => {
                return response.data;
            },
        }),
        editCampaign: builder.mutation<Campaign, Campaign>({
            query: (body) => ({
                url: `/common/data-campaign/edit`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: Campaign }, meta, arg) => {
                return response.data;
            },
        }),
        updateCampaignStatus: builder.mutation({
            query: (payload) => ({
              url: `/common/data-campaign/update-campaign-status/${payload.id}`,
              method: "PUT",
              params: {
                status: payload.status,
              },
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
              return response.data;
            },
        }),
        randomCheck: builder.query({
            query: () => ({
                url: `/common/data-campaign/random-check`,
                method: 'GET'
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        updateStatus: builder.mutation({
            query: (payload) => ({
              url: `/common/data-campaign/update-status/${payload.id}`,
              method: "PUT",
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
              return response.data;
            },
        }),
        updateData: builder.mutation({
            query: ({ dataInfoMatchId, dataKeyMap }) => ({
                url: `/common/data-campaign/${dataInfoMatchId}/data-key-map`,
                method: "PUT",
                body: dataKeyMap,
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useEditCampaignMutation,
    useLazyGetCampaignQuery,
    useLazyGetCampaignByIdQuery,
    // useLazyGetCampaignTotalQuery
    useUpdateCampaignStatusMutation,
    useLazyRandomCheckQuery,
    useUpdateStatusMutation,
    useUpdateDataMutation,
} = campaignApi;
export default campaignApi;